import React from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  isTooth,
  isImage,
  isXRay,
} from '../../../shared-logic/taskLevelsTypesHelper';
import {
  currentTaskSelector,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { currentMarkerSelector } from '../../../redux/marks/currentMarkingSlice';
import {
  setSelectedTableRowId,
  toothRightClickSelector,
  setMarkEdited,
  selectedTableRowIdSelector,
} from '../../../redux/labelingTool/labelingToolSlice';
import { Table } from '../../../components';
import {
  useGetToothMarkers,
  usePrevMarks,
  useSetTooth,
  useMissPoint,
} from '../customHooks';
import { NOT_SELECTED_STR } from '../../../shared-logic/params';
import styles from '../LabelingToolPage.module.css';
import {
  imageLevelColumns,
  toothLevelColumns,
  imageLevelTrainingColumns,
} from '../table-columns';
import { useGetLabelingToolPageLogic } from '../../../hooks';
import { isTier3 } from '../../../shared-logic/tiersHelpers';
import { showMissPointsSelector } from '../../../redux/marks/currentMarkingSlice';
import {
  COLOR_BLUE,
  COLOR_LIGHT_BLUE,
} from '../ImageFrameManipulation/BordersAndLabels/BordersAndLabelsEnum';
import { commitSelector } from '../../../redux/taskStateImages/outputSlice';
import { isTrainingMode } from '../../../config/configUtil';
import { selectCampaign, selectConfig } from '../../../redux/tasks/tasksSlice';
import { PointStatus } from '../../../shared-logic/enums';
import useFindingsDetails from '../MarkLabeling/Labeling/FindingsDetails';
const MarksDetailsTable = ({ onMarkerSelected }) => {
  const labelingToolLogic = useGetLabelingToolPageLogic();
  const dispatch = useDispatch();
  const taskLevel = useSelector(taskLevelSelector);
  const currentTask = useSelector(currentTaskSelector);
  const isToothLevel = isTooth(taskLevel);
  const isImageLevel = isImage(taskLevel);
  const tableHeight = isToothLevel ? 130 : 300;
  const tier = useSelector(tierSelector);
  const TIER_3 = isTier3(tier);
  const getToothMarkers = useGetToothMarkers();
  const currentMarker = useSelector(currentMarkerSelector);
  const toothRightClick = useSelector(toothRightClickSelector);
  const selectedTableRowId = useSelector(selectedTableRowIdSelector);
  const showMissPoints = useSelector(showMissPointsSelector);
  const commit = useSelector(commitSelector);
  const config = useSelector(selectConfig);
  const campaign = useSelector(selectCampaign);
  const { findingsDetails } = useFindingsDetails();
  const setTooth = useSetTooth();

  const { prevMarks, markersData } = usePrevMarks();

  const toothMarkersDataTransformed = () => {
    const marks = cloneDeep(markersData);
    marks.forEach((m) => {
      if (m.subType && m.subType === NOT_SELECTED_STR) {
        m.subType = '';
      }
      if (m.material && m.material === NOT_SELECTED_STR) {
        m.material = '';
      }
    });
    return marks;
  };

  const missPointsRes = useMissPoint();
  const missPoints = missPointsRes?.missPoints;
  const isTraining = campaign && isTrainingMode(config, campaign);

  const dataOfMarker = isImageLevel
    ? TIER_3
      ? markersData.concat(prevMarks).sort(labelingToolLogic.compareMarkers) //sort markings in the table by id
      : showMissPoints && missPoints && (!commit || isTraining)
      ? markersData.concat(missPoints)
      : markersData
    : toothMarkersDataTransformed();

  const handleMarkingSelection = (item) => {
    dispatch(setSelectedTableRowId(-1)); //if marking in progress
    onMarkerSelected(item);
  };

  const handleSelectionFromTableOrImage = (mark) => {
    handleMarkingSelection({ x: mark.x, y: mark.y, type: mark.type });
  };

  const handleToothMarkingSelection = (item) => {
    const mark = getToothMarkers().find((m) => m.id === item.id);
    if (mark) {
      setTooth(mark);
    } else {
      if (Object.keys(currentMarker).length > 0 && toothRightClick) {
        dispatch(setMarkEdited(true));
      }
    }
  };

  const data = [...dataOfMarker].sort((a, b) => a.id - b.id);
  if (isImageLevel && isTraining && showMissPoints) {
    data.forEach((item) => {
      item.X = item.id;
      if (item.pointStatus === PointStatus.MISTAKE) item.X += ' (X)';
    });
  }
  isImageLevel &&
    data.forEach((d) => {
      const finding = findingsDetails.find((f) => f.FindingId === d.findingsId);
      d['findingName'] = finding?.FindingName || 'Loading...';
      d['detailName'] =
        finding?.Details.find((detail) => detail.id === d.detailsId).name ||
        'Loading...';
    });

  return (
    data && (
      <div className={styles.wrapperFlex1}>
        {!(isToothLevel && isXRay(currentTask)) && (
          <Table
            width={500}
            height={tableHeight}
            headerHeight={30}
            data={data}
            columns={
              isImageLevel
                ? isTraining && showMissPoints
                  ? imageLevelTrainingColumns
                  : imageLevelColumns
                : toothLevelColumns
            }
            onRowSelect={(mark) => {
              isImageLevel
                ? handleSelectionFromTableOrImage(mark)
                : handleToothMarkingSelection(mark);
            }}
            selectedRowId={selectedTableRowId}
            tableName={'markings'}
            rowStyle={({ index }) => ({
              backgroundColor:
                showMissPoints &&
                data?.[index]?.pointStatus === PointStatus.POTENTIAL_MISS
                  ? index % 2
                    ? COLOR_BLUE
                    : COLOR_LIGHT_BLUE
                  : null,
            })}
          />
        )}
      </div>
    )
  );
};

MarksDetailsTable.propTypes = {
  /**
   * On markerselected logic
   */
  onMarkerSelected: PropTypes.func,
};

export default MarksDetailsTable;
