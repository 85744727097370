import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  currentContouringSelector,
  shapeInProgressSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
} from '../../../../../../redux/marks/currentMarkingSlice';
import { setRectangle } from '../../../../shared-logic';
import { BOUNDING_BOX } from '../../../../../../shared-logic/enums';
import { useActionsCurrentShapeData } from '../../../../customHooks';
import RectangleContainer from './Rectangle/RectangleContainer';
import { shouldRenderShape } from '../shared-logic';

const Rectangles = ({
  rectangles,
  hideAreas,
  hideMarks,
  onMarkerCreated,
  isNiri,
}) => {
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);

  const currentContouring = useSelector(currentContouringSelector);
  const [selectedRect, setSelectedRect] = useState(null);
  const { getCurrentShapeData } = useActionsCurrentShapeData();
  const isBoundingBoxInProgress = shapeInProgress === BOUNDING_BOX;

  useEffect(() => {
    const shape = isBoundingBoxInProgress && getCurrentShapeData(BOUNDING_BOX);
    shape ? setSelectedRect(shape.data) : setSelectedRect(null);
  }, [
    currentContouring,
    shapeInProgress,
    getCurrentShapeData,
    isBoundingBoxInProgress,
  ]);
  const imageSize = isNiri ? imageSizeNiri : imageSizeColor;
  const getRectDimensions = ({ width, height, y, x }) =>
    setRectangle(
      x,
      y,
      width,
      height,
      imageSize.width,
      imageSize.height,
      imageSize.naturalWidth,
      imageSize.naturalHeight
    );

  //check if rectangle in list and rectangle received from redux are the same
  const isSelectedRectangle = (rect) => isEqual(rect, selectedRect);

  //render rectangle as simple div if it is not selected
  const renderCurrentRectangle = (rectangle, isSelectedRect, index) => {
    return (
      <RectangleContainer
        key={index}
        rectangle={rectangle}
        onMarkerCreated={onMarkerCreated}
        editMode={false}
        hideMarks={false}
        isNiri={isNiri}
        selectedRec={isSelectedRect}
      />
    );
  };

  //render rectangle component with editing functionality if it is selected
  const editCurrentRectangle = (rectangle, isSelectedRect, index) => (
    <RectangleContainer
      key={index}
      rectangle={rectangle}
      onMarkerCreated={onMarkerCreated}
      editMode={true}
      isNiri={isNiri}
      selectedRec={isSelectedRect}
    />
  );

  const addNewRectangle = (isSelectedRect) => (
    <RectangleContainer
      hideMarks={hideMarks}
      onMarkerCreated={onMarkerCreated}
      editMode={true}
      isNiri={isNiri}
      selectedRec={true}
    />
  );

  return (
    <div>
      {rectangles.map((rect, index) => {
        const rectangle = getRectDimensions(rect);
        const isSelectedRect = isSelectedRectangle(rect);

        if (shouldRenderShape(hideAreas, hideMarks, isSelectedRect)) {
          return isSelectedRect
            ? editCurrentRectangle(rectangle, isSelectedRect, index)
            : renderCurrentRectangle(rectangle, isSelectedRect, index);
        }
        return null;
      })}
      {isBoundingBoxInProgress && !selectedRect && addNewRectangle()}
    </div>
  );
};

export default Rectangles;

Rectangles.prototype = {
  /**
   * rectangles array positions
   */
  rectangles: PropTypes.array,
  /**
   * Should hide areas
   */
  hideAreas: PropTypes.bool,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
  /**
   *when a new marker is created
   */
  onMarkerCreated: PropTypes.func,
  /**
   * was a niri or color type
   */
  isNiri: PropTypes.bool,
};

Rectangles.defaultProps = {
  rectangles: [],
};
