import { cloneDeep } from 'lodash';
import { Materials } from '../../ToothLabeling/Labeling/Materials';
import { SubTypes } from '../../ToothLabeling/Labeling/SubTypes';
import { Types } from '../../ToothLabeling/Labeling/Types';

const commentsHeader = 'Comments';
const mapDetailsToLabelers = (cluster) => {
  const detailsToLabelers = new Map();
  cluster.forEach((m, i) => {
    if (detailsToLabelers.has(m.details)) {
      detailsToLabelers.set(
        m.details,
        detailsToLabelers.get(m.details) + ', ' + m.labeler
      );
    } else {
      detailsToLabelers.set(m.details, m.labeler);
    }
    detailsToLabelers.set(
      `${commentsHeader} ${i}`,
      `- ${commentsHeader} (${m.labeler}): ${m.comments}`
    );
  });
  return detailsToLabelers;
};

export const getClusterText = (cluster) => {
  const detailsToLabelers = mapDetailsToLabelers(cluster);
  let labelText = [];
  detailsToLabelers.forEach((value, key) => {
    if (key.includes('undefined')) {
      return;
    }
    if (key.startsWith(commentsHeader) && !isNaN(key.slice(-1))) {
      labelText.push({ text: `${value}` });
    } else {
      labelText.push({ text: `${key}: ${value}`, bold: true });
    }
  });
  return labelText;
};

const removeLastCommaFromString = (str) => str.replace(/,\s*$/, '');

const sortData = (data) =>
  data
    .flat(1)
    .sort((a, b) =>
      a.details > b.details ? 1 : b.details > a.details ? -1 : 0
    );

export const prepareTaggingData = (data) => {
  if (!data || data.length === 0) return [];
  let combinedData = {
    ...cloneDeep(data.consistent),
    ...cloneDeep(data.inconsistent),
  };
  const notSelectedStr = 'not selected';
  const taggingData = Object.keys(combinedData).map((key) => {
    return combinedData[key].map((item) => {
      let details = ``;
      if (item.type && item.type.toLowerCase() !== notSelectedStr) {
        details += `${Types[item.type] ? Types[item.type] : item.type}, `;
      }
      if (item.subType && item.subType.toLowerCase() !== notSelectedStr) {
        details += `${
          SubTypes[item.subType] ? SubTypes[item.subType] : item.subType
        }, `;
      }
      if (item.material && item.material.toLowerCase() !== notSelectedStr) {
        details += `${
          Materials[item.material] ? Materials[item.material] : item.material
        } `;
      }
      details = removeLastCommaFromString(details);
      return { details, labeler: key.substring(0, 2), comments: item.comments };
    });
  });
  return sortData(taggingData);
};
