import React from 'react';
import { NotificationManager } from 'react-notifications';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LabeledSwitch, SubmitHiddenButton } from '../../Components';
import TabForm from '../TabForm';
import styles from './AddOrEditBatch.module.css';

const UNSELECTED_CAMPAIGN_VALUE = 'Do not connect to campaign';

const AddOrEditBatch = (props) => {
  const {
    tasksListLogic,
    campaignsList,
    batchName,
    setBatchName,
    isDataForAIDetections,
    setIsDataForAIDetections,
    isDataForGroundTruthLabels,
    setIsDataForGroundTruthLabels,
    connectToCampaign,
    setConnectToCampaign,
    submitRef,
    fetchData,
  } = props;
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newBatch = {
      batchName,
      connectToCampaign,
      isDataForAIDetections,
      isDataForGroundTruthLabels,
    };

    try {
      await tasksListLogic.postAddBatch(newBatch);
      await fetchData();
      resetParameters();

      NotificationManager.success('Batch added successfuly', 'Success!');
    } catch (error) {
      console.error('Error adding batch:', error);
      NotificationManager.error(
        'An error occurred while adding the batch. Please try again later.',
        'Warning'
      );
    }
  };

  const resetParameters = () => {
    setBatchName('');
    setIsDataForAIDetections(false);
    setIsDataForGroundTruthLabels(false);
    setConnectToCampaign(undefined);
  };

  return (
    <TabForm className={styles.tabForm} onSubmit={handleSubmit}>
      <TextField
        required
        label="Batch Name"
        value={batchName}
        onChange={(e) => setBatchName(e.target.value)}
      />

      <LabeledSwitch
        checked={isDataForAIDetections}
        setChecked={setIsDataForAIDetections}
        label={'Contains data for AI detections'}
      />

      <LabeledSwitch
        checked={isDataForGroundTruthLabels}
        setChecked={setIsDataForGroundTruthLabels}
        label={'Contains data for Ground truth labels'}
      />
      <FormControl>
        <InputLabel id="select-campaign-label">Connect To Campaign</InputLabel>
        <Select
          labelId="select-campaign-label"
          required
          label={'Connect To Campaign'}
          value={connectToCampaign}
          onChange={(e) =>
            setConnectToCampaign(
              e.target.value === UNSELECTED_CAMPAIGN_VALUE
                ? undefined
                : e.target.value
            )
          }
        >
          <MenuItem
            key={UNSELECTED_CAMPAIGN_VALUE}
            value={UNSELECTED_CAMPAIGN_VALUE}
          >
            {UNSELECTED_CAMPAIGN_VALUE}
          </MenuItem>
          {campaignsList?.map((campaign) => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SubmitHiddenButton type="submit" ref={submitRef} />
    </TabForm>
  );
};

export default AddOrEditBatch;
