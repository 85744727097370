import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UpperJaw from './UpperJaw';
import LowerJaw from './LowerJaw';
import PrepToggle from './PrepToggle';
import Dot from './Dot';
import styles from './Jaws.module.css';
import { Tasks } from '../../shared-logic/enums';

const propTypes = {
  /**
   * If `true`, lower jaw is disabled
   */
  lowerDisabled: PropTypes.bool,
  /**
   * If `true`, lower jaw is selected
   */
  lowerSelected: PropTypes.bool,
  /**
   * Callback, fired when a jaw element is clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * If `true`, upper jaw is disabled
   */
  upperDisabled: PropTypes.bool,
  /**
   * If `true`, upper jaw is selected
   */
  upperSelected: PropTypes.bool,
  /**
   * If `true`, jaws are 3 ways toggleable
   */
  isJawToggleExist: PropTypes.bool,
  /**
   * object containing all available preps
   */
  preps: PropTypes.object,
  /**
   * Function to handle right click on jaw
   */
  handleRightClick: PropTypes.func,
};

const defaultProps = {
  biteDisabled: false,
  lowerDisabled: false,
  lowerSelected: false,
  upperDisabled: false,
  upperSelected: false,
  upperJawToggleSelectedOption: '0',
  lowerJawToggleSelectedOption: '0',
  isMultiBite: false,
  multiBiteActive: false,
  preps: {},
};

const Jaws = (props) => {
  const {
    lowerDisabled,
    lowerSelected,
    upperDisabled,
    upperSelected,
    onClick,
    isJawToggleExist,
    className: classNameProp,
    preps,
    style,
    isUpperJaw,
    handleRightClick,
  } = props;

  const upperJawProps = {
    className: styles.upperJaw,
    disabled: upperDisabled,
    selected: upperSelected,
    onClick: () => onClick(Tasks.UPPER, props),
  };

  const lowerJawProps = {
    className: styles.lowerJaw,
    disabled: lowerDisabled,
    selected: lowerSelected,
    onClick: () => onClick(Tasks.LOWER, props),
  };

  const className = classNames(classNameProp, styles.jaws);
  return (
    <div className={className} style={style}>
      {!isJawToggleExist && isUpperJaw && <UpperJaw {...upperJawProps} />}
      {!isJawToggleExist && !isUpperJaw && <LowerJaw {...lowerJawProps} />}

      {preps &&
        Object.values(preps).map((prep, i) => {
          const className = classNames(
            styles.prepToggle,
            styles[`ada${prep.id}`]
          );
          const prepProps = {
            ...prep,
            onClick: () => onClick(`ada${prep.id}`, props),
            key: prep.id,
            className,
          };
          return (
            <div key={i}>
              <Dot {...prep} />
              <PrepToggle {...prepProps} handleRightClick={handleRightClick} />
            </div>
          );
        })}
    </div>
  );
};

Jaws.propTypes = propTypes;
Jaws.defaultProps = defaultProps;

export default Jaws;
