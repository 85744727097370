import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import styles from './CampaignGuidanceEdit.module.css';
import PropTypes from 'prop-types';
import { RichTextEditor } from '../../components';

export const CampaignGuidanceEdit = ({
  textToEdit,
  isEditableRole,
  saveCampaignGuidance,
}) => {
  const [html, setHtml] = useState(textToEdit);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setHtml(textToEdit);
  }, [textToEdit]);

  const handleChange = (value) => {
    setHtml(value);
  };

  const sanitizeConf = {
    allowedTags: [
      'i',
      'em',
      'strong',
      'a',
      'p',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'ul',
      'li',
      'ol',
      'b',
      'br',
      'font',
    ],
    allowedAttributes: { a: ['href'], font: ['color', 'size'] },
  };

  const sanitize = () => {
    setHtml(sanitizeHtml(html, sanitizeConf));
  };

  const onClick = () => {
    saveCampaignGuidance(html);
    setEditMode(false);
  };

  return (
    <div>
      <h3>Campaign Guidance</h3>
      <ContentEditable className={styles.textArea} html={html} disabled />
      {!editMode && isEditableRole && (
        <button className={styles.button} onClick={() => setEditMode(true)}>
          Edit
        </button>
      )}
      {isEditableRole && editMode && (
        <div className={styles.popup}>
          <div className={styles.popupInner}>
            <div className={styles.popupHeader}>
              <h3>Campaign Edit</h3>
              <button
                className={styles.closeButton}
                onClick={() => setEditMode(false)}
              >
                ✖
              </button>
            </div>
            <div className={styles.popupContent}>
              <RichTextEditor
                className={styles.dialogTextArea}
                value={html}
                onChange={handleChange}
                onBlur={sanitize}
              />
            </div>
            <div className={styles.popupFooter}>
              <button className={styles.saveButton} onClick={onClick}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CampaignGuidanceEdit.propTypes = {
  textToEdit: PropTypes.string,
  saveCampaignGuidance: PropTypes.func,
  isEditableRole: PropTypes.bool,
};

export default CampaignGuidanceEdit;
