import * as JSZip from 'jszip';
import { logToTimber } from '../../shared-logic/timberLogger';

export const getZippedObject = async (arraybufferData) => {
  return await JSZip.loadAsync(arraybufferData);
};

export const extractFile = async (zippedData, fileName, type) => {
  try {
    return await zippedData.file(fileName).async(type);
  } catch (e) {
    logToTimber({
      timberData: {
        value: fileName,
        response: e,
      },
    });
    throw new Error(`Missing ${fileName} or corrupted data` + e);
  }
};
