import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentMarkerSelector,
  currentPhotoIndexSelector,
  setCurrentContouring,
} from '../../../redux/marks/currentMarkingSlice';
import { outputSelector } from '../../../redux/taskStateImages/outputSlice';
import useHandleNewOutputPerType from './useHandleNewOutputPerType';
import { buildContouring } from '../ImageFrameManipulation/ImageMarking/Shapes/shared-logic';
import { isEqual } from 'lodash';

const useActionsCurrentShapeData = () => {
  const output = useSelector(outputSelector, isEqual);
  const currentMarker = useSelector(currentMarkerSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const dispatch = useDispatch();
  const handleNewOutputPerType = useHandleNewOutputPerType();
  const imageIndex = output?.imagePairs?.findIndex(
    (item) => item.id === currentPhotoIndex
  );
  const markerIndex =
    imageIndex !== -1 &&
    output?.imagePairs?.[imageIndex][currentMarker?.type]?.markings?.findIndex(
      (item) => item.id === currentMarker.id
    );

  const getCurrentContouring = useCallback(() => {
    return (
      imageIndex !== -1 &&
      output?.imagePairs[imageIndex][currentMarker?.type]?.markings[markerIndex]
        ?.contouring
    );
  }, [currentMarker, imageIndex, markerIndex, output]);

  const getCurrentShapeData = useCallback(
    (type) =>
      getCurrentContouring()?.length &&
      getCurrentContouring().find((item) => item.type === type),
    [getCurrentContouring]
  );

  //remove shape data from output
  const removeCurrentShapeData = useCallback(
    (type) => {
      const currentContouring = getCurrentContouring();
      if (currentContouring) {
        const updatedContouring = currentContouring?.filter(
          (c) => c.type !== type
        );
        const prev = cloneDeep(output);
        prev.imagePairs[imageIndex][currentMarker.type].markings[
          markerIndex
        ].contouring = updatedContouring;
        handleNewOutputPerType(prev);
        dispatch(setCurrentContouring(updatedContouring));
      }
    },
    [
      currentMarker.type,
      dispatch,
      getCurrentContouring,
      handleNewOutputPerType,
      imageIndex,
      markerIndex,
      output,
    ]
  );

  //add shape data to output
  const addCurrentShapeData = useCallback(
    (data, shape) => {
      const updatedContouring = buildContouring(
        getCurrentContouring(),
        data,
        shape
      );
      const prev = cloneDeep(output);
      prev.imagePairs[imageIndex][currentMarker.type].markings[
        markerIndex
      ].contouring = updatedContouring;
      handleNewOutputPerType(prev);
    },
    [
      currentMarker.type,
      getCurrentContouring,
      handleNewOutputPerType,
      imageIndex,
      markerIndex,
      output,
    ]
  );

  return {
    getCurrentContouring,
    getCurrentShapeData,
    removeCurrentShapeData,
    addCurrentShapeData,
  };
};

export default useActionsCurrentShapeData;
