import tasksListLogic from '../pages/TasksList/TasksList.logic';
import useGetApiConfigs from './apiConfigs';

let tasksListLogicInstance = null;
const useGetTaskListLogic = () => {
  const apiConfigs = useGetApiConfigs();
  if (tasksListLogicInstance === null) {
    tasksListLogicInstance = tasksListLogic(apiConfigs);
  }
  return tasksListLogicInstance;
};

export default useGetTaskListLogic;
