import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Transformer, Rect } from 'react-konva';
import PropTypes from 'prop-types';
import styles from './Rectangle.module.css';
import Done from '../../../../../Done/Done';
import {
  BLACK,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  ENTER,
  ESCAPE,
  KEY_DOWN,
  RED,
  TOP_LEFT,
  TOP_RIGHT,
  WHITE,
  YELLOW,
} from '../../shared-logic';
import { BOUNDING_BOX } from '../../../../../../../shared-logic/enums';
import submitEditingDialog from '../../../../../Dialogs/submitEditingDialog';
import { mouseUpHandler } from '../../../ImageMarking.logic';
import classNames from 'classnames';

const Rectangle = ({
  isRenderTypeMatchMarkerType,
  rectangle,
  saveShapeData,
  setShapeInProgress,
  setCurrentShapeData,
  width,
  height,
  hideMarks,
  onMarkerCreated,
  editMode,
  shapeInProgress,
  selectedRect,
}) => {
  const [newRect, setNewRect] = useState(null);
  const [first, setFirst] = useState(rectangle ? false : true);
  const [rect, setRect] = useState(rectangle);
  const [currentRect, setCurrentRect] = useState(null);
  const [isSelected, setIsSelected] = useState(selectedRect);
  const [shapeDimensions, setShapeDimensions] = useState(null);
  const shapeRef = useRef();
  const transformerRef = useRef();
  const isShapeInProgress =
    shapeInProgress === BOUNDING_BOX && isRenderTypeMatchMarkerType;
  const stageClassName = classNames(styles.stage, {
    [styles.upLayer]: isShapeInProgress && !selectedRect,
    [styles.selected]: selectedRect,
  });
  useEffect(() => {
    if (rectangle && !shapeInProgress) setRect(rectangle);
  }, [rectangle, shapeInProgress]);

  useEffect(() => {
    setIsSelected(selectedRect);
  }, [selectedRect]);

  useEffect(() => {
    if (newRect) setCurrentRect(newRect);
    else if (rect) setCurrentRect(rect);
    else setCurrentRect(null);
  }, [rect, newRect]);

  useEffect(() => {
    if (isSelected && transformerRef.current) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected, currentRect, hideMarks]);

  const handleKeyPress = useCallback(
    (e) => {
      if (shapeInProgress === BOUNDING_BOX) {
        if (e.key === ENTER) {
          saveShapeData(BOUNDING_BOX, shapeDimensions);
        } else if (e.key === ESCAPE) {
          setShapeInProgress(null);
        }
      }
    },
    [shapeDimensions, saveShapeData, setShapeInProgress, shapeInProgress]
  );

  useEffect(() => {
    document.addEventListener(KEY_DOWN, handleKeyPress);
    return () => {
      document.removeEventListener(KEY_DOWN, handleKeyPress);
    };
  }, [handleKeyPress]);

  const saveRectangle = () => {
    saveShapeData(BOUNDING_BOX, shapeDimensions);
  };

  const showSubmitEditingDialog = (e) => {
    const mouseDownPosition = {
      mouseDownX: e.clientX,
      mouseDownY: e.clientY,
    };
    submitEditingDialog(
      saveRectangle,
      () => setShapeInProgress(null),
      () => {
        mouseUpHandler(e, mouseDownPosition, false, onMarkerCreated);
      }
    );
  };

  const checkClickOnEmpty = (e) => {
    if (editMode && e.target === e.target.getStage()) {
      showSubmitEditingDialog(e.evt);
    }
  };

  const saveRect = (rect) => {
    setRect(rect);
    setCurrentShapeData(rect);
  };

  const handleMouseDown = (e) => {
    if (!newRect && first) {
      const { x, y } = e.target.getStage().getPointerPosition();
      setNewRect({ x, y, width: 0, height: 0 });
    } else {
      checkClickOnEmpty(e);
    }
  };

  const handleMouseUp = (e) => {
    if (newRect) {
      const sx = newRect.x;
      const sy = newRect.y;
      const { x, y } = e.target.getStage().getPointerPosition();
      const width = x - sx;
      const height = y - sy;
      setNewRect(null);
      setFirst(false);
      saveRect({
        x: sx,
        y: sy,
        width: width ? width : 1,
        height: height ? height : 1,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (newRect && !rect) {
      const sx = newRect.x;
      const sy = newRect.y;
      const { x, y } = e.target.getStage().getPointerPosition();
      setNewRect({
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
      });
    }
  };

  const onSelect = () => {
    setIsSelected(true);
  };
  const onMouseDown = (e) => {
    if (editMode && e.target !== e.target.getStage()) {
      showSubmitEditingDialog(e.evt);
    }
  };

  const onChange = (newAttrs) => {
    saveRect(newAttrs);
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    setShapeDimensions({
      scaleX,
      scaleY,
      skewX: node.skewX(),
      rotation: node.rotation(),
    });
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      ...currentRect,
      x: node.x(),
      y: node.y(),
      width: node.width() * scaleX,
      height: node.height() * scaleY,
    });
  };

  return (
    <>
      {!hideMarks && (
        <Stage
          className={stageClassName}
          width={width}
          height={height}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onTouchMove={handleMouseMove}
        >
          <Layer>
            {currentRect && (
              <>
                <Rect
                  {...currentRect}
                  className={styles.rect}
                  stroke={editMode ? RED : YELLOW}
                  onClick={onSelect}
                  onTap={onSelect}
                  ref={shapeRef}
                  onTransformEnd={handleTransformEnd}
                  onMouseDown={onMouseDown}
                  strokeWidth={2}
                />
                {isSelected && (
                  <Transformer
                    ref={transformerRef}
                    rotateEnabled={false}
                    anchorStroke={BLACK}
                    anchorFill={WHITE}
                    enabledAnchors={[
                      TOP_LEFT,
                      TOP_RIGHT,
                      BOTTOM_LEFT,
                      BOTTOM_RIGHT,
                    ]}
                    borderStroke={editMode ? RED : YELLOW}
                    keepRatio={false}
                    anchorCornerRadius={100}
                    anchorSize={6}
                  />
                )}
              </>
            )}
          </Layer>
        </Stage>
      )}
      {editMode && isSelected && rect && (
        <Done handleDoneClick={saveRectangle} />
      )}
    </>
  );
};

export default Rectangle;

Rectangle.propTypes = {
  /**
   * rectangle to render
   */
  rectangle: PropTypes.object,
  /**
   * function to save data of shape
   */
  saveShapeData: PropTypes.func,
  /**
   * function to set shape in progress
   */
  setShapeInProgress: PropTypes.func,
  /**
   * function to set current shape data
   */
  setCurrentShapeData: PropTypes.func,
  /**
   *  the stage width
   */
  width: PropTypes.number,
  /**
   *  the stage height
   */
  height: PropTypes.number,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
  /**
   *when a new marker is created
   */
  onMarkerCreated: PropTypes.func,
  /**
   * the shape string that is in progress
   */
  shapeInProgress: PropTypes.string,
  /**
   * Should select rectangle
   */
  selectedRect: PropTypes.bool,
};

Rectangle.defaultProps = {
  rectangle: null,
};
