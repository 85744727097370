import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import tasksReducer from './tasks/tasksSlice';
import outputReducer from './taskStateImages/outputSlice';
import contrastBrightnessContrastReducer from './taskState/contrastBrightnessSlice';
import rotationReducer from './taskState/rotationSlice';
import currentMarkingReducer from './marks/currentMarkingSlice';
import labelingToolReducer from './labelingTool/labelingToolSlice';
import taskDetailsReducer from './taskState/taskDetailsSlice';
import stateImagesLowerReducer from './taskStateImages/stateImagesLowerSlice';
import stateImagesUpperReducer from './taskStateImages/stateImagesUpperSlice';
import stateImagesXRayReducer from './taskStateImages/stateImagesXRaySlice';

export default configureStore({
  reducer: {
    tasks: tasksReducer,
    output: outputReducer,
    contrastBrightness: contrastBrightnessContrastReducer,
    rotation: rotationReducer,
    currentMarking: currentMarkingReducer,
    labelingTool: labelingToolReducer,
    taskDetails: taskDetailsReducer,
    stateImagesLower: stateImagesLowerReducer,
    stateImagesUpper: stateImagesUpperReducer,
    stateImagesXRay: stateImagesXRayReducer,
  },
  middleware: [...getDefaultMiddleware({ immutableCheck: false })],
});
