import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import modalStyles from '../cancelTaskDialog/cancelTaskDialog.module.css';

const { customUi, dialogBtns, dialogBtn } = modalStyles;

const completeTaskDialog = (completeTask) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={customUi}>
          <section>
            <h3>Mark case completed</h3>
            <p>Are you sure you want to complete the case?</p>
          </section>
          <footer className={dialogBtns}>
            <button
              className={dialogBtn}
              onClick={() => {
                completeTask(onClose);
                onClose();
              }}
            >
              Ok
            </button>
            <button
              className={dialogBtn}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
          </footer>
        </div>
      );
    },
  });
};

export default completeTaskDialog;
