import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { runWithAdal } from 'react-adal';
import store from './redux/store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { adalAppConfig } from './config/adalAppConfig';
import { awsLabelingAppConfig } from './config/awsLabelingAppConfig';
import { getAuthContext } from './config/adalConfig';

const DO_NOT_LOGIN = false;
awsLabelingAppConfig.init().then(() => {
  adalAppConfig.init().then(() => {
    const authContext = getAuthContext();
    runWithAdal(
      authContext,
      () => {
        ReactDOM.render(
          // <React.StrictMode>
          <Provider store={store}>
            <Router>
              <App />
            </Router>
          </Provider>,
          // </React.StrictMode>,
          document.getElementById('root')
        );
      },
      DO_NOT_LOGIN
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
});
