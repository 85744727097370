import userActionLogs from '../../../../shared-logic/userActionLogs';
import { generateMarkId } from '../sharedLogic';
import {
  MISSING_TOOTH,
  NOT_SELECTED_STR,
} from '../../../../shared-logic/params';
import { isEqual } from 'lodash';
import { checkIsMarkNew, findCurrentToothData } from '../../shared-logic';
import { TaskLevels } from '../../../../shared-logic/enums';

export const onDeleteToothMark = (prev, currentToothIndex, currentMarker) => {
  const currentToothMarkings = findCurrentToothData(prev, currentToothIndex);
  const currentToothNewMarkings = currentToothMarkings.markings.filter(
    (m) => m.id !== currentMarker.id
  );
  const toothInTeethIndex = prev.teeth.findIndex(
    (t) => t.id === currentToothIndex
  );
  prev.teeth[toothInTeethIndex].markings = currentToothNewMarkings;
  return { ...prev };
};

const findCurrentToothIndex = (prev, toothIndex) => {
  return prev.teeth.findIndex((m) => m.id === toothIndex);
};

export const onNewToothOutput = (
  prev,
  markState,
  toothIndex,
  toothRightClick,
  recentIndication,
  pointData,
  currentContouring,
  setIndexToothMark,
  prevMarks = []
) => {
  const { toothMarkIndex, type, subType, material, probability, comments } =
    markState;

  const updateMarkings = (id, isToothExist) => {
    if (type === NOT_SELECTED_STR) {
      return isToothExist ? prev.teeth[currentToothIndex].markings : [];
    }
    const marker = {
      id,
      type,
      subType,
      material,
      probability,
      comments,
    };
    return toothRightClick && recentIndication?.prevToothMarkings?.length > 1
      ? recentIndication.prevToothMarkings
      : isToothExist
      ? [...prev.teeth[currentToothIndex].markings, marker]
      : [marker];
  };

  const currentToothIndex = findCurrentToothIndex(prev, toothIndex);
  const isPointUpdated =
    type === MISSING_TOOTH ||
    (pointData &&
      !isEqual(
        prev.teeth[currentToothIndex]?.point?.position,
        pointData.position
      ));
  let log;
  if (isPointUpdated) {
    if (type === MISSING_TOOTH) {
      log = `point of tooth ${toothIndex} is disabled because tooth set to missing`;
    } else {
      log = `new point of tooth ${toothIndex} at image ${pointData.imageNumber} (${pointData.type})`;
    }
  } else {
    log = `new tooth mark tooth ${toothIndex}`;
  }
  userActionLogs.addActionLog(log);

  const currentTooth = prev.teeth[currentToothIndex];
  const markersData = currentTooth ? currentTooth.markings : [];
  if (currentToothIndex === -1) {
    // no tooth yet
    const id = generateMarkId(markersData, prevMarks);
    prev.teeth.push({
      id: toothIndex,
      markings:
        isPointUpdated && type !== MISSING_TOOTH
          ? []
          : updateMarkings(id, false),
      point: isPointUpdated ? pointData : null,
    });
  } else {
    if (pointData) {
      prev.teeth[currentToothIndex].point = pointData;
    }
    const existingMark = prev.teeth[currentToothIndex].markings.find(
      (m) => m.id === toothMarkIndex
    );
    if (existingMark) {
      existingMark.type = type;
      existingMark.subType = subType;
      existingMark.material = material;
      existingMark.comments = comments;
      existingMark.probability = probability;
    } else {
      const mark = { type, subType, material, comments, probability };
      const isMarkNew = checkIsMarkNew(
        currentContouring,
        currentToothIndex,
        TaskLevels.TOOTH,
        [
          {
            id: prev.teeth[currentToothIndex].id,
            markings: prev.teeth[currentToothIndex].markings ?? [],
          },
        ],
        mark
      );
      if (!isMarkNew) {
        //check to prevent addition of two markings instead of one
        return prev;
      }
      // tooth exists mark doesn't
      const id = generateMarkId(markersData, prevMarks);
      setIndexToothMark(id);
      prev.teeth[currentToothIndex].markings = updateMarkings(id, true);
    }
  }

  return { ...prev };
};
