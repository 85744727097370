// function to determine perceived brightness of RGB color
const rgbToLuma = (px, pos) => {
  return px[pos] * 0.299 + px[pos + 1] * 0.587 + px[pos + 2] * 0.114;
};

const mapSliderValue = (sliderValue) => (2 / 100) * sliderValue;

const calculateNewPixel = (originalPixel, defaultACPixel, sliderValue) =>
  originalPixel * (1 - sliderValue) + defaultACPixel * sliderValue;

export const parseImage = (
  canvasRef,
  isNiri,
  width,
  height,
  sliderValue,
  setACsrc,
  imageWithoutAC,
  idealACrgbaPixelsRef
) => {
  const mappedSliderValue = mapSliderValue(sliderValue);
  const context = canvasRef.current?.getContext('2d');

  context.drawImage(imageWithoutAC, 0, 0, width, height);

  // get image data
  const imageData = context.getImageData(0, 0, width, height);
  const rgbaData = imageData.data; // array containing the data in the RGBA order. each pixel is represented by four one-byte values
  const originalImagePixels = [...rgbaData];

  if (!idealACrgbaPixelsRef.current) {
    //undergo these calculations only if they have not been done before
    var i,
      min = -1,
      max = -1;
    let threshold = isNiri ? 0.1 : 0;
    const histogram = new Uint32Array(256); // histogram buffer

    // get lumas and build histogram
    for (i = 0; i < rgbaData.length; i += 4) {
      const luma = Math.round(rgbToLuma(rgbaData, i));
      histogram[luma]++; // add to the luma bar (and why we need an integer)
    }

    // find tallest bar so we can use that to scale threshold
    const maxHeight = Math.max(...histogram); // to find scale of histogram

    // use that for threshold
    threshold *= maxHeight;

    // find min value
    for (i = 0; i < histogram.length * 0.5; i++) {
      if (histogram[i] > threshold) {
        min = i;
        break;
      }
    }
    if (min < 0) {
      min = 0; // not found, set to default 0
    } else if (min > 20) {
      min = 20;
    }

    // find max value
    for (i = histogram.length - 1; i > histogram.length * 0.5; i--) {
      if (histogram[i] > threshold) {
        max = i;
        break;
      }
    }
    if (max < 0) {
      max = 255; // not found, set to default 255
    }

    const defaultColorScale = 1.5;
    const defaultNiriScale = (255 / (max - min)) * 2;

    const scale = isNiri ? defaultNiriScale : defaultColorScale;

    const maxScale = (i) => Math.max(0, rgbaData[i] - min) * scale;

    // scale all pixels
    for (i = 0; i < rgbaData.length; i += 4) {
      rgbaData[i] = maxScale(i);
      rgbaData[i + 1] = maxScale(i + 1);
      rgbaData[i + 2] = maxScale(i + 2);
    }
    idealACrgbaPixelsRef.current = [...rgbaData]; //save ideal AC rgba data array to the relevant ref to not calculate it every time parseImage is invoked
  }

  const rgbaArray = idealACrgbaPixelsRef.current || rgbaData;

  for (i = 0; i < rgbaArray.length; i++) {
    rgbaData[i] = calculateNewPixel(
      originalImagePixels[i],
      rgbaArray[i],
      mappedSliderValue
    );
  }

  context.putImageData(imageData, 0, 0);
  const dataUrl = canvasRef.current.toDataURL('image/jpeg');
  setACsrc(dataUrl);
};
