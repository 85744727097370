import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Dot.module.css';
import Tooth from '../../../pages/LabelingToolPage/ToothLabeling/toothEnum';

const Dot = ({ id, isPointDefined, isPointMarkable }) => {
  const classId = `${Tooth.PREFIX}${id}`;
  const className = classNames(styles.dot, styles[classId], {
    [styles.isPointDefined]: isPointDefined && isPointMarkable,
    [styles.isNotMarkable]: isPointMarkable === false,
  });

  return <div className={className} />;
};

Dot.propTypes = {
  /**
   * tooth id
   */
  id: PropTypes.number,
  /**
   * When `true`, point is defined
   */
  isPointDefined: PropTypes.bool,
  /**
   * When `true`, point is markable
   */
  isPointMarkable: PropTypes.bool,
};

Dot.defaultProps = {
  isPointDefined: false,
  isPointMarkable: true,
};

export default Dot;
