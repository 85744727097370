import React from 'react';
import styles from './CheckBox.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CheckBox = ({ isChecked, onChange, label, classNameProp }) => {
  const className = classNames(styles.checkbox, classNameProp);

  return (
    <div className={className}>
      <label>
        <input type="checkbox" checked={isChecked} onChange={onChange} />
        {label}
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  /**
   * If `true`, will render as checked
   */
  isChecked: PropTypes.bool,
  /**
   * Callback, fire when checkbox is changed
   */
  onChange: PropTypes.func,
  /**
   * label of checkBox
   */
  label: PropTypes.string,
  /**
   * className for styling checkBox
   */
  classNameProp: PropTypes.string,
};

export default CheckBox;
