import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './MoreButtons.module.css';
import BugReportByUser from '../../../bugReport/BugReportByUser';
import { GroupedDropDownList } from '../../../../components';
import { isTier2 } from '../../../../shared-logic/tiersHelpers';
import { isWatcher } from '../../../TasksList/rolesUtil';
import { selectRole } from '../../../../redux/tasks/tasksSlice';
import {
  tierSelector,
  potentiallyDiscardedSelector,
} from '../../../../redux/taskState/taskDetailsSlice';
import { NotificationManager } from 'react-notifications';
import { useGetLabelingToolPageLogic } from '../../../../hooks';
import {
  assigneeSelector,
  taskLevelSelector,
  taskShortNickNameSelector,
} from '../../../../redux/taskState/taskDetailsSlice';
import { useCancelTask } from '../../customHooks';
const REMOVE = 'REMOVE';
const REPORT_A_BUG = 'Report a Bug';
const TASK_INAPPLICABLE = 'Task Inapplicable';
const RETURN_TO_WORKING = 'Return to Working';
const DELETE_TASK = 'Delete Task';

const MoreButtons = () => {
  const labelingToolLogic = useGetLabelingToolPageLogic();
  const role = useSelector(selectRole);
  const tier = useSelector(tierSelector);
  const assignee = useSelector(assigneeSelector);
  const taskShortNickName = useSelector(taskShortNickNameSelector);
  const level = useSelector(taskLevelSelector);

  const potentiallyDiscarded = useSelector(potentiallyDiscardedSelector);
  const [openBugWindow, setOpenBugWindow] = useState(false);

  const cancelTask = useCancelTask();

  const onClickSelection = async (selection) => {
    switch (selection) {
      case REPORT_A_BUG:
        await setOpenBugWindow(true);
        break;
      case TASK_INAPPLICABLE:
        await cancelTask();
        break;
      case RETURN_TO_WORKING:
        await returnToWork();
        break;
      case DELETE_TASK:
        await cancelTask();
        break;
      default:
        return null;
    }
  };

  const TIER_2 = isTier2(tier);

  const contentList = [
    REPORT_A_BUG,
    !potentiallyDiscarded && !isWatcher(role) && !TIER_2 && TASK_INAPPLICABLE,
    !isWatcher(role) && potentiallyDiscarded && RETURN_TO_WORKING,
    !isWatcher(role) && potentiallyDiscarded && DELETE_TASK,
  ].filter(Boolean);

  const returnToWork = async () => {
    const data = {
      assignee,
      taskShortNickName,
      level,
      action: REMOVE,
    };
    try {
      await labelingToolLogic.potentiallyDeleteRevert(data);
      NotificationManager.success('Return to Working Successfully', 'Success!');
    } catch (err) {
      NotificationManager.error('Failed to Return Task', 'Warning');
    }
  };

  return (
    <>
      <GroupedDropDownList
        className={styles.buttonCdn}
        id="chooseDropDownListMoreButton"
        width={75}
        selectedValue={'More ...'}
        items={[
          {
            content: contentList,
          },
        ]}
        onChange={(selection) => onClickSelection(selection)}
        noSelectionText={'More ...'}
      />
      {openBugWindow && (
        <BugReportByUser
          openWindow={true}
          setOpenWindow={setOpenBugWindow}
          taskShortNickName={taskShortNickName}
        />
      )}
    </>
  );
};

export default MoreButtons;
