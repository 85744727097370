import { useGetLabelingToolPageLogic } from '../../../hooks';
import { TYPE_NIRI, TYPE_COLOR, TYPE_XRAY } from '../../../shared-logic/enums';
import {
  isImage,
  isXRay,
  isTooth,
} from '../../../shared-logic/taskLevelsTypesHelper';
import { useSelector } from 'react-redux';
import {
  currentTaskSelector,
  taskLevelSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  outputSelector,
  prevTierStateSelector,
} from '../../../redux/taskStateImages/outputSlice';
import { xRayPrevStateSelector } from '../../../redux/taskStateImages/stateImagesXRaySlice';
import { currentPhotoIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import { useGetToothMarkers, useImageMarksData } from '../customHooks';
import { isEqual } from 'lodash';
import { getPrevMarkers } from '../shared-logic';

const usePrevMarks = () => {
  const labelingToolLogic = useGetLabelingToolPageLogic();
  const taskLevel = useSelector(taskLevelSelector);
  const currentTask = useSelector(currentTaskSelector);
  const isToothLevel = isTooth(taskLevel);
  const output = useSelector(outputSelector, isEqual);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const xRayPrevState = useSelector(xRayPrevStateSelector);
  const prevTierState = useSelector(prevTierStateSelector);
  const isImageLevel = isImage(taskLevel);

  const currentImagePair =
    (!isToothLevel &&
      output.imagePairs.filter((pair) => pair.id === currentPhotoIndex)) ||
    [];
  const currentPair = currentImagePair ? currentImagePair[0] : null;
  const currentPrevState = isXRay(currentTask) ? xRayPrevState : prevTierState;
  const { imageMarkersData, imagesXRayMarkersData } = useImageMarksData({
    currentPair,
  });
  const getToothMarkers = useGetToothMarkers();
  const markersData = isImageLevel
    ? isXRay(currentTask)
      ? imagesXRayMarkersData
      : imageMarkersData
    : getToothMarkers();

  const prevMarks =
    currentPair && !currentPair.markings
      ? //niri color
        [
          ...getPrevMarkers(TYPE_NIRI, currentPair, currentPrevState),
          ...getPrevMarkers(TYPE_COLOR, currentPair, currentPrevState),
        ].sort(labelingToolLogic.compareMarkers)
      : //xray
        [...getPrevMarkers(TYPE_XRAY, currentPair, currentPrevState)].sort(
          labelingToolLogic.compareMarkers
        );

  return { prevMarks, markersData };
};

export default usePrevMarks;
