import { cloneDeep, isEqual } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  markEditedSelector,
  toothRightClickSelector,
  rowSelectedSelector,
  setSelectedTableRowId,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  commentsSelector,
  currentContouringSelector,
  currentPhotoIndexSelector,
  currentToothIndexSelector,
  currentTypeSelector,
  findingSelector,
  detailSelector,
  materialSelector,
  probabilitySelector,
  setCurrentMarker,
  setProbability,
  subTypeSelector,
  toothMarkIndexSelector,
  typeSelector,
  setCurrentContouring,
  currentMarkerSelector,
  setToothMarkIndex,
  showMissPointsSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  outputSelector,
  prevTierStateSelector,
  setIsOutputModified,
} from '../../../redux/taskStateImages/outputSlice';
import { xRayPrevStateSelector } from '../../../redux/taskStateImages/stateImagesXRaySlice';
import {
  currentTaskSelector,
  recentIndicationSelector,
  setRecentIndication,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { approvedPrevTierSelector } from '../../../redux/taskStateImages/outputSlice';
import {
  isImage,
  isTooth,
  isXRay,
} from '../../../shared-logic/taskLevelsTypesHelper';
import {
  handleNewOutputImageLevel,
  handleNewOutputToothLevel,
  handleNewOutputXRayLevel,
} from '../markingStateUtil/state.util';
import { isValidToothIndication } from '../shared-logic';
import {
  brightnessSelector,
  colorBrightnessSelector,
  colorContrastSelector,
  contrastSelector,
  niriBrightnessSelector,
  niriContrastSelector,
} from '../../../redux/taskState/contrastBrightnessSlice';
import useHandleNewOutputPerType from './useHandleNewOutputPerType';
import { NOT_SELECTED_DETAIL_ID } from '../../../shared-logic/params';
import { useEffect } from 'react';
import useMissPoint from './useMissPoint';
import { useGetLabelingToolPageLogic } from '../../../hooks';

const useHandleNewOutput = () => {
  const dispatch = useDispatch();
  const { details, detailsId } = useSelector(detailSelector);
  const type = useSelector(typeSelector);
  const subType = useSelector(subTypeSelector);
  const material = useSelector(materialSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const { findings, findingsId } = useSelector(findingSelector);
  const probability = useSelector(probabilitySelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const toothMarkIndex = useSelector(toothMarkIndexSelector);
  const comments = useSelector(commentsSelector);
  const currentToothIndex = useSelector(currentToothIndexSelector);
  const niriBrightness = useSelector(niriBrightnessSelector);
  const niriContrast = useSelector(niriContrastSelector);
  const colorBrightness = useSelector(colorBrightnessSelector);
  const colorContrast = useSelector(colorContrastSelector);
  const contrast = useSelector(contrastSelector);
  const brightness = useSelector(brightnessSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const tier = useSelector(tierSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const markEdited = useSelector(markEditedSelector);
  const approvedPrevTier = useSelector(approvedPrevTierSelector);
  const output = useSelector(outputSelector, isEqual);
  const currentTask = useSelector(currentTaskSelector);
  const recentIndication = useSelector(recentIndicationSelector, isEqual);
  const toothRightClick = useSelector(toothRightClickSelector);
  const prevMarks = useSelector(prevTierStateSelector);
  const xRayPrevState = useSelector(xRayPrevStateSelector);
  const currentMarker = useSelector(currentMarkerSelector);
  const showMissPoints = useSelector(showMissPointsSelector);
  const labelingToolLogic = useGetLabelingToolPageLogic();

  const isImageLevel = isImage(taskLevel);
  const isToothLevel = isTooth(taskLevel);
  const isTaskXRay = isXRay(currentTask);

  const handleNewOutputPerType = useHandleNewOutputPerType();
  const missPointsRes = useMissPoint();
  const missPoints = missPointsRes?.missPoints;
  useEffect(() => {
    currentMarker && dispatch(setSelectedTableRowId(currentMarker.id ?? -1)); //select table row on change of current marker
  }, [currentMarker, dispatch]);

  const handleNewOutput = useCallback(() => {
    if (
      detailsId !== NOT_SELECTED_DETAIL_ID ||
      isValidToothIndication(type, subType, material) ||
      (isToothLevel && currentContouring)
    ) {
      if (isImageLevel && !isTaskXRay) {
        handleNewOutputImageLevel({
          labelingToolLogic,
          rowSelected,
          markEdited,
          currentType,
          currentContouring,
          findings,
          findingsId,
          details,
          detailsId,
          niriBrightness,
          niriContrast,
          colorBrightness,
          colorContrast,
          approvedPrevTier,
          probability,
          currentPhotoIndex,
          tier,
          setOutput: (v) => handleNewOutputPerType(v),
          setIsOutputModified: (v) => dispatch(setIsOutputModified(v)),
          setCurrentMarker: (v) => dispatch(setCurrentMarker(v)),
          setProbability: (v) => dispatch(setProbability(v)),
          prev: cloneDeep(output),
          setRecentIndication: (v) => dispatch(setRecentIndication(v)),
          prevMarks,
          missPoints,
          showMissPoints,
        });
      } else if (isToothLevel) {
        handleNewOutputToothLevel({
          labelingToolLogic,
          rowSelected,
          markEdited,
          toothMarkIndex,
          type,
          subType,
          material,
          probability,
          comments,
          setOutput: (v) => handleNewOutputPerType(v),
          setIsOutputModified: (v) => dispatch(setIsOutputModified(v)),
          currentToothIndex,
          prev: cloneDeep(output),
          toothRightClick,
          recentIndication,
          currentContouring,
          currentPhotoIndex,
          setCurrentContouring: (v) => dispatch(setCurrentContouring(v)),
          setIndexToothMark: (v) => dispatch(setToothMarkIndex(v)),
        });
      } else if (isTaskXRay) {
        handleNewOutputXRayLevel({
          labelingToolLogic,
          rowSelected,
          markEdited,
          currentContouring,
          findingsId,
          findings,
          detailsId,
          details,
          brightness,
          contrast,
          approvedPrevTier,
          probability,
          currentPhotoIndex,
          tier,
          setOutput: (v) => handleNewOutputPerType(v),
          setIsOutputModified: (v) => dispatch(setIsOutputModified(v)),
          setCurrentMarker: (v) => dispatch(setCurrentMarker(v)),
          setProbability: (v) => dispatch(setProbability(v)),
          prev: cloneDeep(output),
          setRecentIndication: (v) => dispatch(setRecentIndication(v)),
          xRayPrevState,
        });
      }
    }
  }, [
    details,
    detailsId,
    type,
    subType,
    material,
    isToothLevel,
    currentContouring,
    isImageLevel,
    isTaskXRay,
    labelingToolLogic,
    rowSelected,
    markEdited,
    currentType,
    findings,
    findingsId,
    niriBrightness,
    niriContrast,
    colorBrightness,
    colorContrast,
    approvedPrevTier,
    probability,
    currentPhotoIndex,
    tier,
    output,
    prevMarks,
    missPoints,
    showMissPoints,
    handleNewOutputPerType,
    dispatch,
    toothMarkIndex,
    comments,
    currentToothIndex,
    toothRightClick,
    recentIndication,
    brightness,
    contrast,
    xRayPrevState,
  ]);

  return handleNewOutput;
};

export default useHandleNewOutput;
