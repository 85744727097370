import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setMarkEdited,
  setRowSelected,
  setSelectedTableRowId,
  setToothRightClick,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  setCurrentMarker,
  setSubType,
  setType,
  setMaterial,
  setComments,
  setToothMarkIndex,
  setCurrentContouring,
} from '../../../redux/marks/currentMarkingSlice';
import { MISSING_TOOTH, NOT_SELECTED_STR } from '../../../shared-logic/params';

const useSetTooth = () => {
  const dispatch = useDispatch();
  const setTooth = useCallback(
    (mark) => {
      dispatch(setRowSelected(true));
      dispatch(setCurrentMarker(mark));
      dispatch(setToothMarkIndex(mark.id));
      dispatch(setSelectedTableRowId(mark.id));
      dispatch(setType(mark.type ? mark.type : NOT_SELECTED_STR));
      dispatch(setSubType(mark.subType));
      dispatch(setMaterial(mark.material));
      dispatch(setComments(mark.comments));
      dispatch(setToothRightClick(false));
      dispatch(setMarkEdited(false));
      mark.type === MISSING_TOOTH && dispatch(setCurrentContouring(null));
    },
    [dispatch]
  );

  return setTooth;
};

export default useSetTooth;
