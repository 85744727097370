import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentPhotoIndexSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
  currentTypeSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setAutoCorrection,
  setColorBrightness,
  setColorContrast,
  setNiriBrightness,
  setNiriContrast,
  autoCorrectionSelector,
} from '../../../redux/taskState/contrastBrightnessSlice';
import AutoCorrection from './AutoCorrection';
import PropTypes from 'prop-types';
import { parseImage } from './AutoCorrection.logic';
import { TYPE_NIRI } from '../../../shared-logic/enums';
import { picturesSelector } from '../../../redux/taskStateImages/outputSlice';
import {
  currentTaskSelector,
  setCurrentColorACsrc,
  setCurrentNiriACsrc,
  setCurrentXRayACsrc,
} from '../../../redux/taskState/taskDetailsSlice';
import { isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import { resetImagesACsrc } from '../shared-logic';

const AutoCorrectionContainer = ({ isToothLevel }) => {
  const initialACSliderValue = 50;

  const canvasRef = useRef(null);
  const sliderRef = useRef(null);
  const currentType = useSelector(currentTypeSelector);

  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);
  const { naturalWidth, naturalHeight } =
    currentType === TYPE_NIRI ? imageSizeNiri : imageSizeColor;

  const autoCorrectionMode = useSelector(autoCorrectionSelector);
  const pictures = useSelector(picturesSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentTask = useSelector(currentTaskSelector);

  const dispatch = useDispatch();

  const [sliderValue, setSliderValue] = useState(initialACSliderValue);
  const [currentImages, setCurrentImages] = useState([]);

  const setDefaultSliderValue = useCallback(() => {
    sliderRef.current.value = initialACSliderValue;
  }, []);

  const controllerRef = useRef(new AbortController());
  const isAborted = controllerRef.current.signal.aborted;

  const idealNiriACPixelsRef = useRef(null);
  const idealColorACPixelsRef = useRef(null);
  const idealXRayACPixelsRef = useRef(null);

  const resetIdealACRefs = () => {
    idealNiriACPixelsRef.current = null;
    idealColorACPixelsRef.current = null;
    idealXRayACPixelsRef.current = null;
  };

  const prevPhotoIndexRef = useRef(0);
  const prevTaskRef = useRef(currentTask);
  useEffect(() => {
    //reset ideal AC rgba pixel arrays on all kinds of navigation
    if (
      currentPhotoIndex === prevPhotoIndexRef.current &&
      currentTask === prevTaskRef.current
    )
      return;
    setTimeout(() => resetIdealACRefs()); //TODO get rid of setTimeout. Currently we need to to ensure that we reset ideal AC once the new image pair was already set
    prevPhotoIndexRef.current = currentPhotoIndex;
    prevTaskRef.current = currentTask;
  }, [currentPhotoIndex, currentTask]);

  const applySliderValue = useCallback(
    (sliderValue) => {
      const promises = [];
      for (let i = 0; i < currentImages.length; i++) {
        const isXray = isXRay(currentTask);
        const isNiri = currentImages[i].classList.contains(TYPE_NIRI);
        const imageInitialSrc = isXray
          ? 'data:image/png;base64,' + pictures[currentPhotoIndex]
          : 'data:image/png;base64,' +
            pictures[currentPhotoIndex][isNiri ? 'niri' : 'color'];
        const setImageCurrentACsrc = (v) =>
          dispatch(
            isXray
              ? setCurrentXRayACsrc(v)
              : isNiri
              ? setCurrentNiriACsrc(v)
              : setCurrentColorACsrc(v)
          );
        const idealACrefRGBAPixels = isXray
          ? idealXRayACPixelsRef
          : isNiri
          ? idealNiriACPixelsRef
          : idealColorACPixelsRef;

        const imageWithoutAC = new Image();
        imageWithoutAC.src = imageInitialSrc;

        const generateParseImagePromise = () =>
          new Promise(() => {
            parseImage(
              canvasRef,
              isNiri,
              naturalWidth,
              naturalHeight,
              sliderValue,
              setImageCurrentACsrc, //function to set the src of autocorrected images to redux store
              imageWithoutAC,
              idealACrefRGBAPixels
            );
          });

        imageWithoutAC.addEventListener(
          'load',
          function () {
            if (isAborted) controllerRef.current = new AbortController();
            promises.push(generateParseImagePromise());
          },
          isAborted ? undefined : { signal: controllerRef.current.signal }
        );
      }
      Promise.all(promises);
    },
    [
      currentImages,
      currentTask,
      pictures,
      currentPhotoIndex,
      isAborted,
      dispatch,
      naturalWidth,
      naturalHeight,
    ]
  );

  const handleClick = useCallback(() => {
    setDefaultSliderValue();
    if (autoCorrectionMode) {
      dispatch(setAutoCorrection(false));
      resetImagesACsrc(dispatch); //remove image src with autocorrection from redux store
      setSliderValue(initialACSliderValue); //reset value in state
      resetIdealACRefs();
    } else {
      dispatch(setNiriBrightness(100));
      dispatch(setColorBrightness(100));
      dispatch(setNiriContrast(100));
      dispatch(setColorContrast(100));
      dispatch(setAutoCorrection(true));
    }
  }, [autoCorrectionMode, dispatch, setDefaultSliderValue]);

  useEffect(() => {
    const images = isToothLevel
      ? document.querySelectorAll('img[class*=niriModeImage]')
      : document.querySelectorAll('img[class*=ImageMarking]');
    setCurrentImages(images);
  }, [isToothLevel]);

  useEffect(() => {
    if (autoCorrectionMode) applySliderValue(sliderValue);
  }, [applySliderValue, autoCorrectionMode, sliderValue]);

  return (
    <AutoCorrection
      canvasRef={canvasRef}
      onClick={handleClick}
      naturalWidth={naturalWidth}
      naturalHeight={naturalHeight}
      autoCorrectionMode={autoCorrectionMode}
      setSliderValue={setSliderValue}
      sliderRef={sliderRef}
      signal={controllerRef.current}
    />
  );
};

export default AutoCorrectionContainer;

AutoCorrectionContainer.propTypes = {
  isToothLevel: PropTypes.bool,
};
