import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { currentPhotoIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import {
  approvedPrevTierSelector,
  outputSelector,
  picturesSelector,
  setCommit,
} from '../../../redux/taskStateImages/outputSlice';
import {
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { commitSelector } from '../../../redux/taskStateImages/outputSlice';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { onCommitOrApprovalChange } from '../markingStateUtil/imageLevel/setOutputImageUtil';
import {
  brightnessSelector,
  colorBrightnessSelector,
  colorContrastSelector,
  contrastSelector,
  niriBrightnessSelector,
  niriContrastSelector,
} from '../../../redux/taskState/contrastBrightnessSlice';
import { onCommitOrApprovalXRayChange } from '../markingStateUtil/imageLevel/setOutputXRayUtil';
import useHandleNewOutputPerType from './useHandleNewOutputPerType';
import { setIsOutputModified } from '../../../redux/taskStateImages/outputSlice';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { isEqual } from 'lodash';

const useHandleCommitOrApprovalChange = () => {
  const dispatch = useDispatch();

  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const approvedPrevTier = useSelector(approvedPrevTierSelector);
  const tier = useSelector(tierSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const output = useSelector(outputSelector, isEqual);
  const pictures = useSelector(picturesSelector);
  const commit = useSelector(commitSelector);
  const niriBrightness = useSelector(niriBrightnessSelector);
  const niriContrast = useSelector(niriContrastSelector);
  const colorBrightness = useSelector(colorBrightnessSelector);
  const colorContrast = useSelector(colorContrastSelector);
  const contrast = useSelector(contrastSelector);
  const brightness = useSelector(brightnessSelector);
  const isToothLevel = isTooth(taskLevel);

  const handleNewOutputPerType = useHandleNewOutputPerType();
  const currentPair =
    output.imagePairs &&
    output.imagePairs.find((p) => p.id === currentPhotoIndex);

  const currentPairAndDiffCommit =
    !currentPair ||
    (currentPair &&
      (currentPair.commited !== commit ||
        (currentPair.approvedPrevTier !== undefined &&
          currentPair.approvedPrevTier !== approvedPrevTier)));

  const setCommitToFalseNoPair = () => {
    if (!currentPair && commit) {
      userActionLogs.addActionLog(
        `commit set to false via setCommitToFalseNoPair`
      );
      dispatch(setCommit(false));
    }
  };

  const handleCommitOrApprovalChange = () => {
    if (isToothLevel) return;
    setCommitToFalseNoPair();
    if (currentPairAndDiffCommit) {
      const imagesState = {
        niriBrightness,
        niriContrast,
        colorBrightness,
        colorContrast,
        commit,
        approvedPrevTier,
      };
      handleNewOutputPerType(
        onCommitOrApprovalChange(
          cloneDeep(output),
          currentPhotoIndex,
          tier,
          imagesState,
          pictures[currentPhotoIndex]
        )
      );
    }
  };

  const handleCommitOrApprovalXRayChange = () => {
    if (isToothLevel) return;
    setCommitToFalseNoPair();
    if (currentPairAndDiffCommit) {
      const imagesState = {
        brightness,
        contrast,
        commit,
        approvedPrevTier,
      };
      handleNewOutputPerType(
        onCommitOrApprovalXRayChange(
          cloneDeep(output),
          currentPhotoIndex,
          tier,
          imagesState
        )
      );
      dispatch(setIsOutputModified(true));
    }
  };

  return { handleCommitOrApprovalChange, handleCommitOrApprovalXRayChange };
};

export default useHandleCommitOrApprovalChange;
