import { Box } from '@mui/material';
import React from 'react';
import { disableEnter } from './SharedLogic';
import sharedStyles from './TabForm.module.css';
import classNames from 'classnames';

const TabForm = ({ children, className, onSubmit }) => {
  return (
    <Box
      className={classNames(sharedStyles.tabForm, className)}
      component="form"
      onSubmit={onSubmit}
      onKeyDown={disableEnter}
    >
      {children}
    </Box>
  );
};

export default TabForm;
