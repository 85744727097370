import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GroupedDropDownList } from '../../../components';
import styles from './Header.module.css';
import classNames from 'classnames';
import { isWatcher, isSupervisor } from '../rolesUtil';

import { selectAdminRole } from '../../../redux/tasks/tasksSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ManagementConsoleWindow from '../ManagementConsole/ManagementConsoleWindow';
const roleLabel = 'Role';
const roleWidth = 125;
export const Header = (props) => {
  const [isDataManagmentScreen, setIsDataManagmentScreen] = useState(false);
  const isAdminRole = useSelector(selectAdminRole);
  const isAdminPage = props.isAdminPage;
  const history = useHistory();

  const rolesList = (
    <GroupedDropDownList
      className={classNames([styles.roleStyle, 'roles'])}
      width={roleWidth}
      items={[
        {
          label: roleLabel,
          content: props.listRoles,
        },
      ]}
      onChange={async (item) => await props.onRoleChange(item)}
      selectedValue={props.role}
    />
  );

  return (
    <div className={styles.header}>
      <p className={styles.paragraph}>Welcome: {props.name}</p>
      {isAdminPage ? null : rolesList}
      <div className={styles.headerButtons}>
        {(isWatcher(props.role) || isSupervisor(props.role)) && (
          <button
            className={classNames([styles.roleStyle, 'roles'])}
            onClick={() => setIsDataManagmentScreen(true)}
          >
            Data Management
          </button>
        )}
        {isAdminRole && (
          <button
            className={classNames([styles.roleStyle, 'roles'])}
            onClick={async () => {
              await history.push({ pathname: isAdminPage ? '/' : '/manage' });
              isAdminPage && window.location.reload();
            }}
          >
            {isAdminPage ? 'Return to Home Page' : 'Campaign Management'}
          </button>
        )}
      </div>
      {isDataManagmentScreen && (
        <ManagementConsoleWindow
          setIsDataManagmentScreen={setIsDataManagmentScreen}
          campaignsToRolesMapIncludeHidden={
            props.campaignsToRolesMapIncludeHidden
          }
          campaignsToRolesMap={props.campaignsToRolesMap}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default Header;
