import React, { useEffect } from 'react';
import { useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import styles from './BugWindow.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const BugWindow = ({
  textToEdit,
  setOpenBugWindow,
  handleSendEmail,
  setOpenWindow,
}) => {
  const [html, setHtml] = useState(textToEdit);

  useEffect(() => {
    setHtml(textToEdit);
  }, [textToEdit]);

  const handleChange = (evt) => {
    setHtml(evt.target.value);
  };

  const sanitizeConf = {
    allowedTags: [
      'i',
      'em',
      'strong',
      'a',
      'p',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'ul',
      'li',
      'ol',
      'b',
      'br',
      'font',
    ],
    allowedAttributes: { a: ['href'], font: ['color', 'size'] },
  };

  const sanitize = () => {
    setHtml(sanitizeHtml(html, sanitizeConf));
  };

  const onClick = async () => {
    await setOpenBugWindow(false);
    await setOpenWindow(false);
    handleSendEmail(html);
  };
  const onClickCancel = () => {
    setOpenBugWindow(false);
    setOpenWindow(false);
  };
  const classNameEditTextArea = classNames([
    styles.textArea,
    styles.dialogTextArea,
  ]);

  return (
    <div>
      <div className={styles.popup}>
        <div className={styles.popupInner}>
          <div className={styles.popupContent}>
            <h3>Bug Description:</h3>
            <textarea
              className={classNameEditTextArea}
              value={html}
              onChange={handleChange}
              onBlur={sanitize}
            />
            <div className={styles.buttons}>
              <button className={styles.button} onClick={onClick}>
                send
              </button>
              <button className={styles.button} onClick={onClickCancel}>
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BugWindow.propTypes = {
  textToEdit: PropTypes.string,
  editable: PropTypes.bool,
  saveCampaignGuidance: PropTypes.func,
};

export default BugWindow;
