import { useRef, useState } from 'react';

const EditUserStates = ({ tasksListLogic }) => {
  const [selectedUser, setSelectedUser] = useState('');
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [name, setName] = useState('');
  const [group, setGroup] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedFilteredGroups, setSelectedFilteredGroups] = useState([]);
  const submitRef = useRef();

  const editUserProps = {
    tasksListLogic,
    selectedUser,
    setSelectedUser,
    email,
    setEmail,
    isActive,
    setIsActive,
    groupList,
    setGroupList,
    name,
    setName,
    group,
    setGroup,
    isAdmin,
    setIsAdmin,
    selectedFilteredGroups,
    setSelectedFilteredGroups,
    submitRef,
  };

  const editUserButtons = [
    {
      label: 'Apply Edits',
      onClick: () => submitRef.current.click(),
    },
  ];

  return { editUserProps, editUserButtons };
};

export default EditUserStates;
