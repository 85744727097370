import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavigationArrows.module.css';
import ArrowButton from '../ArrowButton';
import Directions from '../ArrowButton/directionsEnum';

const NavigationArrows = ({ width, height, amount, onNavigate }) => {
  const buttonWidth = width / 4;

  return (
    <div className={styles.container} style={{ height, width }}>
      <ArrowButton
        style={{ height, width: buttonWidth }}
        direction={Directions.DOUBLE_BACK}
        onClick={() => onNavigate(-amount)}
        height={height}
        className={'backwardSeveralImages'}
      />
      <ArrowButton
        style={{ height, width: buttonWidth }}
        direction={Directions.BACK}
        onClick={() => onNavigate(-1)}
        height={height}
        className={'prevImage'}
      />
      <ArrowButton
        style={{ height, width: buttonWidth }}
        direction={Directions.FORWARD}
        onClick={() => onNavigate(1)}
        height={height}
        className={'nextImage'}
      />
      <ArrowButton
        style={{ height, width: buttonWidth }}
        direction={Directions.DOUBLE_FORWARD}
        onClick={() => onNavigate(amount)}
        height={height}
        className={'forwardSeveralImages'}
      />
    </div>
  );
};

NavigationArrows.defaultProps = {
  height: 50,
  width: 200,
};

NavigationArrows.propTypes = {
  /**
   * The container width
   */
  width: PropTypes.number,
  /**
   * The container height
   */
  height: PropTypes.number,
  /**
   * The amount of images to move height
   */
  amount: PropTypes.number,
  /**
   * Event function when pressing a navigation button
   */
  onNavigate: PropTypes.func.isRequired,
};

export default NavigationArrows;
