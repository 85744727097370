import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { selectConfig } from '../../../../redux/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  getAllCampaignListActive,
  getAllCampaignListHidden,
  getCampaignList,
  getCampaignName,
} from '../../../../config/configUtil';
import 'react-dropdown-tree-select/dist/styles.css';
import styles from '../ExportType.module.css';
import { LabelsAndCampaigns } from './LabelsAndCampaigns';
import { useGetTaskListLogic } from '../../../../hooks';
import { getUserName } from '../../TasksList.logic';
import {
  getCampaignTreeData,
  getFlattenTreeNodeValues,
  downloadArn,
} from '../SharedLogic';

const Active = 'Active';
const Hidden = 'Hidden';
const params = 'params';
const batch = 'batches';

export const ExportLabelsforAI = () => {
  const config = useSelector(selectConfig);
  const tasksListLogic = useGetTaskListLogic();
  const userName = getUserName();

  const campaignListActive = getAllCampaignListActive(config, userName);
  const campaignListHidden = getAllCampaignListHidden(config, userName);

  const [optionsForExport, setOptionsForExport] = useState('');
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState(false);

  const fillData = () => {
    const dataOriginal = [
      getCampaignTreeData(config, Active, campaignListActive),
      getCampaignTreeData(config, Hidden, campaignListHidden),
    ];
    return dataOriginal;
  };
  const data = fillData();

  const onDataChange = (value) => {
    setShowCampaigns(true);
    setOptionsForExport(value);
    setShowExport(false);
  };

  const exportLabelsForAi = async (campaignList, optionsForExport) => {
    NotificationManager.success(
      'Request received. Data will be downloaded soon',
      'Success!'
    );
    for (const campaign of campaignList) {
      try {
        const campaigns = getCampaignList(config, campaign);
        if (!campaigns || !campaigns.hasOwnProperty(params)) continue;

        const batches = campaigns[params][batch];

        tasksListLogic
          .exportLabelsForAi(optionsForExport, batches)
          .then((res) => {
            if (!res.executionArn) {
              throw new Error('Failed to retrieve execution ARN.');
            }
            const campaignName = getCampaignName(config, campaign);
            downloadArn(
              tasksListLogic,
              res.executionArn,
              `${campaignName}-${optionsForExport}.zip`
            );
          })
          .catch((err) => {
            console.error('Error:', err);
            NotificationManager.error(
              err.message || 'An error occurred.',
              'Download Error'
            );
          });
      } catch (err) {
        NotificationManager.error(err, 'Warning');
      }
    }
  };

  const onExportClick = () => {
    const valuesToExport = getFlattenTreeNodeValues(selectedNodes, data);
    exportLabelsForAi(valuesToExport, optionsForExport);
  };

  const onTreeChange = (currentNode, selectedNodes) => {
    setShowExport(true);
    setSelectedNodes(selectedNodes);
  };

  return (
    <div className={styles.formcontainer}>
      <LabelsAndCampaigns
        optionsForExport={optionsForExport}
        onDataChange={onDataChange}
        showCampaigns={showCampaigns}
        data={data}
        onTreeChange={onTreeChange}
      />
      <button
        // className={styles.button}
        onClick={onExportClick}
        disabled={!showExport}
      >
        Export
      </button>
    </div>
  );
};

ExportLabelsforAI.propTypes = {
  exportLabelsForAi: PropTypes.func.isRequired,
};

export default ExportLabelsforAI;
