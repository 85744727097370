import { createSlice } from '@reduxjs/toolkit';
import {
  NOT_SELECTED_DETAIL,
  NOT_SELECTED_FINDING,
} from '../../shared-logic/params';

export const currentMarkingSlice = createSlice({
  name: 'currentMarking',
  initialState: {
    currentMarker: {},
    currentPhotoIndex: 0,
    prevPhotoIndex: null,
    currentContouring: {},
    currentType: null,
    finding: NOT_SELECTED_FINDING,
    detail: NOT_SELECTED_DETAIL,
    probability: '100%',
    type: 'Not Selected',
    subType: 'Not Selected',
    material: 'Not Selected',
    comments: '',
    toothMarkIndex: null,
    currentToothIndex: null,
    lowerPhotoIndex: 0,
    upperPhotoIndex: 0,
    'x-rayPhotoIndex': 0,
    supervisorNote: undefined,
    imageSizeNiri: {},
    imageSizeColor: {},
    shapeInProgress: null,
    currentShapeData: undefined,
    lastShape: null,
    showMissPoints: false,
    recentlyUsedDetails: [],
    openSelectHistory: false,
  },
  reducers: {
    setCurrentMarker: (state, action) => {
      state.currentMarker = action.payload;
    },
    setCurrentPhotoIndex: (state, action) => {
      state[action.payload.task + 'PhotoIndex'] = action.payload.index;
    },
    setPrevPhotoIndex: (state, action) => {
      state.prevPhotoIndex = action.payload;
    },
    setCurrentContouring: (state, action) => {
      state.currentContouring = action.payload;
    },
    setCurrentType: (state, action) => {
      state.currentType = action.payload;
    },
    setFinding: (state, action) => {
      state.finding = action.payload;
    },
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    setRecentlyUsedDetails: (state, action) => {
      state.recentlyUsedDetails = action.payload;
    },
    setProbability: (state, action) => {
      state.probability = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setSubType: (state, action) => {
      state.subType = action.payload;
    },
    setMaterial: (state, action) => {
      state.material = action.payload;
    },
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setToothMarkIndex: (state, action) => {
      state.toothMarkIndex = action.payload;
    },
    setCurrentToothIndex: (state, action) => {
      state.currentToothIndex = action.payload;
    },
    setSupervisorNote: (state, action) => {
      state.supervisorNote = action.payload;
    },
    setImageSizeNiri: (state, action) => {
      state.imageSizeNiri = action.payload;
    },
    setImageSizeColor: (state, action) => {
      state.imageSizeColor = action.payload;
    },
    setShapeInProgress: (state, action) => {
      state.shapeInProgress = action.payload;
    },
    setCurrentShapeData: (state, action) => {
      state.currentShapeData = action.payload;
    },
    setLastShape: (state, action) => {
      state.lastShape = action.payload;
    },
    setShowMissPoints: (state, action) => {
      state.showMissPoints = action.payload;
    },
    setOpenSelectHistory: (state, action) => {
      state.openSelectHistory = action.payload;
    },
  },
});

export const {
  setCurrentMarker,
  setCurrentPhotoIndex,
  setPrevPhotoIndex,
  setCurrentContouring,
  setCurrentType,
  setFinding,
  setDetail,
  setProbability,
  setRecentlyUsedDetails,
  setType,
  setSubType,
  setMaterial,
  setComments,
  setToothMarkIndex,
  setCurrentToothIndex,
  setSupervisorNote,
  setImageSizeNiri,
  setImageSizeColor,
  setShapeInProgress,
  setCurrentShapeData,
  setLastShape,
  setShowMissPoints,
  setOpenSelectHistory,
} = currentMarkingSlice.actions;

// selectors
export const currentMarkerSelector = (state) =>
  state.currentMarking.currentMarker;
export const currentPhotoIndexSelector = (state) => {
  return state.currentMarking[state.taskDetails.currentTask + 'PhotoIndex'];
};
export const prevPhotoIndexSelector = (state) =>
  state.currentMarking.prevPhotoIndex;
export const currentContouringSelector = (state) =>
  state.currentMarking.currentContouring;
export const currentTypeSelector = (state) => state.currentMarking.currentType;
export const findingSelector = (state) => state.currentMarking.finding;
export const detailSelector = (state) => state.currentMarking.detail;
export const recentlyUsedDetailsSelector = (state) =>
  state.currentMarking.recentlyUsedDetails;
export const probabilitySelector = (state) => state.currentMarking.probability;
export const typeSelector = (state) => state.currentMarking.type;
export const subTypeSelector = (state) => state.currentMarking.subType;
export const materialSelector = (state) => state.currentMarking.material;
export const commentsSelector = (state) => state.currentMarking.comments;
export const supervisorNoteSelector = (state) =>
  state.currentMarking.supervisorNote;
export const imageSizeNiriSelector = (state) =>
  state.currentMarking.imageSizeNiri;
export const imageSizeColorSelector = (state) =>
  state.currentMarking.imageSizeColor;

export const toothMarkIndexSelector = (state) =>
  state.currentMarking.toothMarkIndex;
export const currentToothIndexSelector = (state) =>
  state.currentMarking.currentToothIndex;
export const shapeInProgressSelector = (state) =>
  state.currentMarking.shapeInProgress;
export const currentShapeDataSelector = (state) =>
  state.currentMarking.currentShapeData;
export const lastShapeSelector = (state) => state.currentMarking.lastShape;
export const showMissPointsSelector = (state) =>
  state.currentMarking.showMissPoints;
export const openSelectHistorySelector = (state) =>
  state.currentMarking.openSelectHistory;

export default currentMarkingSlice.reducer;
