import {
  Button,
  FormControl,
  Stack,
  Tabs,
  Typography,
  Box,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Header from '../TasksList/Header';
import { getUserName } from '../TasksList/TasksList.logic';
import styles from './ManagementPage.module.css';
import {
  AddUser,
  EditUser,
  AddImageLevelFinding,
  EditImageLevelFinding,
  AddBatch,
  AddCampaign,
} from './Tabs';
import { useGetTaskListLogic } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfig, setUsersList } from '../../redux/tasks/tasksSlice';
import { getCampaignName, getCampaignGroup } from '../../config/configUtil';
import {
  AddUserStates,
  EditUserStates,
  AddImageLevelFindingStates,
  EditImageLevelFindingStates,
  AddBatchStates,
  AddCampaignStates,
} from './Hooks';
import { CustomTab } from './Components';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabPanelBox}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const ManagementPage = () => {
  const dispatch = useDispatch();
  const userName = getUserName();
  const [value, setValue] = useState(0);
  const tasksListLogic = useGetTaskListLogic();
  const config = useSelector(selectConfig);

  // Shared Data
  const [findingsList, setFindingsList] = useState([]);
  const [batchesList, setBatchesList] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);

  const fetchData = useCallback(async () => {
    const listOfUsers = await tasksListLogic.getListOfUsers();
    const listOfFindings = await tasksListLogic.getListOfFindings();
    const listOfBatches = await tasksListLogic.getListOfBatches();
    const listOfCampaigns = await tasksListLogic.getUserCampaignsAndRoles(
      config,
      userName
    );
    const listOfMappingCampaigns = [...listOfCampaigns.keys()].map((x) => ({
      id: x,
      name: getCampaignName(config, x),
      group: getCampaignGroup(config, x),
    }));
    dispatch(setUsersList(listOfUsers));
    setFindingsList(listOfFindings);
    setBatchesList(listOfBatches);
    setCampaignsList(listOfMappingCampaigns);
  }, [config, dispatch, tasksListLogic, userName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onButtonClicked = async (b) => {
    await b.onClick();
  };

  // Add User
  const { addUserProps, addUserButtons } = AddUserStates({ tasksListLogic });

  // Edit User
  const { editUserProps, editUserButtons } = EditUserStates({ tasksListLogic });

  // Add Image Level Findings
  const { addImageLevelFindingProps, addImageLevelFindingButtons } =
    AddImageLevelFindingStates({ tasksListLogic, findingsList });

  //  Edit Image Level Findings
  const { editImageLevelFindingProps, editImageLevelFindingButtons } =
    EditImageLevelFindingStates({ tasksListLogic, findingsList });

  //  Add Tooth Level Findings
  //  Edit Tooth Level Findings
  //  Add Batch
  const { addBatchProps, addBatchButtons } = AddBatchStates({
    tasksListLogic,
    campaignsList,
  });

  //  Add Campaign
  const { addCampaignProps, addCampaignButtons } = AddCampaignStates({
    tasksListLogic,
    campaignsList,
    findingsList,
    batchesList,
  });

  //  Add Case

  //

  const tabButtons = [
    addUserButtons,
    editUserButtons,
    addImageLevelFindingButtons,
    editImageLevelFindingButtons,
    null,
    null,
    addBatchButtons,
    addCampaignButtons,
  ];
  const [currentTabButtons, setCurrentTabButtons] = useState(tabButtons[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTabButtons(tabButtons[newValue] ?? []);
  };

  const FooterButtons = () => {
    return (
      <Stack className={styles.buttonsContainer} direction="row">
        {currentTabButtons
          ? currentTabButtons.map((b) => (
              <Button
                key={b.label}
                onClick={() => onButtonClicked(b)}
                variant="outlined"
                disabled={b.hidden}
              >
                {b.label}
              </Button>
            ))
          : null}
      </Stack>
    );
  };

  return (
    <Box className={styles.page}>
      <Header name={userName} isAdminPage={true} role="tier1" />
      <Box className={styles.body}>
        <Box className={styles.tabsContainer}>
          <Tabs orientation="vertical" value={value} onChange={handleChange}>
            <CustomTab label="Add User" {...a11yProps(0)} />
            <CustomTab label="Edit User" {...a11yProps(1)} />
            <CustomTab label="Add Image Level Finding" {...a11yProps(2)} />
            <CustomTab label="Edit Image Level Finding" {...a11yProps(3)} />
            {/* <CustomTab label="Add Tooth Level Finding" {...a11yProps(4)} /> */}
            <></>
            {/* <CustomTab label="Edit Tooth Level Finding" {...a11yProps(5)} /> */}
            <></>
            {/* <CustomTab label="Add Batch" {...a11yProps(6)} /> */}
            <></>
            <CustomTab label="Add Campaign" {...a11yProps(7)} />
            {/* <CustomTab label="Add Case" {...a11yProps(8)} /> */}
          </Tabs>
        </Box>
        <Box className={styles.frameContainer}>
          <FormControl>
            <TabPanel value={value} index={0}>
              <AddUser {...addUserProps} fetchData={fetchData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EditUser {...editUserProps} fetchData={fetchData} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AddImageLevelFinding
                {...addImageLevelFindingProps}
                fetchData={fetchData}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <EditImageLevelFinding
                {...editImageLevelFindingProps}
                fetchData={fetchData}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              Add Tooth Level Findings
            </TabPanel>
            <TabPanel value={value} index={5}>
              Edit Tooth Level Findings
            </TabPanel>
            <TabPanel value={value} index={6}>
              <AddBatch {...addBatchProps} fetchData={fetchData} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <AddCampaign {...addCampaignProps} fetchData={fetchData} />
            </TabPanel>
            <TabPanel value={value} index={8}>
              Add Case
            </TabPanel>
          </FormControl>
          <FooterButtons />
        </Box>
      </Box>
    </Box>
  );
};

export default ManagementPage;
