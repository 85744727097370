import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  imageSizeNiriSelector,
  imageSizeColorSelector,
  shapeInProgressSelector,
} from '../../../../../../redux/marks/currentMarkingSlice';
import { useActionsCurrentShapeData } from '../../../../customHooks';
import { setX, setY } from '../../../../shared-logic';
import PolygonLineContainer from './PolygonLine/PolygonLineContainer';

const PolygonsLines = ({
  shapesData,
  isNiri,
  shapeName,
  hideAreas,
  hideMarks,
}) => {
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const { getCurrentShapeData } = useActionsCurrentShapeData();
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);
  const imageSize = isNiri ? imageSizeNiri : imageSizeColor;
  const { width, height, naturalWidth, naturalHeight } = imageSize;
  const [selectedShape, setSelectedShape] = useState(null);
  const isShapeInProgress = shapeInProgress === shapeName;

  useEffect(() => {
    const selected = isShapeInProgress && getCurrentShapeData(shapeName);
    selected ? setSelectedShape(selected.data) : setSelectedShape(null);
  }, [getCurrentShapeData, isShapeInProgress, shapeName]);

  const isSelectedShape = (points) => {
    return isEqual(points, selectedShape);
  };
  return (
    <div>
      {Object.keys(imageSize).length > 0 &&
        shapesData &&
        shapesData.map((pointsArray, index) => {
          const points = pointsArray?.map((point) => [
            setX(point[0], naturalWidth, width),
            setY(point[1], naturalHeight, height),
          ]);
          const isSelected = selectedShape && isSelectedShape(pointsArray);
          return (
            <PolygonLineContainer
              key={index}
              points={points}
              isSelectedShape={isSelected}
              isNiri={isNiri}
              shapeName={shapeName}
              hideAreas={hideAreas}
              hideMarks={hideMarks}
            />
          );
        })}
      {isShapeInProgress && !selectedShape && (
        <PolygonLineContainer
          isNiri={isNiri}
          shapeName={shapeName}
          hideAreas={hideAreas}
          hideMarks={hideMarks}
          isSelectedShape={true}
        />
      )}
    </div>
  );
};
PolygonsLines.propTypes = {
  /**
   * shapesData position array
   */
  shapesData: PropTypes.array,
  /**
   * was a niri or color type
   */
  isNiri: PropTypes.bool,
  /**
   * shape name to render
   */
  shapeName: PropTypes.string,
  /**
   * Should hide areas
   */
  hideAreas: PropTypes.bool,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
};

PolygonsLines.defaultProps = {
  shapesData: [],
};

export default PolygonsLines;
