//functions to calculate point/rectangle positions based on original and rendered width and height
export const setX = (x, width, prevWidth) => (x * width) / prevWidth;
export const setY = (y, height, prevHeight) => (y * height) / prevHeight;

export const setSavedX = (x, width, prevWidth) =>
  Math.ceil(setX(x, width, prevWidth));
export const setSavedY = (y, height, prevHeight) =>
  Math.ceil(setY(y, height, prevHeight));

export const setRectangle = (
  x,
  y,
  shapeWidth,
  shapeHeight,
  currentWidth,
  currentHeight,
  prevWidth,
  prevHeight,
  valueToSave
) => {
  const setXFunction = valueToSave ? setSavedX : setX;
  const setYFunction = valueToSave ? setSavedY : setY;
  const width = setXFunction(shapeWidth, currentWidth, prevWidth);
  const height = setYFunction(shapeHeight, currentHeight, prevHeight);
  x = setXFunction(x, currentWidth, prevWidth);
  y = setYFunction(y, currentHeight, prevHeight);

  return { width, height, x, y };
};
