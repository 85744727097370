import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNiriBrightness,
  setNiriContrast,
  setContrast,
  setBrightness,
} from '../../../../redux/taskState/contrastBrightnessSlice';
import {
  niriBrightnessSelector,
  niriContrastSelector,
  contrastSelector,
  brightnessSelector,
} from '../../../../redux/taskState/contrastBrightnessSlice';
import { currentTaskSelector } from '../../../../redux/taskState/taskDetailsSlice';
import {
  ContrastBrightness,
  Tasks,
  TYPE_NIRI,
  TYPE_XRAY,
} from '../../../../shared-logic/enums';

const { CONTRAST, BRIGHTNESS } = ContrastBrightness;
const ADDITION_VALUE = 4;
const SUBTRACTION_VALUE = -4;

const NiriBrightnessContrastKeys = () => {
  const dispatch = useDispatch();
  const niriContrast = useSelector(niriContrastSelector);
  const niriBrightness = useSelector(niriBrightnessSelector);
  const currentTask = useSelector(currentTaskSelector);
  const xrayContrast = useSelector(contrastSelector);
  const xrayBrightness = useSelector(brightnessSelector);
  const { XRAY } = Tasks;

  const GetValuePerType = (type, contrastBrightness, operator) => {
    const valueToAddOrSubtract =
      operator === '+' ? ADDITION_VALUE : SUBTRACTION_VALUE;

    if (type === TYPE_XRAY && contrastBrightness === CONTRAST) {
      return xrayContrast + valueToAddOrSubtract;
    }
    if (type === TYPE_XRAY && contrastBrightness === BRIGHTNESS) {
      return xrayBrightness + valueToAddOrSubtract;
    }
    if (type === TYPE_NIRI && contrastBrightness === CONTRAST) {
      return niriContrast + valueToAddOrSubtract;
    }
    if (type === TYPE_NIRI && contrastBrightness === BRIGHTNESS) {
      return niriBrightness + valueToAddOrSubtract;
    }
  };

  const setContrastOrBrightness = (contrastBrightness, operator) => {
    const comparisonMaxValue = 200;
    const newValueXray = GetValuePerType(
      TYPE_XRAY,
      contrastBrightness,
      operator
    );
    const newValueNiri = GetValuePerType(
      TYPE_NIRI,
      contrastBrightness,
      operator
    );

    if (currentTask === XRAY && contrastBrightness === CONTRAST) {
      dispatch(setContrast(Math.min(newValueXray, comparisonMaxValue)));
    }

    if (currentTask === XRAY && contrastBrightness === BRIGHTNESS) {
      dispatch(setBrightness(Math.min(newValueXray, comparisonMaxValue)));
    }

    if (currentTask !== XRAY && contrastBrightness === BRIGHTNESS) {
      dispatch(setNiriBrightness(Math.min(newValueNiri, comparisonMaxValue)));
    }

    if (currentTask !== XRAY && contrastBrightness === CONTRAST) {
      dispatch(setNiriContrast(Math.min(newValueNiri, comparisonMaxValue)));
    }
  };

  useHotkeys(
    'shift+up',
    () => {
      setContrastOrBrightness(CONTRAST, '+');
    },
    [niriContrast, xrayContrast]
  );

  useHotkeys(
    'shift+down',
    () => {
      setContrastOrBrightness(CONTRAST, '-');
    },
    [niriContrast, xrayContrast]
  );

  useHotkeys(
    'ctrl+up',
    () => {
      setContrastOrBrightness(BRIGHTNESS, '+');
    },
    [niriBrightness, xrayBrightness]
  );

  useHotkeys(
    'ctrl+down',
    () => {
      setContrastOrBrightness(BRIGHTNESS, '-');
    },
    [niriBrightness, xrayBrightness]
  );
  return null;
};

export default NiriBrightnessContrastKeys;
