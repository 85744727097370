import React from 'react';
import PropTypes from 'prop-types';
import { Jaws, ArrowButton } from '../../../components';
import styles from './JawNavigation.module.css';
import Directions from '../../../components/ArrowButton/directionsEnum';
import { Tasks } from '../../../shared-logic/enums';
import { isUpper } from '../../../shared-logic/taskLevelsTypesHelper';

const JawNavigation = ({
  jawPart,
  currentToothIndex,
  handleAddFinding,
  preps,
  handleJawClick,
  checkedId,
  handleRightClick,
}) => {
  const isUpperJaw = isUpper(jawPart);
  const back = isUpperJaw ? -1 : 1;
  const forward = isUpperJaw ? 1 : -1;

  return (
    <div className={styles.flexContainer}>
      <Jaws
        preps={preps}
        isUpperJaw={isUpperJaw}
        onClick={(id) => handleJawClick(id, 0)}
        handleRightClick={handleRightClick}
      />
      <div>
        <button className={styles.addFinding} onClick={handleAddFinding}>
          Add Finding
        </button>
        <div className={styles.arrows}>
          <ArrowButton
            onClick={() => handleJawClick(checkedId, back)}
            className={styles.arrow}
            height={27}
            direction={Directions.BACK}
          />
          <p className={styles.toothNumText}>Tooth {currentToothIndex}</p>
          <ArrowButton
            onClick={() => handleJawClick(checkedId, forward)}
            className={styles.arrow}
            height={27}
            direction={Directions.FORWARD}
          />
        </div>
      </div>
    </div>
  );
};

JawNavigation.propTypes = {
  /**
   * Which jaw
   */
  jawPart: PropTypes.oneOf([Tasks.LOWER, Tasks.UPPER]),
  /**
   * The current tooth index selected
   */
  currentToothIndex: PropTypes.number,
  /**
   * Function to handle addition of multiple markings
   */
  handleAddFinding: PropTypes.func,
  /**
   * preps - object represnting teeth state
   */
  preps: PropTypes.object,
  /**
   * Function to handle jaw click
   */
  handleJawClick: PropTypes.func,
  /**
   * The current preps id od checked tooth
   */
  checkedId: PropTypes.string,
  /**
   * Function to handle right click on a jaw
   */
  handleRightClick: PropTypes.func,
};

export default JawNavigation;
