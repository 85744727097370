import {
  onDeleteImageXRayMark,
  onDeleteImageMark,
  onNewImageOutput,
} from './imageLevel/setOutputImageUtil';
import { onNewXRayOutput } from './imageLevel/setOutputXRayUtil';
import {
  onDeleteToothMark,
  onNewToothOutput,
} from './toothLevel/setOutputToothUtil';

import { Details } from '../MarkLabeling/Labeling/Details';
import { Findings } from '../MarkLabeling/Labeling/Findings';
import { Types } from '../ToothLabeling/Labeling/Types';
import { SubTypes } from '../ToothLabeling/Labeling/SubTypes';
import { Materials } from '../ToothLabeling/Labeling/Materials';
import {
  MISSING_TOOTH,
  NOT_SELECTED_FINDING_ID,
  NOT_SELECTED_DETAIL_ID,
} from '../../../shared-logic/params';

export const handleNewOutputImageLevel = ({
  labelingToolLogic,
  rowSelected,
  markEdited,
  currentType,
  currentContouring,
  findings,
  findingsId,
  details,
  detailsId,
  niriBrightness,
  niriContrast,
  colorBrightness,
  colorContrast,
  approvedPrevTier,
  probability,
  currentPhotoIndex,
  tier,
  setOutput,
  setIsOutputModified,
  setCurrentMarker,
  setProbability,
  prev,
  setRecentIndication,
  prevMarks,
  missPoints,
  showMissPoints,
}) => {
  if (
    (rowSelected && !markEdited) ||
    !currentType ||
    !currentContouring ||
    (findings === NOT_SELECTED_FINDING_ID && details === NOT_SELECTED_DETAIL_ID)
  ) {
    return;
  }
  const imagesState = {
    niriBrightness,
    niriContrast,
    colorBrightness,
    colorContrast,
    approvedPrevTier,
  };
  const markState = {
    currentContouring,
    findings: labelingToolLogic.getIndicationId(Findings, findings),
    findingsId: findingsId,
    details: labelingToolLogic.getIndicationId(Details, details),
    detailsId: detailsId,
    probability,
    currentType,
    markEdited,
  };
  if (!rowSelected) {
    setCurrentMarker({}); //we need to set current marker to an empty object because otherwise selected mark won't be set in useSelectMarkingHook's useEffect
  }
  setOutput(
    onNewImageOutput(
      prev,
      currentPhotoIndex,
      tier,
      imagesState,
      markState,
      rowSelected,
      setCurrentMarker,
      prevMarks,
      missPoints,
      showMissPoints
    )
  );
  setIsOutputModified(true);
  setRecentIndication({ findingsId, findings, detailsId, details });
  setProbability('100%');
};

export const handleNewOutputXRayLevel = ({
  labelingToolLogic,
  rowSelected,
  markEdited,
  currentContouring,
  findings,
  findingsId,
  details,
  detailsId,
  brightness,
  contrast,
  approvedPrevTier,
  probability,
  currentPhotoIndex,
  tier,
  setOutput,
  setIsOutputModified,
  setCurrentMarker,
  setProbability,
  prev,
  setRecentIndication,
  xRayPrevState,
}) => {
  if (
    (rowSelected && !markEdited) ||
    !currentContouring ||
    (findings === NOT_SELECTED_FINDING_ID && details === NOT_SELECTED_DETAIL_ID)
  ) {
    return;
  }
  const imagesState = {
    brightness,
    contrast,
    approvedPrevTier,
  };
  const markState = {
    currentContouring,
    findings: labelingToolLogic.getIndicationId(Findings, findings),
    findingsId: findingsId,
    details: labelingToolLogic.getIndicationId(Details, details),
    detailsId: detailsId,
    probability,
    markEdited,
  };
  if (!rowSelected) {
    setCurrentMarker({});
  }
  setOutput(
    onNewXRayOutput(
      prev,
      currentPhotoIndex,
      tier,
      imagesState,
      markState,
      rowSelected,
      xRayPrevState,
      setCurrentMarker
    )
  );
  setIsOutputModified(true);
  setRecentIndication({ findingsId, findings, detailsId, details });
  setProbability('100%');
};

export const handleDeleteImageLevel = (
  setDisableShortcuts,
  currentMarker,
  disableMarking,
  currentPairId,
  resetLabelingSelection,
  setOutput,
  setIsOutputModified,
  prev,
  deletePrevMark
) => {
  setDisableShortcuts(false);
  resetLabelingSelection();

  if (!currentMarker.id || disableMarking) {
    return;
  }

  deletePrevMark();
  setOutput(onDeleteImageMark(prev, currentPairId, currentMarker));

  setIsOutputModified(true);
};

export const handleDeleteImageXRayLevel = (
  setDisableShortcuts,
  currentMarker,
  disableMarking,
  currentPairId,
  resetLabelingSelection,
  setOutput,
  setIsOutputModified,
  prev
) => {
  setDisableShortcuts(false);
  if (!currentMarker.id || disableMarking) {
    resetLabelingSelection();
    return;
  }
  setOutput(onDeleteImageXRayMark(prev, currentPairId, currentMarker));
  setIsOutputModified(true);
  resetLabelingSelection();
};

export const handleDeleteToothLevel = (
  setDisableShortcuts,
  currentMarker,
  disableMarking,
  currentToothIndex,
  resetToothControls,
  setOutput,
  setIsOutputModified,
  prev
) => {
  setDisableShortcuts(false);
  if (!currentMarker.id || disableMarking) {
    resetToothControls();
    return;
  }
  setOutput(onDeleteToothMark(prev, currentToothIndex, currentMarker));
  setIsOutputModified(true);
  resetToothControls();
};

export const handleNewOutputToothLevel = ({
  labelingToolLogic,
  rowSelected,
  markEdited,
  toothMarkIndex,
  type,
  subType,
  material,
  probability,
  comments,
  setOutput,
  setIsOutputModified,
  currentToothIndex,
  prev,
  toothRightClick,
  recentIndication,
  currentContouring,
  currentPhotoIndex,
  setCurrentContouring,
  setIndexToothMark,
}) => {
  if (rowSelected && !markEdited) {
    return;
  }

  const markState = {
    toothMarkIndex,
    type: labelingToolLogic.getIndicationId(Types, type),
    subType: labelingToolLogic.getIndicationId(SubTypes, subType),
    material: labelingToolLogic.getIndicationId(Materials, material),
    probability,
    comments,
  };

  let pointState;
  if (markState.type !== MISSING_TOOTH) {
    pointState = currentContouring && {
      imageNumber: currentPhotoIndex,
      ...currentContouring[0].data,
    };
  }

  setOutput(
    onNewToothOutput(
      prev,
      markState,
      currentToothIndex,
      toothRightClick,
      recentIndication,
      pointState,
      currentContouring,
      setIndexToothMark
    )
  );
  setIsOutputModified(true);
};
