import convertCampaignIdToTreeObject from './ConvertCampaignIdToTreeObject';

const getNodeCampaignsData = (config, campaigns) =>
  campaigns.map((id) => convertCampaignIdToTreeObject(config, id));

const getCampaignTreeData = (
  config,
  label,
  campaignList,
  campaignForCurrentUser
) => {
  let filterdCampaignList = campaignList;
  if (campaignForCurrentUser) {
    filterdCampaignList = campaignList?.filter((item) =>
      [...campaignForCurrentUser.keys()].includes(item)
    );
  }
  return {
    label: label,
    value: label,
    children: getNodeCampaignsData(config, filterdCampaignList),
  };
};

export default getCampaignTreeData;
