import { TextField } from '@mui/material';
import React from 'react';

const DisplayNameInput = ({ name, setName }) => {
  return (
    <TextField
      required
      label="Display Name"
      value={name}
      onChange={(e) => setName(e.target.value)}
    />
  );
};

export default DisplayNameInput;
