import { toothMarkOverrideDialog } from '../../Dialogs';
import { findCurrentToothData, isMissingTooth } from '../../shared-logic';

const isEmptySpace = ({ nodeName, className }) =>
  nodeName === 'svg' ||
  (className &&
    typeof className !== 'object' &&
    !className.toLowerCase().includes('mark_marker'));

export const mouseUpHandler = (
  event,
  mouseDownPosition,
  output,
  disableMarking,
  currentToothIndex,
  initNewMark
) => {
  const { mouseDownX, mouseDownY } = mouseDownPosition;
  const { clientX, clientY } = event;
  const tooth = findCurrentToothData(output, currentToothIndex);

  if (disableMarking) return;
  if (!isMissingTooth(tooth) && isEmptySpace(event.target)) {
    if (mouseDownX === clientX && mouseDownY === clientY) {
      const { nativeEvent } = event;
      if (tooth?.point) {
        toothMarkOverrideDialog(() => initNewMark(nativeEvent));
      } else {
        initNewMark(nativeEvent);
      }
    }
  }
};

export const getRelevantMarkers = (
  output,
  currentPhotoIndex,
  niriOrColor,
  tier,
  id = false
) =>
  output?.teeth
    ?.filter(
      (t) =>
        t.point?.imageNumber === currentPhotoIndex &&
        t.point?.type === niriOrColor &&
        (id ? t.id === id : true)
    )
    .map((t) => ({
      ...t.point.position,
      type: t.point.type,
      tier: tier - 1,
      id: t.id,
    }));

export const calcImageSize = (
  containerWidth,
  containerHeight,
  imageWidth,
  imageHeight
) => {
  const containerRatio = containerWidth / containerHeight;
  const imageRatio = imageWidth / imageHeight;
  let imgWidth;
  let imgHeight;
  if (containerRatio === imageRatio) {
    imgWidth = containerWidth;
    imgHeight = containerHeight;
  } else if (containerRatio > imageRatio) {
    imgWidth = containerWidth;
    imgHeight = imageHeight * (containerWidth / imageWidth);
  } else {
    imgWidth = imageWidth * (containerHeight / imageHeight);
    imgHeight = containerHeight;
  }

  return { width: imgWidth, height: imgHeight };
};

export const calculateSize = (
  height,
  retrieveImageHeight,
  imageRef,
  calculateImageSize
) => {
  retrieveImageHeight && retrieveImageHeight(height);
  if (imageRef && imageRef.current) calculateImageSize();
};
