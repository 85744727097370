import { AuthenticationContext } from 'react-adal';
import logger from '../logger';
import { adalAppConfig } from './adalAppConfig';

export const getAdalConfig = () => {
  return {
    tenant: adalAppConfig.getConfig()?.tenant,
    clientId: adalAppConfig.getConfig()?.clientId,
    redirectUri: window.location.origin,
    endpoints: {
      api: `https://${adalAppConfig.getConfig()?.tenant}/${
        adalAppConfig.getConfig()?.clientId
      }`,
    },
    cacheLocation: 'sessionStorage',
    navigateToLoginRequestUrl: false,
  };
};

export const getAuthContext = () => new AuthenticationContext(getAdalConfig());

export const getToken = async () => await getTokenHelper();

export const getUser = () => getAuthContext().getCachedUser();

function getTokenHelper() {
  return new Promise((resolve) => {
    getAuthContext().acquireToken(
      getAdalConfig().clientId,
      (errorDesc, token, error) => {
        if (token) {
          resolve(token);
        } else {
          logger
            .error('getTokenHelper')
            .data({ module: 'adalConfig', err: error })
            .end();
          if (getUser()) {
            getAuthContext().acquireTokenRedirect(
              getAdalConfig().endpoints.api
            );
          }
          resolve(null); // for tests
        }
      }
    );
  });
}
