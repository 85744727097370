import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Markers from './Markers';
import styles from './ImageMarking.module.css';
import BordersAndLabels from '../BordersAndLabels/BordersAndLabels';
import { useHotkeys } from 'react-hotkeys-hook';
import { keyupEvent, isKeyCode } from '../../../../shared-logic/keyFunctions';
import {
  KeyCodes,
  TYPE_COLOR,
  TYPE_NIRI,
  Tasks,
} from '../../../../shared-logic/enums';
import PinchZoomContainer from './PinchZoomContainer/PinchZoomContainer';
import { rotationSelector } from '../../../../redux/taskState/rotationSlice';
import {
  extractValueFromString,
  getDimensionsOfBordersAndTags,
} from '../ImageFrameManipulation.logic';
import {
  currentColorACsrcSelector,
  currentNiriACsrcSelector,
  currentTaskSelector,
  currentXRayACsrcSelector,
  imagesTypeSelector,
} from '../../../../redux/taskState/taskDetailsSlice';
import { useSelector } from 'react-redux';
import {
  mouseUpHandler,
  calcTouchCoordinates,
  getClusterText,
  getMinMaxXY,
  groupByCluster,
  addCenterPoint,
  isLabel,
} from './ImageMarking.logic';
import {
  imageSizeNiriSelector,
  imageSizeColorSelector,
  currentPhotoIndexSelector,
  setShapeInProgress,
  shapeInProgressSelector,
  lastShapeSelector,
  showMissPointsSelector,
  setLastShape,
  setOpenSelectHistory,
} from '../../../../redux/marks/currentMarkingSlice';
import {
  selectConfig,
  selectCampaign,
} from '../../../../redux/tasks/tasksSlice';
import {
  BORDER,
  BORDER_PADDING,
  COLOR_GREEN,
  COLOR_LIGHT_GREEN,
  COLOR_RED,
  COLOR_YELLOW,
  COLOR_BLUE,
  LABEL,
  PADDING_POSITIONING,
} from '../BordersAndLabels/BordersAndLabelsEnum';
import { useDispatch } from 'react-redux';
import { useOnLoadImage } from '../../customHooks';
import {
  isMarkingAreaEnabled,
  getAutoCorrectionDisable,
  isTrainingMode,
} from '../../../../config/configUtil';
import Shapes from './Shapes/Shapes';
import classNames from 'classnames';
import {
  isTier2,
  isTier3,
  isTier1,
} from '../../../../shared-logic/tiersHelpers';
import { isXRay } from '../../../../shared-logic/taskLevelsTypesHelper';
import { autoCorrectionSelector } from '../../../../redux/taskState/contrastBrightnessSlice';
import { commitSelector } from '../../../../redux/taskStateImages/outputSlice';
import useDetailsMap from '../../MarkLabeling/Labeling/useDetailsMap';

const { XRAY } = Tasks;
const ImageMarking = (props) => {
  const {
    width,
    height,
    src,
    brightness,
    contrast,
    onMarkerCreated,
    prevMarkers,
    markers,
    disableMarking,
    onMarkClick,
    hideMarks,
    hideAreas,
    tier,
    markings,
    isSelected,
    disableShortcuts,
    imageIsRotatedSideways,
    rotationStyles,
    xRayImageSize,
    internalImageScale,
    retrieveImageHeight,
    calculateImageSize,
    setLabelingImageTransformValue,
    isNiri,
    currentPair,
    missPoints,
    markersWrongUser,
    isNg,
  } = props;
  const imageRef = useRef(null);

  const currentTask = useSelector(currentTaskSelector);
  const imageRotation = useSelector(rotationSelector);
  const imagesType = useSelector(imagesTypeSelector);
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const lastShape = useSelector(lastShapeSelector);
  const showMissPoints = useSelector(showMissPointsSelector);
  const config = useSelector(selectConfig);
  const campaign = useSelector(selectCampaign);
  const commit = useSelector(commitSelector);
  const autoCorrectionDisabled = getAutoCorrectionDisable(config, campaign);
  const dispatch = useDispatch();
  const [showTags, setShowTags] = useState(false);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const [hoveredClusterNumber] = useState();
  const [numberOfTouches, setNumberOfTouches] = useState(0);
  const [imageScale, setImageScale] = useState(1);
  const [mouseDownPosition, setMouseDownPosition] = useState({
    mouseDownX: null,
    mouseDownY: null,
  });
  const onLoadImg = useOnLoadImage({ imageRef, calculateImageSize });
  const markingsWrapper = useRef(null);
  const touchCoordinates = useRef();
  const { detailsMap } = useDetailsMap();

  const XRAY_OFFSET_TOP_FOR_CENTER_IMAGE = 68.5;
  const MAX_MARK_SELECTION_DISTANCE = 50;

  const touchHandler = (event) => {
    touchCoordinates.current = {};
    //prevent marking logic if open select menu was touched or if user is pinching screen
    const target = event.target.className;
    if (
      (target &&
        typeof target !== 'object' &&
        (target.toLowerCase().includes('openselect') ||
          target.toLowerCase().includes('mark_marker'))) ||
      numberOfTouches > 1
    ) {
      return;
    } else if (isLabel(event)) {
      addCenterPoint(event, disableMarking, bordersAndTags, onMarkerCreated);
    } else {
      const coordinates = calcTouchCoordinates(
        TIER_2 || TIER_3 ? { ...event, target: imageRef.current } : event,
        imageScale,
        internalImageScale,
        imageRotation
      );

      //create marker
      if (disableMarking) return;
      onMarkerCreated({
        x: coordinates.x,
        y: coordinates.y,
        copy: false,
      });
    }

    //reset number of touches state when touch actions are over
    setNumberOfTouches(0);
  };

  const mouseDownHandler = (e) => {
    setMouseDownPosition({
      mouseDownX: e.clientX,
      mouseDownY: e.clientY,
    });
  };

  const touchStartHandler = (e) => {
    if (currentTask === XRAY || isLabel(e)) {
      const touch = e.touches[0] || e.changedTouches[0];
      touchCoordinates.current = { x: touch.pageX, y: touch.pageY };
      setNumberOfTouches(e.touches.length);
    }
  };

  const touchEndHandler = (e) => {
    const touch = e.touches[0] || e.changedTouches[0];
    if (
      (currentTask === XRAY || isLabel(e)) &&
      Math.abs(touchCoordinates.current.x - touch.pageX) <
        MAX_MARK_SELECTION_DISTANCE &&
      Math.abs(touchCoordinates.current.y - touch.pageY) <
        MAX_MARK_SELECTION_DISTANCE
    ) {
      touchHandler(e);
    }
  };

  const imageMarkingMouseUpHandler = (e) => {
    mouseUpHandler(
      e,
      mouseDownPosition,
      disableMarking,
      onMarkerCreated,
      bordersAndTags
    );
  };

  const handleImageScale = useCallback(() => {
    const transformStyleString =
      markingsWrapper.current.parentElement.style.transform;

    const scaleValue = extractValueFromString(transformStyleString, 'scale');

    setLabelingImageTransformValue(transformStyleString);
    setImageScale(scaleValue);
  }, [setLabelingImageTransformValue]);

  const determineColor = (cluster) => {
    if (TIER_3) return COLOR_GREEN;
    if (cluster.length === 1) {
      return COLOR_YELLOW;
    } else if (cluster[0].consistent) {
      return COLOR_GREEN;
    } else {
      return COLOR_RED;
    }
  };

  useEffect(() => {
    if (markingsWrapper.current) {
      const config = { attributes: true, childList: true, subtree: true };
      const observer = new MutationObserver(handleImageScale);
      observer.observe(markingsWrapper.current.parentElement, config);

      return () => {
        observer.disconnect();
      };
    }
  }, [handleImageScale]);

  useEffect(() => {
    if (shapeInProgress && shapeInProgress !== lastShape)
      dispatch(setLastShape(shapeInProgress));
  }, [shapeInProgress, lastShape, dispatch]);

  //handle press 'E' key
  useEffect(() => {
    dispatch(setLastShape(null));
  }, [currentPhotoIndex, dispatch]);

  const enterEKeyAction = () => {
    if (!shapeInProgress && lastShape) {
      dispatch(setShapeInProgress(lastShape));
    }
  };

  useHotkeys(
    KeyCodes.E_KEY,
    (e) => {
      !disableShortcuts && isKeyCode(e, KeyCodes.E_KEY) && enterEKeyAction();
    },
    [disableShortcuts, shapeInProgress, lastShape]
  );

  useEffect(() => {
    imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
  }, [brightness, contrast]);

  const TIER_2 = isTier2(tier);
  const TIER_3 = isTier3(tier);
  const TIER_1 = isTier1(tier);

  useHotkeys(
    KeyCodes.T_KEY,
    (e) =>
      !disableShortcuts && isKeyCode(e, KeyCodes.T_KEY) && setShowTags(true),
    [disableShortcuts]
  );

  useEffect(() => {
    if (imageRef && imageRef.current) calculateImageSize();
  }, [imageIsRotatedSideways, calculateImageSize]);

  useEffect(() => {
    retrieveImageHeight(imageRef.current.height);

    return () => {
      keyupEvent.remove(KeyCodes.T_KEY, () => showTags && setShowTags(false));
    };
  }, [retrieveImageHeight, imagesType, showTags]);

  keyupEvent.add(KeyCodes.T_KEY, () => showTags && setShowTags(false));

  const getBordersAndTags = (imageSize) => {
    if (!prevMarkers[0]) return;
    const marks = prevMarkers.filter(
      (mark) => !!mark.cluster || mark.cluster === 0
    );
    const clusteredMarks = groupByCluster(marks);

    const data = {};
    data.nodes = [];
    data.links = [];

    Object.keys(clusteredMarks).forEach((key) => {
      const { naturalWidth, naturalHeight, width, height } = imageSize;
      const { minX, maxX, minY, maxY } = getMinMaxXY(clusteredMarks, key);

      const { borderWidth, borderHeight, positionX, positionY } =
        getDimensionsOfBordersAndTags(
          width,
          height,
          naturalWidth,
          naturalHeight,
          BORDER_PADDING,
          PADDING_POSITIONING,
          minX,
          minY,
          maxX,
          maxY
        );

      const color = determineColor(clusteredMarks[key]);

      const string = getClusterText(clusteredMarks[key], tier, detailsMap);
      const cluster = clusteredMarks[key][0]
        ? clusteredMarks[key][0].cluster
        : null;

      data.nodes.push({
        id: data.nodes.length,
        width: borderWidth,
        height: borderHeight,
        positionX: positionX,
        positionY: positionY,
        group: BORDER,
        color: color,
        cluster: cluster,
      });

      if (showTags) {
        data.nodes.push({
          id: data.nodes.length,
          x: positionX,
          y: positionY,
          group: LABEL,
          color: color,
          text: string,
          cluster: cluster,
        });

        data.links.push({
          source: data.nodes.length - 2,
          target: data.nodes.length - 1,
          color: color,
          cluster: cluster,
        });
      }
    });

    return data;
  };

  const bordersAndTags = getBordersAndTags(
    isNiri ? imageSizeNiri : imageSizeColor
  );
  const isCurrentMarkerMissPoint = () => {
    return (
      missPoints &&
      missPoints?.find(
        (point) => point?.x === markings?.[0]?.x && point.y === markings?.[0]?.y
      )
    );
  };

  /** TODO check if neccessery */
  // const missPointsWithoutCurrentMarker =
  //   missPoints &&
  //   missPoints?.filter(
  //     (point) => point?.x !== markings?.[0]?.x || point.y !== markings?.[0]?.y
  //   );

  const getMarkersComponent = (
    markers,
    onMarkClick,
    color,
    isSelected = false,
    blink = false,
    showOpenSelectMenu = false,
    missPoint = false
  ) => {
    return (
      <Markers
        markers={markers}
        tier={tier}
        onMarkClick={onMarkClick}
        isSelected={isSelected}
        color={color}
        blink={blink}
        showOpenSelectMenu={showOpenSelectMenu}
        imageScale={imageScale * internalImageScale}
        imageSize={isNiri ? imageSizeNiri : imageSizeColor}
        hoveredClusterNumber={hoveredClusterNumber}
        labelingImageSize={
          currentTask === XRAY ? xRayImageSize : { height, width }
        }
        touchEvent={currentTask === XRAY}
        zIndex={4}
        missPoint={missPoint}
      />
    );
  };

  const getImageWidth = () =>
    currentTask === XRAY ? xRayImageSize.width : width;
  const getImageHeight = () =>
    currentTask === XRAY ? xRayImageSize.height : height;
  const markingAreaEnabled = isMarkingAreaEnabled(config, campaign);

  const autoCorrectionMode = useSelector(autoCorrectionSelector);
  const currentNiriACsrc = useSelector(currentNiriACsrcSelector);
  const currentColorACsrc = useSelector(currentColorACsrcSelector);
  const currentXRayACsrc = useSelector(currentXRayACsrcSelector);
  const isTraining = campaign && isTrainingMode(config, campaign);

  const setRightClick = (e) => {
    e.preventDefault();
    dispatch(setOpenSelectHistory(true));
  };

  useEffect(() => {
    document.addEventListener('contextmenu', setRightClick);
    return () => document.removeEventListener('contextmenu', setRightClick);
    /** TODO check if neccessery */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onMouseDown={mouseDownHandler}
      onTouchStart={touchStartHandler}
      onTouchEnd={touchEndHandler}
      onMouseUp={imageMarkingMouseUpHandler}
    >
      <PinchZoomContainer
        pinchProps={{
          top: XRAY_OFFSET_TOP_FOR_CENTER_IMAGE,
          position: 'center',
          initialScale: 1,
          maxScale: currentTask === XRAY ? 10 : 1,
          zoomButtons: false,
          frameWidth: getImageWidth(),
          frameHeight: 100,
        }}
        disable={!(currentTask === XRAY)}
      >
        <div ref={markingsWrapper}>
          <div style={{ ...rotationStyles }}>
            {!hideMarks &&
              (TIER_2 || TIER_3) &&
              getMarkersComponent(
                prevMarkers,
                TIER_3 ? onMarkClick : null,
                COLOR_BLUE
              )}

            {!hideMarks && (TIER_2 || TIER_3) && prevMarkers[0] && (
              <BordersAndLabels
                data={bordersAndTags}
                width={getImageWidth()}
                height={getImageHeight()}
                imageScale={imageScale}
                isNg={isNg}
              />
            )}

            {!hideMarks &&
              getMarkersComponent(
                markings,
                TIER_3 ? onMarkClick : null,
                'rgb(253,35,46)',
                isSelected,
                true,
                markings.length === 1 && !markings[0].id,
                isCurrentMarkerMissPoint()
              )}
            {!hideMarks &&
              showMissPoints &&
              TIER_1 &&
              (!commit || isTraining) &&
              getMarkersComponent(
                missPoints,
                onMarkClick,
                COLOR_LIGHT_GREEN,
                false,
                false,
                false,
                true
              )}
            {!hideMarks && getMarkersComponent(markers, onMarkClick)}
            {!hideMarks &&
              showMissPoints &&
              isTraining &&
              getMarkersComponent(markersWrongUser, onMarkClick, COLOR_RED)}

            <img
              alt="labeling-img"
              className={classNames([
                styles.img,
                isNiri ? TYPE_NIRI : TYPE_COLOR,
              ])}
              ref={imageRef}
              onLoad={onLoadImg}
              src={
                autoCorrectionMode && !autoCorrectionDisabled
                  ? isXRay(currentTask)
                    ? currentXRayACsrc || src
                    : isNiri
                    ? currentNiriACsrc || src
                    : currentColorACsrc || src
                  : src
              }
              width={getImageWidth()}
              height={getImageHeight()}
            />
            {markingAreaEnabled && (
              <Shapes
                hideAreas={hideAreas}
                hideMarks={hideMarks}
                onMarkerCreated={onMarkerCreated}
                currentPair={currentPair}
                isNiri={isNiri}
              />
            )}
          </div>
        </div>
      </PinchZoomContainer>
    </div>
  );
};

ImageMarking.defaultProps = {
  width: 200,
  height: 200,
  src: '',
  brightness: 100,
  contrast: 100,
  markers: [],
  disableMarking: false,
};
ImageMarking.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image height
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The number of percentage of brightness apply to the image
   */
  brightness: PropTypes.number,
  /**
   * The number of percentage of contrast apply to the image
   */
  contrast: PropTypes.number,
  /**
   * Previous tier markers
   */
  prevMarkers: PropTypes.array,
  /**
   * Markers array positions
   */
  markers: PropTypes.array,
  /**
   * The blinking mark if any
   */
  markings: PropTypes.array,
  /**
   * Is the blinking because of selection
   */
  isSelected: PropTypes.bool,
  /**
   * Callback, fire when button is clicked
   */
  onMarkerCreated: PropTypes.func.isRequired,
  /**
   * Set marking to disabled
   */
  disableMarking: PropTypes.bool,
  /**
   * Tier
   */
  tier: PropTypes.number,
  /**
   * Callback, fire when mark is clicked
   */
  onMarkClick: PropTypes.func,
  /**
   * Should hide marks
   */
  hideMarks: PropTypes.bool,
  /**
   * Should hide areas
   */
  hideAreas: PropTypes.bool,
  /**
   * whether shortcuts should be disabled
   */
  disableShortcuts: PropTypes.bool,
  /**
   * whether image is rotated sideways
   */
  imageIsRotatedSideways: PropTypes.bool,
  /**
   * calculates image size, fire when image is loading or image rotation has occured
   */
  setXRayImageSize: PropTypes.func,
  /**
   * calculate xray image size
   */
  calculateImageSize: PropTypes.func,
  /**
   * function set transform value of labeling image to parent component for openselect offset calculation
   */
  setLabelingImageTransformValue: PropTypes.func,
  /**
   * was a niri or color type
   */
  isNiri: PropTypes.bool,
  /**
   * boolean if this type is NG
   */
  isNg: PropTypes.bool,
};

export default ImageMarking;
