import { Timber } from '@itero/timber';
import { LogLevel, EventType, AppenderType } from '@itero/timber/enums';
import { getUserName } from '../../pages/TasksList/TasksList.logic';

let timber = null;
let runOnce = false;

export const missingTimberUrlParamError = 'Missing timber url for timberLogger';

const getTimberInstance = () => {
  if (runOnce) {
    return timber;
  }

  const timberUrl = 'https://itero-timber-e3.dv.itero.cloud/empty.png';
  if (!timberUrl) {
    console.error(missingTimberUrlParamError);
    runOnce = true;
    return null;
  }

  runOnce = true;

  timber = new Timber({
    appId: 'labeling-tool-client',
    appenderTypes: [AppenderType.Remote],
    minLogLevel: LogLevel.All,
    requiredFields: [],
    url: timberUrl,
    eventType: EventType.BusinessReport,
  });

  return timber;
};

const buildTimberMessage = (params, funcName) => {
  const { value, response } = params;
  let message;
  const userName = getUserName();
  message = {
    function: funcName,
    labler: userName,
    requestUrl: value,
    response: response,
  };
  return message;
};

export const logToTimber = ({ timberData }) => {
  const timber = getTimberInstance();
  const stack = new Error().stack;
  const callerName = stack.split('\n')[2].trim().split(' ')[1];
  if (timber) {
    const message = buildTimberMessage(timberData, callerName);
    timber.log(LogLevel.All, {}, { extendedParameters: message });
  }
};
