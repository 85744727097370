import { Types } from './Labeling/Types';
import { SubTypes } from './Labeling/SubTypes';
import { Materials } from './Labeling/Materials';

export const TypesSubTypesMaterials = {
  [Types['Not Selected']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Regular']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Primary Tooth']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Geminated']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Missing Tooth']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Crown']]: {
    'Sub Type': [
      SubTypes['Not Selected'],
      SubTypes['Crown on Tooth'],
      SubTypes['Implant: Adhesive'],
      SubTypes['Implant: Screw Retained'],
    ],
    Material: [
      Materials['Not Selected'],
      Materials['PFM (Pocelain Fused Metal)'],
      Materials['PFZ (Pocelain Fused Zirconia)'],
      Materials['All Ceramic'],
      Materials['Metal'],
      Materials['Full Ziroconia'],
      Materials['PMMA Temporary'],
      Materials['Other Temporary'],
    ],
  },
  [Types['Bridge']]: {
    'Sub Type': [
      SubTypes['Not Selected'],
      SubTypes['Tooth Abutment - Crown'],
      SubTypes['Tooth Abutment - Inlay/Onlay'],
      SubTypes['Implant Abutment'],
      SubTypes['Pontic'],
      SubTypes['Maryland Abutment'],
      SubTypes['Screw Retained'],
    ],
    Material: [
      Materials['Not Selected'],
      Materials['PFM (Pocelain Fused Metal)'],
      Materials['PFZ (Pocelain Fused Zirconia)'],
      Materials['All Ceramic'],
      Materials['Metal'],
      Materials['Full Ziroconia'],
      Materials['PMMA Temporary'],
      Materials['Other Temporary'],
    ],
  },
  [Types['Inlay / Onlay / Overlay']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [
      Materials['Not Selected'],
      Materials['Ziconia'],
      Materials['Ceramic'],
      Materials['Composite'],
      Materials['Metal'],
    ],
  },
  [Types['Implant Abutment']]: {
    'Sub Type': [
      SubTypes['Not Selected'],
      SubTypes['Prefacricated Abutment'],
      SubTypes['Custom Abutment'],
    ],
    Material: [Materials['Not Selected']],
  },
  [Types['Scan Body']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Veneer']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Prepped Tooth']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [
      Materials['Not Selected'],
      Materials['Natural'],
      Materials['Composite Core'],
      Materials['Amalgam Core'],
    ],
  },
  [Types['Healing Abutment']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [Materials['Not Selected']],
  },
  [Types['Irregular Position']]: {
    'Sub Type': [
      SubTypes['Not Selected'],
      SubTypes['Ectopic Tooth'],
      SubTypes['Over Erupted'],
      SubTypes['Partially Erupted'],
      SubTypes['Impacted Tooth'],
      SubTypes['Rotated Tooth'],
    ],
    Material: [Materials['Not Selected']],
  },
  [Types['Structure Loss']]: {
    'Sub Type': [
      SubTypes['Not Selected'],
      SubTypes['Profound Coronal Destruction'],
      SubTypes['Roots Residues'],
    ],
    Material: [Materials['Not Selected']],
  },
  [Types['Significant Filling']]: {
    'Sub Type': [SubTypes['Not Selected']],
    Material: [
      Materials['Not Selected'],
      Materials['Amalgam'],
      Materials['Composite'],
      Materials['Glass Ionomer'],
      Materials['Temporary Filling'],
    ],
  },
  [Types['Caries']]: {
    'Sub Type': [SubTypes['Not Selected'], SubTypes['M'], SubTypes['D']],
    Material: [
      Materials['Not Selected'],
      Materials['High'],
      Materials['Medium'],
      Materials['Low'],
    ],
  },
};
