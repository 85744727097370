const CAMPAIGN = 'campaign';
const DATE = 'Date';
const NONE = 'None';
export const ReportType = {
  LABELING_REPORT: 'Campaign Status',
  DETAILS_CASE_REPORT: 'Detailed Case Status',
  WORK_PER_CAMPAIGN: 'Work per Campaign',
  WORK_REPORT: 'Work per Labeler',
  NOTES_REPORT: 'Notes per Case',
  FULL_REPORT: 'Full Report',
};

export const ReportSelectedType = {
  [ReportType.LABELING_REPORT]: CAMPAIGN,
  [ReportType.DETAILS_CASE_REPORT]: CAMPAIGN,
  [ReportType.WORK_PER_CAMPAIGN]: CAMPAIGN,
  [ReportType.WORK_REPORT]: DATE,
  [ReportType.NOTES_REPORT]: DATE,
  [ReportType.FULL_REPORT]: NONE,
};
