import React from 'react';
import AddUserForm from './AddUserForm';
import EditUserForm from './EditUserForm';

const FormContent = (props) => {
  return props.isEdit ? (
    <EditUserForm {...props} />
  ) : (
    <AddUserForm {...props} />
  );
};

export default FormContent;
