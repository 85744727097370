import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setCurrentMarker,
  setCurrentType,
  setDetail,
  setFinding,
  setProbability,
  setCurrentContouring,
  setShapeInProgress,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setLabelingDisabled,
  setDisableShortcuts,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  NOT_SELECTED_FINDING,
  NOT_SELECTED_DETAIL,
} from '../../../shared-logic/params';

const useResetLabelingSelection = () => {
  const dispatch = useDispatch();

  const resetLabelingSelection = useCallback(() => {
    dispatch(setCurrentType(null));
    dispatch(setCurrentMarker({}));
    dispatch(setLabelingDisabled(true));
    dispatch(setFinding(NOT_SELECTED_FINDING));
    dispatch(setDetail(NOT_SELECTED_DETAIL));
    dispatch(setProbability('100%'));
    dispatch(setCurrentContouring(null));
    dispatch(setShapeInProgress(null));
    dispatch(setDisableShortcuts(false));
  }, [dispatch]);

  return resetLabelingSelection;
};

export default useResetLabelingSelection;
