import React from 'react';
import FindingNameInput from './FindingNameInput';
import DetailsExplanationsListComp from './DetailsExplanationsListComp';
import { Autocomplete, TextField } from '@mui/material';

const EditImageLevelFindingForm = ({
  findingsList,
  findingId,
  handleSelectFinding,
  newFindingName,
  setNewFindingName,
  addDetail,
  oldDetailsExplanations,
  setOldDetailsExplanations,
  updateDetail,
  detailsExplanations,
  setDetailsExplanations,
  deleteDetail,
}) => {
  return (
    <>
      <Autocomplete
        options={findingsList}
        getOptionLabel={(option) => option.FindingName}
        value={
          findingId
            ? findingsList.find((item) => item.FindingId === findingId)
            : null
        }
        onChange={(event, newValue) => handleSelectFinding(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Finding Group"
            required
            disabled={findingId !== ''}
          />
        )}
        selectOnFocus
        handleHomeEndKeys
      />

      {findingId !== '' && (
        <FindingNameInput
          label="New Name"
          newFindingName={newFindingName}
          setNewFindingName={setNewFindingName}
          addDetail={addDetail}
        />
      )}

      <DetailsExplanationsListComp
        list={oldDetailsExplanations}
        setList={setOldDetailsExplanations}
        isDeleteable={false}
        updateDetail={updateDetail}
      />

      <DetailsExplanationsListComp
        list={detailsExplanations}
        setList={setDetailsExplanations}
        isDeleteable={true}
        updateDetail={updateDetail}
        deleteDetail={deleteDetail}
      />
    </>
  );
};

export default EditImageLevelFindingForm;
