export const isFDI = process.env.REACT_APP_TOOTH_NUMBERING === 'FDI';

export const UPPER_FDI_FIRST_TOOTH = 18;
export const UPPER_UNIVERSAL_FIRST_TOOTH = 1;
export const LOWER_FDI_FIRST_TOOTH = 38;
export const LOWER_UNIVERSAL_FIRST_TOOTH = 17;

export const fdiTeethLower = [
  48,
  47,
  46,
  45,
  44,
  43,
  42,
  41,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
];
export const universalTeethLower = [
  32,
  31,
  30,
  29,
  28,
  27,
  26,
  25,
  24,
  23,
  22,
  21,
  20,
  19,
  18,
  17,
];

export const fdiTeethUpper = [
  28,
  27,
  26,
  25,
  24,
  23,
  22,
  21,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
];
export const universalTeethUpper = [
  16,
  15,
  14,
  13,
  12,
  11,
  10,
  9,
  8,
  7,
  6,
  5,
  4,
  3,
  2,
  1,
];

export const lower = {};
export const upper = {};

function initLowerUpperTeeth() {
  universalTeethLower.forEach((tooth) => {
    lower[`ada${tooth}`] = { id: tooth, disabled: false };
  });

  lower['ada17'].checked = true;

  universalTeethUpper.forEach((tooth) => {
    upper[`ada${tooth}`] = { id: tooth, disabled: false };
  });
  upper['ada1'].checked = true;
}

initLowerUpperTeeth();
