import { useEffect } from 'react';
import { useHandleNewOutput, useSetTooth } from '../customHooks';
import { useSelector } from 'react-redux';
import {
  toothRightClickSelector,
  markEditedSelector,
} from '../../../redux/labelingTool/labelingToolSlice';
import { currentMarkerSelector } from '../../../redux/marks/currentMarkingSlice';

const useCopyToothIndication = () => {
  const markEdited = useSelector(markEditedSelector);
  const toothRightClick = useSelector(toothRightClickSelector);
  const currentMarker = useSelector(currentMarkerSelector);
  const setTooth = useSetTooth();
  const handleNewOutput = useHandleNewOutput();

  useEffect(() => {
    if (
      markEdited &&
      toothRightClick &&
      Object.keys(currentMarker).length > 0
    ) {
      handleNewOutput();
      setTooth(currentMarker);
    }
  }, [currentMarker, handleNewOutput, markEdited, toothRightClick, setTooth]);
};

export default useCopyToothIndication;
