import { relative } from 'path';

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';

import ImageManipulationSlider from './ImageManipulationSlider';
import ImageRotationController from './ImageRotationController';
import ToothTierTwoConflictMenuContainer from './ToothTierTwoConflictMenu/ToothTierTwoConflictMenuContainer';
import styles from './ImageFrameManipulation.module.css';
import brightnessIcon from './assets/brightness.png';
import contrastIcon from './assets/contrast.png';
import ImageMarking from './ImageMarking';
import ToothMarking from './ToothMarking';
import {
  overrideCtrlKey,
  keyupEvent,
  isKeyCode,
} from '../../../shared-logic/keyFunctions';
import { KeyCodes, TaskTypes } from '../../../shared-logic/enums';
import { useDispatch, useSelector } from 'react-redux';
import { disableShortcutsSelector } from '../../../redux/labelingTool/labelingToolSlice';
import {
  currentTaskSelector,
  imagesTypeSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { rotationSelector } from '../../../redux/taskState/rotationSlice';
import OpenSelect from './OpenSelect/OpenSelect';
import {
  IMAGE_CONTAINER_HEIGHT,
  CONTRAST_MIN_VALUE,
  CONTRAST_MAX_VALUE,
  CONTRAST_DEFAULT_VALUE,
  BRIGHTNESS_DEFAULT_VALUE,
  ROTATION_MENU_HEIGHT,
} from './ImageFrameManipulation.logic';
import {
  calculateSelectPositionForXray,
  calculateSelectPositionForImagePairs,
  getWidth,
  getHeight,
  calcIfImageIsRotated,
  getOverflowImageScaleDownValue,
  getImageSizeObject,
} from './ImageFrameManipulation.logic';
import {
  setImageSizeColor,
  setImageSizeNiri,
} from '../../../redux/marks/currentMarkingSlice';
import { autoCorrectionSelector } from '../../../redux/taskState/contrastBrightnessSlice';
import {
  isTooth,
  isXRay,
  isNG,
} from '../../../shared-logic/taskLevelsTypesHelper';
import { isTier2 } from '../../../shared-logic/tiersHelpers';
import { taskLevelSelector } from '../../../redux/taskState/taskDetailsSlice';
import { openSelectHistorySelector } from '../../../redux/marks/currentMarkingSlice';

const ImageFrameManipulation = ({
  src,
  rotation,
  markers,
  isVertical,
  markingMode,
  onMarkerCreated,
  componentToRender,
  onChangedBrightness,
  onChangedContrast,
  brightness,
  contrast,
  disableMarking,
  onMarkClick,
  markings,
  isSelected,
  prevMarkings,
  isNiri,
  naturalHeight,
  naturalWidth,
  currentPair,
  missPoints,
  markersWrongUser,
}) => {
  const [xRayImageSize, setXRayImageSize] = useState({
    width: null,
    height: null,
  });

  const [imageHeight, setImageHeight] = useState(null);
  const [openSelectPosition, setOpenSelectPosition] = useState({
    left: null,
    top: null,
  });
  const [labelingImageTransformValue, setLabelingImageTransformValue] =
    useState(null);

  const dispatch = useDispatch();
  const [hideMarks, setHideMarks] = useState(false);
  const [hideAreas, setHideAreas] = useState(false);
  const tier = useSelector(tierSelector);
  const imageRotation = useSelector(rotationSelector);
  const imagesType = useSelector(imagesTypeSelector);
  const disableShortcuts = useSelector(disableShortcutsSelector);
  const currentTask = useSelector(currentTaskSelector);
  const autoCorrection = useSelector(autoCorrectionSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const openSelectHistory = useSelector(openSelectHistorySelector);
  const height = getHeight(imagesType, currentTask, xRayImageSize);
  const ratio = height / naturalHeight;

  const width = getWidth(imagesType, currentTask, naturalWidth, ratio);

  const imageIsRotatedSideways = calcIfImageIsRotated(imageRotation.rotateZ);
  const overflowImageScaleDownValue = getOverflowImageScaleDownValue(
    imageIsRotatedSideways,
    xRayImageSize
  );

  const isXray = isXRay(currentTask);

  const showTierTwoToothConflictMenu =
    isTooth(taskLevel) && isTier2(tier) && !isXray;

  const containerClass = classNames({
    [styles.xrayContainer]: isXray,
  });

  const relativeClass = classNames([
    styles.relative,
    {
      [styles.relativeImg]: imagesType !== TaskTypes.NG,
    },
  ]);

  useEffect(() => {
    if (markings.length === 1 && !markings[0].id) {
      const x = markings[0].x;
      const y = markings[0].y;
      if (isXray) {
        calculateSelectPositionForXray(
          labelingImageTransformValue,
          x,
          y,
          setOpenSelectPosition
        );
      } else {
        const coordinates = calculateSelectPositionForImagePairs(imagesType)(
          x,
          y,
          ratio
        );
        setOpenSelectPosition({ top: coordinates.top, left: coordinates.left });
      }
    }

    return () => {
      overrideCtrlKey.remove(KeyCodes.H_KEY);
      overrideCtrlKey.remove(KeyCodes.F_KEY);
      keyupEvent.remove(
        KeyCodes.H_KEY,
        () => !hideMarks && setHideMarks(false)
      );
      keyupEvent.remove(
        KeyCodes.F_KEY,
        () => !hideAreas && setHideAreas(false)
      );
    };
  }, [
    xRayImageSize,
    markings,
    currentTask,
    imagesType,
    labelingImageTransformValue,
    hideMarks,
    hideAreas,
    isXray,
    ratio,
  ]);

  const rotationStyles = {
    transform: `scale(${overflowImageScaleDownValue}) rotateX(${imageRotation.rotateX}deg) rotateY(${imageRotation.rotateY}deg) rotateZ(${imageRotation.rotateZ}deg)`,
  };

  useHotkeys(
    KeyCodes.H_KEY,
    (e) =>
      !disableShortcuts &&
      !e.repeat &&
      isKeyCode(e, KeyCodes.H_KEY) &&
      setHideMarks(true),
    [disableShortcuts]
  );

  useHotkeys(
    KeyCodes.F_KEY,
    (e) =>
      !disableShortcuts &&
      !e.repeat &&
      isKeyCode(e, KeyCodes.F_KEY) &&
      setHideAreas(true),
    [disableShortcuts]
  );

  overrideCtrlKey.add(KeyCodes.H_KEY);

  keyupEvent.add(KeyCodes.H_KEY, () => !hideMarks && setHideMarks(false));
  keyupEvent.add(KeyCodes.F_KEY, () => !hideAreas && setHideAreas(false));
  const onBrightnessChange = (value) => {
    brightness = value;
    onChangedBrightness(value);
  };

  const onContrastChange = (value) => {
    contrast = value;
    onChangedContrast(value);
  };

  const retrieveImageHeight = useCallback(
    (height) => setImageHeight(height),
    []
  );

  const calculateImageSize = (imageRef) => {
    if (!imageRef) return;
    const { imageSize, naturalSize } = getImageSizeObject(
      imageRef,
      currentTask
    );
    isXray && setXRayImageSize(imageSize);
    dispatch(
      isNiri
        ? setImageSizeNiri({ ...naturalSize, ...imageSize })
        : setImageSizeColor({ ...naturalSize, ...imageSize })
    );
  };
  const IMAGE_ROTATION = classNames(styles.fullHeight, {
    [styles.rotate]: isNG(imagesType),
  });
  return (
    <div
      className={styles.container}
      style={{
        flexDirection: isVertical ? 'row' : 'column-reverse',
        justifyContent: 'space-between',
        minWidth: isXray ? 1310 : 'auto',
        height: isNG(imagesType) ? width : 'auto',
        width: isNG(imagesType) ? height : 'auto',
      }}
    >
      <div
        className={styles.sliders}
        style={{
          flexDirection: isVertical ? 'column' : 'row',
          width: isVertical ? relative : isNG(imagesType) ? height : width,
          height: isXray
            ? IMAGE_CONTAINER_HEIGHT + ROTATION_MENU_HEIGHT
            : isNG(imagesType)
            ? width
            : imageHeight,
        }}
      >
        <ImageManipulationSlider
          value={brightness}
          onChange={onBrightnessChange}
          defaultValue={BRIGHTNESS_DEFAULT_VALUE}
          icon={brightnessIcon}
          isVertical={isVertical}
          disabled={autoCorrection}
        />
        <ImageManipulationSlider
          value={contrast}
          onChange={onContrastChange}
          defaultValue={CONTRAST_DEFAULT_VALUE}
          min={CONTRAST_MIN_VALUE}
          max={CONTRAST_MAX_VALUE}
          icon={contrastIcon}
          isVertical={isVertical}
          disabled={autoCorrection}
        />
      </div>

      <div className={containerClass}>
        {isXray && <ImageRotationController />}
        {markingMode ? (
          <div
            style={{
              height: isXray ? 800 : isNG(imagesType) ? height : 700,
              width: width,
            }}
            className={relativeClass}
          >
            <div className={IMAGE_ROTATION}>
              <ImageMarking
                width={width}
                setXRayImageSize={setXRayImageSize}
                height={height}
                prevMarkers={tier > 1 ? prevMarkings : []}
                markers={markers}
                onMarkClick={onMarkClick}
                src={src}
                brightness={brightness}
                contrast={contrast}
                onMarkerCreated={onMarkerCreated}
                disableMarking={disableMarking}
                hideMarks={hideMarks}
                hideAreas={hideAreas}
                tier={tier}
                isSelected={isSelected}
                markings={markings}
                disableShortcuts={disableShortcuts}
                imageIsRotatedSideways={imageIsRotatedSideways}
                rotationStyles={rotationStyles}
                internalImageScale={overflowImageScaleDownValue}
                xRayImageSize={xRayImageSize}
                retrieveImageHeight={retrieveImageHeight}
                calculateImageSize={calculateImageSize}
                setLabelingImageTransformValue={setLabelingImageTransformValue}
                isNiri={isNiri}
                naturalHeight={naturalHeight}
                naturalWidth={naturalWidth}
                currentPair={currentPair}
                missPoints={missPoints}
                markersWrongUser={markersWrongUser}
                isNg={isNG(imagesType)}
              />
            </div>
            {markings.length === 1 && !markings[0].id && (
              <OpenSelect
                openSelectHistory={openSelectHistory}
                left={openSelectPosition.left}
                top={openSelectPosition.top}
              />
            )}
          </div>
        ) : (
          <div
            className={IMAGE_ROTATION}
            style={{
              height: isXray ? 800 : isNG(imagesType) ? height : 700,
              width: width,
              ...rotationStyles,
            }}
          >
            {showTierTwoToothConflictMenu ? (
              <ToothTierTwoConflictMenuContainer />
            ) : null}
            <ToothMarking
              width={width}
              height={height}
              src={src}
              brightness={brightness}
              contrast={contrast}
              rotation={rotation}
              retrieveImageHeight={retrieveImageHeight}
              calculateImageSize={calculateImageSize}
              disableMarking={disableMarking}
              isNiri={isNiri}
              hideMarks={hideMarks}
            />
          </div>
        )}
        <div>
          {/*this div is a placeholder (helps align content in middle with flexbox)*/}
        </div>
        {componentToRender}
      </div>
    </div>
  );
};

ImageFrameManipulation.defaultProps = {
  isVertical: true,
  markingMode: false,
  onMarkerCreated: noop,
  markers: [],
  disableMarking: false,
  isNiri: false,
};

ImageFrameManipulation.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * markers array positions
   */
  markers: PropTypes.array,
  /**
   * The image height
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The rotation to present the images
   */
  rotation: PropTypes.number,
  /**
   * Set the position of the Sliders to below ImageFrame
   */
  isVertical: PropTypes.bool,
  /**
   * set the images to marking mode if true
   */
  markingMode: PropTypes.bool,
  /**
   *when a new marker is created
   */
  onMarkerCreated: PropTypes.func,
  /**
   * when the brighness is changed
   */
  onChangedBrightness: PropTypes.func,
  /**
   * when the contrast is changed
   */
  onChangedContrast: PropTypes.func,
  /**
   * when in watch mode
   */
  /**
   * when a mark is clicked
   */
  onMarkClick: PropTypes.func,
  /**
   * the red markings - new and blinking
   */
  markings: PropTypes.array,
  /**
   * was a mark selected
   */
  isSelected: PropTypes.bool,
  /**
   * task tier
   */
  tier: PropTypes.number,
  /**
   * prev tier markings
   */
  prevMarkings: PropTypes.array,
  /**
   * used for new mark details selection
   */
  setFindings: PropTypes.func,
  /**
   * used for new mark details selection
   */
  setDetails: PropTypes.func,
  /**
   * whether shortcuts should be disabled
   */
  disableShortcuts: PropTypes.bool,
  /**
   * Retrieve the actual marking Image Height
   */
  retrieveImageHeight: PropTypes.func,
  /**
   * was a niri or color type
   */
  isNiri: PropTypes.bool,
};

export default ImageFrameManipulation;
