import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const RichTextEditor = ({
  className,
  value,
  onChange,
  onBlur,
  placeHolder,
}) => {
  return (
    <ReactQuill
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeHolder}
      modules={{
        toolbar: [
          [{ header: '1' }, { header: '2' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          ['clean'],
        ],
      }}
      formats={[
        'header',
        'list',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
      ]}
    />
  );
};

export default RichTextEditor;
