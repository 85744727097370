import { cloneDeep } from 'lodash';
import { findCurrentToothData, isMissingTooth } from '../shared-logic';
import Tooth from '../ToothLabeling/toothEnum';
import { fromFDIToUniversal } from './teeth/toothNumberConverter';
import {
  IS_POINT_DEFINED,
  IS_MARKED,
  IS_POINT_MARKABLE,
} from './teeth/toothAttributes';

const jawNavigationLogic = {
  addOrRemoveMarkingsFromTeeth(teeth, preps, marked, isFDI, prevTierData) {
    const conflictedIds =
      prevTierData &&
      prevTierData
        .filter((tooth) => tooth.consistent === false)
        .map((tooth) => tooth.id);

    let prepsClone = cloneDeep(preps);

    teeth.forEach((id) => {
      if (isFDI) {
        id = fromFDIToUniversal(id);
      }
      const currentToothStringRepresentation =
        prepsClone[`${Tooth.PREFIX}${id}`];
      if (currentToothStringRepresentation) {
        currentToothStringRepresentation.isMarked = marked;
      }
    });

    conflictedIds &&
      conflictedIds.forEach((id) => {
        if (isFDI) {
          id = fromFDIToUniversal(id);
        }
        const currentToothStringRepresentation =
          prepsClone[`${Tooth.PREFIX}${id}`];
        if (
          currentToothStringRepresentation &&
          currentToothStringRepresentation.isMarked
        ) {
          currentToothStringRepresentation.hasConflictAndMarked = true;
        } else if (currentToothStringRepresentation) {
          currentToothStringRepresentation.hasConflictAndMarked = false;
          currentToothStringRepresentation.hasConflict = true;
        }
      });

    return prepsClone;
  },
  countNonOverriddenTeeth(prevState, state) {
    const conflictedTeeth = prevState.filter(
      ({ consistent }) => consistent === false
    );
    if (state.teeth.length === 0) {
      return conflictedTeeth.length;
    }
    const notOverriddenTeeth = conflictedTeeth.filter((t) => {
      const tooth = findCurrentToothData(state, t.id);
      return !tooth ? true : tooth.markings && tooth.markings.length === 0;
    });
    return notOverriddenTeeth.length;
  },
  isSameToothClicked(id, currentToothIndex, isFDI) {
    let toothIndex = currentToothIndex;
    if (isFDI) {
      toothIndex = fromFDIToUniversal(toothIndex);
    }
    return parseInt(id.split(Tooth.PREFIX)[1]) === toothIndex;
  },
  toothName(currentToothIndex) {
    return `${Tooth.PREFIX}${fromFDIToUniversal(currentToothIndex)}`;
  },
  setPropertyOfTooth(preps, currentToothIndex, propertyName, value) {
    const name = this.toothName(currentToothIndex);
    if (preps[name]) preps[name][propertyName] = value;
    return preps;
  },
  addPointsIndicationToPreps(preps, teeth) {
    teeth.forEach((t) => {
      preps = this.updateEditedTooth(t, preps);
    });
    return preps;
  },
  updateEditedTooth(tooth, preps) {
    let prepsClone = cloneDeep(preps);
    const isPointMarkable = !isMissingTooth(tooth);
    const isPointDefined = tooth.point?.position ? true : false;

    prepsClone = this.setPropertyOfTooth(
      prepsClone,
      tooth.id,
      IS_POINT_MARKABLE,
      isPointMarkable
    );

    prepsClone = this.setPropertyOfTooth(
      prepsClone,
      tooth.id,
      IS_POINT_DEFINED,
      isPointDefined
    );

    if (!tooth.markings?.length) {
      prepsClone = this.setPropertyOfTooth(
        prepsClone,
        tooth.id,
        IS_MARKED,
        false
      );
    }
    return prepsClone;
  },
};

export default jawNavigationLogic;
