import React from 'react';
import styles from './LabelersList.module.css';
import PropTypes from 'prop-types';
import { createLabelersList } from './LabelersList.logic';

const LabelersList = ({ prevState, currentLevel, currentToothIndex }) => {
  const labelers = createLabelersList(
    prevState,
    currentLevel,
    currentToothIndex
  );

  return (
    <p className={styles.labelersList}>
      {labelers && labelers.length > 0 && (
        <span>
          {' '}
          {labelers[0].shortName} = {labelers[0].name}{' '}
          {labelers.splice(1).map((labeler, i) => (
            <span key={i}>
              {'// '} {labeler.shortName} = {labeler.name}{' '}
            </span>
          ))}
        </span>
      )}
    </p>
  );
};

LabelersList.propTypes = {
  /**
   * Previous state
   */
  preState: PropTypes.arrayOf(PropTypes.object),
  /**
   * Current task level
   */
  currentLevel: PropTypes.string,
  /**
   * Current tooth index
   */
  currentToothIndex: PropTypes.number,
};

export default LabelersList;
