export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const disableEnter = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export const isNullOrWhitespace = (str) => {
  return typeof str !== 'string' || str.trim().length === 0;
};

export const USER_ROLES = ['tier1', 'tier2', 'tier3', 'supervisor', 'watcher'];
export const ASSISTED_WORKS = [
  'None',
  'Potential misses (AI)',
  'Training mode',
];
export const tier2NavigationModeOptions = ['conflicts', 'all marks'];
export const DEFAULT_LABELERS_NUMBER = 4;
export const MIN_LABELERS_NUMBER = 1;
export const MAX_LABELERS_NUMBER = 9;
