import { useRef, useState } from 'react';

const EditImageLevelFindingStates = ({ tasksListLogic, findingsList }) => {
  const [findingId, setFindingId] = useState('');
  const [oldFindingName, setOldFindingName] = useState('');
  const [newFindingName, setNewFindingName] = useState('');
  const [oldDetailsExplanations, setOldDetailsExplanations] = useState([]);
  const [detailsExplanations, setDetailsExplanations] = useState([]);
  const submitRef = useRef();

  const editImageLevelFindingProps = {
    findingsList,
    tasksListLogic,
    findingId,
    setFindingId,
    oldFindingName,
    setOldFindingName,
    newFindingName,
    setNewFindingName,
    oldDetailsExplanations,
    setOldDetailsExplanations,
    detailsExplanations,
    setDetailsExplanations,
    submitRef,
  };

  const editImageLevelFindingButtons = [
    {
      label: 'Apply Edits',
      onClick: () => submitRef.current.click(),
    },
  ];

  return { editImageLevelFindingProps, editImageLevelFindingButtons };
};

export default EditImageLevelFindingStates;
