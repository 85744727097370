import { createSlice } from '@reduxjs/toolkit';

export const stateImagesXRaySlice = createSlice({
  name: 'stateImagesXRay',
  initialState: {
    xRayState: {},
    xRayImages: [],
    xRayNotes: undefined,
    xRayPrevState: undefined,
  },
  reducers: {
    setXRayState: (state, action) => {
      state.xRayState = action.payload;
    },
    setXRayImages: (state, action) => {
      state.xRayImages = action.payload;
    },
    setXRayNotes: (state, action) => {
      state.xRayNotes = action.payload;
    },
    setXRayPrevState: (state, action) => {
      state.xRayPrevState = action.payload;
    },
  },
});

export const {
  setXRayState,
  setXRayImages,
  setXRayNotes,
  setXRayPrevState,
} = stateImagesXRaySlice.actions;

export const xRayStateSelector = (state) => state.stateImagesXRay.xRayState;
export const xRayImagesSelector = (state) => state.stateImagesXRay.xRayImages;
export const xRayNotesSelector = (state) => state.stateImagesXRay.xRayNotes;
export const xRayPrevStateSelector = (state) =>
  state.stateImagesXRay.xRayPrevState;

export default stateImagesXRaySlice.reducer;
