import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { Button } from '../../components';
import styles from './BugReportByUser.module.css';
import BugWindow from './BugWindow';
import { useGetTaskListLogic } from '../../hooks';
import { getUserName } from '../TasksList/TasksList.logic';
const BugReportByUser = ({
  openWindow = false,
  setOpenWindow,
  taskShortNickName = null,
}) => {
  const [openBugWindow, setOpenBugWindow] = useState(openWindow);

  const taskLogic = useGetTaskListLogic();
  const userName = getUserName();

  const handleClick = () => {
    setOpenBugWindow(true);
  };
  const handleSendEmail = async (bugDescription) => {
    try {
      const screenshot = await html2canvas(document.body);

      const screenshotDataUrl = screenshot.toDataURL('image/png');
      taskLogic.sendBugReportByUser(
        userName,
        bugDescription,
        screenshotDataUrl,
        taskShortNickName
      );
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div>
      {!openWindow && (
        <Button className={styles.btn} onClick={handleClick}>
          Report a Bug
        </Button>
      )}
      {openBugWindow && (
        <BugWindow
          setOpenBugWindow={setOpenBugWindow}
          handleSendEmail={handleSendEmail}
          setOpenWindow={setOpenWindow}
        />
      )}
    </div>
  );
};

export default BugReportByUser;
