import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rowSelectedSelector } from '../../../redux/labelingTool/labelingToolSlice';
import {
  currentContouringSelector,
  currentMarkerSelector,
  currentPhotoIndexSelector,
  currentTypeSelector,
  detailSelector,
  findingSelector,
  imageSizeNiriSelector,
  imageSizeColorSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  brightnessSelector,
  contrastSelector,
  setBrightness,
  setContrast,
} from '../../../redux/taskState/contrastBrightnessSlice';
import {
  outputSelector,
  picturesSelector,
} from '../../../redux/taskStateImages/outputSlice';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import ImageFrameManipulation from '../ImageFrameManipulation';
import {
  recentIndicationSelector,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { TYPE_NIRI, TYPE_XRAY } from '../../../shared-logic/enums';
import {
  getCurrentImageOrPair,
  getMarkers,
  getPrevMarkers,
  onMarkerCreated,
} from '../shared-logic/imageLogic';
import { isEqual } from 'lodash';

const XRay = ({ onMarkerSelected, prevTier, disableMarking }) => {
  const dispatch = useDispatch();

  const currentMarker = useSelector(currentMarkerSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const { detailsId } = useSelector(detailSelector);
  const { findingsId } = useSelector(findingSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const tier = useSelector(tierSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const pictures = useSelector(picturesSelector);
  const output = useSelector(outputSelector, isEqual);
  const contrast = useSelector(contrastSelector);
  const brightness = useSelector(brightnessSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const recentIndication = useSelector(recentIndicationSelector);
  const imageSizeNiri = useSelector(imageSizeNiriSelector);
  const imageSizeColor = useSelector(imageSizeColorSelector);

  const imageSize = currentType === TYPE_NIRI ? imageSizeNiri : imageSizeColor;
  const isToothLevel = isTooth(taskLevel);

  const currentPair = !isToothLevel
    ? getCurrentImageOrPair(output, currentPhotoIndex)
    : null;

  const markers = getMarkers(TYPE_XRAY, currentPair);
  const markings = [currentMarker].filter((m) => Object.keys(m).length !== 0);

  const xrayPrevMarkings =
    tier > 1 ? getPrevMarkers(TYPE_XRAY, currentPair, prevTier) : [];

  return (
    <div style={{ padding: 15 }}>
      <ImageFrameManipulation
        markings={markings}
        isSelected={rowSelected}
        markers={markers}
        contrast={contrast}
        brightness={brightness}
        onChangedContrast={(val) => {
          dispatch(setContrast(val));
        }}
        onChangedBrightness={(val) => {
          dispatch(setBrightness(val));
        }}
        markingMode={!isToothLevel}
        disableMarking={isTooth(taskLevel) || disableMarking}
        prevMarkings={xrayPrevMarkings}
        src={`data:image/png;base64,  ${pictures[currentPhotoIndex]}`}
        onMarkerCreated={(item, filteredDetails) =>
          onMarkerCreated(
            item,
            filteredDetails,
            TYPE_XRAY,
            findingsId,
            detailsId,
            currentType,
            currentContouring,
            rowSelected,
            recentIndication,
            imageSize,
            dispatch
          )
        }
        onMarkClick={(item) => onMarkerSelected(item)}
      />
    </div>
  );
};
export default XRay;
