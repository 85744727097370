import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import styles from './UpperJaw.module.css';

const propTypes = {
  /**
   * If `true`, will render as disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback, fired on clicking on jaw
   */
  onClick: PropTypes.func,
  /**
   * If `true`, will render as selected
   */
  selected: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  onClick: () => {},
  selected: false,
};

function UpperJaw(props) {
  const { className: classNameProp, disabled, onClick, selected } = props;

  const className = classNames(styles.upperJaw, classNameProp, {
    [styles.isDefault]: !(disabled || selected),
    [styles.isDisabled]: disabled,
    [styles.isSelected]: selected,
  });

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      id="upper-jaw"
    />
  );
}

UpperJaw.propTypes = propTypes;
UpperJaw.defaultProps = defaultProps;

export default UpperJaw;
