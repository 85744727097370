import { createSlice } from '@reduxjs/toolkit';

export const labelingToolSlice = createSlice({
  name: 'labelingTool',
  initialState: {
    labelingDisabled: true,
    rowSelected: false,
    selectedTableRowId: -1,
    markEdited: false,
    disableShortcuts: false,
    toothRightClick: false,
  },
  reducers: {
    setLabelingDisabled: (state, action) => {
      state.labelingDisabled = action.payload;
    },
    setRowSelected: (state, action) => {
      state.rowSelected = action.payload;
    },
    setSelectedTableRowId: (state, action) => {
      state.selectedTableRowId = action.payload;
    },
    setMarkEdited: (state, action) => {
      state.markEdited = action.payload;
    },
    setDisableShortcuts: (state, action) => {
      state.disableShortcuts = action.payload;
    },
    setToothRightClick: (state, action) => {
      state.toothRightClick = action.payload;
    },
  },
});

export const {
  setLabelingDisabled,
  setRowSelected,
  setSelectedTableRowId,
  setMarkEdited,
  setDisableShortcuts,
  setToothRightClick,
} = labelingToolSlice.actions;

export const labelingDisabledSelector = (state) =>
  state.labelingTool.labelingDisabled;
export const rowSelectedSelector = (state) => state.labelingTool.rowSelected;
export const selectedTableRowIdSelector = (state) =>
  state.labelingTool.selectedTableRowId;
export const markEditedSelector = (state) => state.labelingTool.markEdited;
export const disableShortcutsSelector = (state) =>
  state.labelingTool.disableShortcuts;
export const toothRightClickSelector = (state) =>
  state.labelingTool.toothRightClick;

export default labelingToolSlice.reducer;
