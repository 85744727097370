export const COMMITTED_IMAGES = 'attr.DataMarkingTask.Summary.committed_images';
export const TOTAL_CASE_IMAGES =
  'attr.DataMarkingTask.Summary.total_case_images';
export const TIER = 'attr.DataMarkingTask.Tier';
export const TASK_SHORT_NICKNAME = 'attr.DataMarkingTask.TaskShortNickName';
export const ASSIGNEE = 'attr.DataMarkingTask.Assignee';
export const BATCH = 'attr.DataMarkingTask.Batch';
export const TYPE = 'attr.DataMarkingTask.Type';
export const LEVEL = 'attr.DataMarkingTask.Level';
export const OBJECT_KEY = 'ObjectKey';
export const REASON = 'attr.DataMarkingTask.Reason';
export const COMPLETED = 'attr.DataMarkingTask.Completed';
export const IN_TIER1 = 'attr.DataMarkingTask.InTier1';
export const POTENTIALLY_DISCARDED = 'PotentiallyDiscarded';
export const SHOW_MISSPOINTS_INPROGRESS =
  'attr.DataMarkingTask.showMissPointInProgress';
