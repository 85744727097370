import { createSlice } from '@reduxjs/toolkit';

export const stateImagesLowerSlice = createSlice({
  name: 'stateImagesLower',
  initialState: {
    lowerState: {},
    lowerImages: [],
    lowerNotes: undefined,
    lowerPrevTierState: undefined,
    lowerConflictedTeeth: undefined,
  },
  reducers: {
    setLowerState: (state, action) => {
      state.lowerState = action.payload;
    },
    setLowerImages: (state, action) => {
      state.lowerImages = action.payload;
    },
    setLowerNotes: (state, action) => {
      state.lowerNotes = action.payload;
    },
    setLowerPrevTierState: (state, action) => {
      state.lowerPrevTierState = action.payload;
    },
    setLowerConflictedTeeth: (state, action) => {
      state.lowerConflictedTeeth = action.payload;
    },
  },
});

export const {
  setLowerState,
  setLowerImages,
  setLowerNotes,
  setLowerPrevTierState,
  setLowerConflictedTeeth,
} = stateImagesLowerSlice.actions;

export const lowerStateSelector = (state) => state.stateImagesLower.lowerState;
export const lowerImagesSelector = (state) =>
  state.stateImagesLower.lowerImages;
export const lowerNotesSelector = (state) => state.stateImagesLower.lowerNotes;
export const lowerPrevTierStateSelector = (state) =>
  state.stateImagesLower.lowerPrevTierState;
export const lowerConflictedTeethSelector = (state) =>
  state.stateImagesLower.lowerConflictedTeeth;
export default stateImagesLowerSlice.reducer;
