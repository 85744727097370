import { confirmAlert } from 'react-confirm-alert';
import { MODIFICATION_OVERRIDE_MESSAGE } from './markOverrideMessage';

const toothMarkOverrideDialog = (overrideMark) => {
  return confirmAlert({
    title: 'Confirm override',
    message: MODIFICATION_OVERRIDE_MESSAGE,
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          overrideMark();
        },
      },
      {
        label: 'No',
      },
    ],
  });
};

export default toothMarkOverrideDialog;
