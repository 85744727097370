import { TYPE_XRAY } from '../../../../shared-logic/enums';
import userActionLogs from '../../../../shared-logic/userActionLogs';
import { generateMarkId, getCurrentImagePair } from '../sharedLogic';
import { checkIsMarkNew } from '../../shared-logic';
import { isTier2 } from '../../../../shared-logic/tiersHelpers';

const initialRotationState = {
  rotateX: 0,
  rotateY: 0,
  rotateZ: 0,
};

const getDefaultXRayData = (
  currentPhotoIndex,
  tier,
  brightness,
  contrast,
  rotation = initialRotationState
) => {
  const approved = {
    approvedPrevTier: false,
  };
  return {
    id: currentPhotoIndex,
    commited: false,
    ...(isTier2(tier) && approved),
    brightness: brightness,
    contrast: contrast,
    rotation: rotation,
    markings: [],
  };
};

export const onCommitOrApprovalXRayChange = (
  prev,
  currentPhotoIndex,
  tier,
  imagesState
) => {
  const { contrast, brightness, commit, approvedPrevTier } = imagesState;
  const currentPair = getCurrentImagePair(prev, currentPhotoIndex);
  if (!currentPair) {
    const newPair = getDefaultXRayData(
      currentPhotoIndex,
      tier,
      contrast,
      brightness
    );
    return { ...prev, imagePairs: [...prev.imagePairs, newPair] };
  } else {
    currentPair.commited = commit;
    if (isTier2(tier)) {
      currentPair.approvedPrevTier = approvedPrevTier;
    }
    return { ...prev };
  }
};

export const onContrastOrBrightnessChangeXRay = (
  prev,
  currentPhotoIndex,
  tier,
  imagesState
) => {
  const { brightness, contrast } = imagesState;
  const currentPair = getCurrentImagePair(prev, currentPhotoIndex);
  if (!currentPair) {
    const newPair = getDefaultXRayData(
      currentPhotoIndex,
      tier,
      brightness,
      contrast
    );
    return { ...prev, imagePairs: [...prev.imagePairs, newPair] };
  } else {
    currentPair.brightness = brightness;
    currentPair.contrast = contrast;
    return { ...prev };
  }
};

export const onRotationChange = (
  prev,
  currentPhotoIndex,
  tier,
  imagesState
) => {
  const { contrast, brightness, rotation } = imagesState;
  const currentPair = getCurrentImagePair(prev, currentPhotoIndex);
  if (!currentPair) {
    const newPair = getDefaultXRayData(
      currentPhotoIndex,
      tier,
      contrast,
      brightness,
      rotation
    );
    if (!!prev.imagePairs) {
      return { ...prev, imagePairs: [...prev.imagePairs, newPair] };
    } else if (prev.teeth) {
      return { ...prev, imagePairs: [...prev.teeth, newPair] };
    }
  } else {
    currentPair.rotation = rotation;
    return { ...prev };
  }
};

const findCurrentMarkIndexXRay = (pair, currentContouring) => {
  return pair.markings.findIndex(
    (m) =>
      m.contouring[0].data.position.x ===
        currentContouring[0].data.position.x &&
      m.contouring[0].data.position.y === currentContouring[0].data.position.y
  );
};

export const onNewXRayOutput = (
  prev,
  currentPhotoIndex,
  tier,
  imagesState,
  markState,
  rowSelected,
  xRayPrevState,
  setCurrentMarker
) => {
  userActionLogs.addActionLog(
    `new x-ray mark current index ${currentPhotoIndex}`
  );
  const { contrast, brightness, approvedPrevTier } = imagesState;
  const { currentContouring, findings, details, probability, markEdited } =
    markState;
  const newPair = getCurrentImagePair(prev, currentPhotoIndex);
  const currentMarkingIndex = findCurrentMarkIndexXRay(
    newPair,
    currentContouring
  );

  const isMarkNew = checkIsMarkNew(
    currentContouring,
    currentPhotoIndex,
    TYPE_XRAY,
    [
      {
        id: prev.imagePairs[currentPhotoIndex].id,
        markings: prev.imagePairs[currentPhotoIndex].markings ?? [],
      },
    ]
  );

  if (rowSelected && markEdited && currentMarkingIndex !== -1) {
    const currentMarking = newPair.markings[currentMarkingIndex];
    const updatedMarking = {
      id: currentMarking.id,
      findings,
      details,
      probability,
      contouring: currentContouring,
    };
    newPair.markings.splice(currentMarkingIndex, 1, updatedMarking);
  } else {
    if (!isMarkNew) {
      //check to prevent addition of two points instead of one
      return prev;
    }
    const currentPair = prev.imagePairs[currentPhotoIndex];

    const markersData =
      (currentPair &&
        currentPair.markings &&
        currentPair.markings.map((item) => ({
          id: item.id,
        }))) ||
      [];
    const prevMarks =
      (xRayPrevState &&
        xRayPrevState.find(
          (markGroup) =>
            markGroup.id === currentPair.id && markGroup[TYPE_XRAY].length > 0
        )) ||
      [];

    const id = generateMarkId(markersData, prevMarks);
    const newMark = {
      id,
      findings,
      details,
      probability,
      contouring: currentContouring,
    };
    newPair.markings = [...newPair.markings, newMark];
    setCurrentMarker(newMark);
  }

  newPair.contrast = contrast;
  newPair.brightness = brightness;
  isTier2(tier) && (newPair.approvedPrevTier = approvedPrevTier);

  return { ...prev };
};
