export const NOT_SELECTED_STR = 'Not Selected';
export const NOT_SELECTED_FINDING_ID = -1;
export const NOT_SELECTED_FINDING = {
  findingsId: NOT_SELECTED_FINDING_ID,
  findings: NOT_SELECTED_STR,
};
export const NOT_SELECTED_DETAIL_ID = '-1';
export const NOT_SELECTED_DETAIL = {
  detailsId: NOT_SELECTED_DETAIL_ID,
  details: NOT_SELECTED_STR,
};
export const MISSING_TOOTH = 'Missing Tooth';
