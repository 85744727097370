import React from 'react';
import { useSelector } from 'react-redux';
import {
  currentTaskSelector,
  taskLevelSelector,
} from '../../../redux/taskState/taskDetailsSlice';

import { xRayPrevStateSelector } from '../../../redux/taskStateImages/stateImagesXRaySlice';

import {
  outputSelector,
  picturesSelector,
  prevTierStateSelector,
} from '../../../redux/taskStateImages/outputSlice';
import { useOnMarkerSelected, useShouldDisableMarking } from '../customHooks';

import {
  isImage,
  isTooth,
  isXRay,
} from '../../../shared-logic/taskLevelsTypesHelper';
import NiriColor from '../NiriColor';
import XRay from '../XRay/XRay';
import { isEqual } from 'lodash';

const Images = () => {
  const currentTask = useSelector(currentTaskSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const output = useSelector(outputSelector, isEqual);
  const prevTierState = useSelector(prevTierStateSelector);
  const prevTierXrayState = useSelector(xRayPrevStateSelector);
  const pictures = useSelector(picturesSelector);
  const disableMarking = useShouldDisableMarking();
  const onMarkerSelected = useOnMarkerSelected();

  if (
    !isXRay(currentTask) &&
    ((isImage(taskLevel) && output.imagePairs[0].niri) ||
      (isTooth(taskLevel) && output.teeth))
  ) {
    return (
      <NiriColor
        disableMarking={disableMarking}
        onMarkerSelected={onMarkerSelected}
        pictures={pictures}
        prevTier={prevTierState}
      />
    );
  } else if (isXRay(currentTask)) {
    return (
      <XRay
        disableMarking={disableMarking}
        onMarkerSelected={onMarkerSelected}
        prevTier={prevTierXrayState}
      />
    );
  }
};

export default Images;
