import React from 'react';
import Button from '../../../components/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CompleteTask.module.css';

const CompleteTask = ({ isCaseCommitted, isToothLevel, disabled, onClick }) => {
  const className = classNames(styles.completeTaskButton, {
    [styles.completeTaskButtonTooth]: isToothLevel,
  });

  return isCaseCommitted ? (
    <Button disabled={disabled} className={className} onClick={onClick}>
      Complete Case
    </Button>
  ) : null;
};

CompleteTask.propTypes = {
  /**
   * If 'true' show component
   */
  isCaseCommitted: PropTypes.bool,
  /**
   * Callback, fired when button is clicked
   */
  completeTask: PropTypes.func,
  /**
   * If 'true' apply tooth mode className to button
   */
  isToothLevel: PropTypes.bool,
  /**
   * If 'true' block button functionality
   */
  /**
   * If `true`, button is disabled
   */
  disabled: PropTypes.bool,
};

export default CompleteTask;
