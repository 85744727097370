import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import styles from './PrepToggle.module.css';
const propTypes = {
  /**
   * Id is a number between 1 to 32
   */
  id: PropTypes.number,
  /**
   * If `true`, will render as disabled
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, will render as marked
   */
  isMarked: PropTypes.bool,
  /**
   * Callback, fired on clicking on jaw
   */
  onClick: PropTypes.func,
  /**
   * If `true`, will render as checked
   */
  checked: PropTypes.bool,
  /**
   * Function to handle right click on jaw
   */
  handleRightClick: PropTypes.func,
};

function PrepToggle(props) {
  const {
    className: classNameProp,
    checked,
    disabled,
    isMarked,
    hasConflict,
    hasConflictAndMarked,
    onClick,
    id,
    handleRightClick,
  } = props;
  const idClass = `ada${id}`;
  const className = classNames(
    styles.prepToggle,
    styles[idClass],
    classNameProp,
    {
      [styles.isChecked]: checked,
      [styles.isDisabled]: disabled,
      [styles.hasConflict]: hasConflict,
      [styles.isMarked]: isMarked,
      [styles.hasConflictAndMarked]: hasConflictAndMarked,
    }
  );

  const onRightClick = (e) => {
    handleRightClick(e, onClick);
  };

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      onRightClick={onRightClick}
      id="prep-toggle"
    />
  );
}

PrepToggle.propTypes = propTypes;

export default PrepToggle;
