import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { taskLevelSelector } from '../../../redux/taskState/taskDetailsSlice';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import {
  setCurrentMarker,
  setSubType,
  setType,
  setMaterial,
  setComments,
  setToothMarkIndex,
} from '../../../redux/marks/currentMarkingSlice';
import { setSelectedTableRowId } from '../../../redux/labelingTool/labelingToolSlice';
import { NOT_SELECTED_STR } from '../../../shared-logic/params';

const useResetToothControls = () => {
  const dispatch = useDispatch();
  const taskLevel = useSelector(taskLevelSelector);
  const isToothLevel = isTooth(taskLevel);

  const resetToothControls = useCallback(() => {
    if (!isToothLevel) return;
    dispatch(setType(NOT_SELECTED_STR));
    dispatch(setSubType(NOT_SELECTED_STR));
    dispatch(setMaterial(NOT_SELECTED_STR));
    dispatch(setComments(''));
    dispatch(setCurrentMarker({}));
    dispatch(setToothMarkIndex(null));
    dispatch(setSelectedTableRowId(-1));
  }, [isToothLevel, dispatch]);

  return resetToothControls;
};

export default useResetToothControls;
