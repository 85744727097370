import { NotificationManager } from 'react-notifications';

const downloadArn = async (tasksListLogic, arn, fileName) => {
  const output = await tasksListLogic.checkExecutionAndRetrieveOutput(arn);
  if (!output || !output.url) {
    NotificationManager.error('Failed to fetching report.', 'Warning');
    return;
  }
  const data = await fetch(output.url);
  const blob = await data.blob();
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
};

export default downloadArn;
