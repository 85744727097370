import React from 'react';
import AddImageLevelFindingForm from './AddImageLevelFindingForm';
import EditImageLevelFindingForm from './EditImageLevelFindingForm';

const FormContent = (props) => {
  return props.isEdit ? (
    <EditImageLevelFindingForm {...props} />
  ) : (
    <AddImageLevelFindingForm {...props} />
  );
};

export default FormContent;
