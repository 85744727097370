import { getAuthContext, getToken, getAdalConfig } from '../config/adalConfig';
import { Errors } from './enums';
import loginDialog from '../pages/LabelingToolPage/Dialogs/loginDialog';
import { logToTimber } from '../shared-logic/timberLogger';

const handleOkClick = () => {
  getAuthContext().acquireTokenRedirect(getAdalConfig().endpoints.api);
};

export const get = async (url, signal = null) => {
  try {
    const res = await fetch(url, {
      signal,
      headers: {
        Authorization: await getToken(),
      },
    });
    const data = await res.json();

    if (res.status === 200) {
      return data;
    }
    if (res.status === 401) {
      throw data.message;
    }
    throw data.error;
  } catch (err) {
    if (err === Errors.UNAUTHORIZED) {
      loginDialog(handleOkClick);
    }
    throw err;
  }
};

export const post = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/json',
        Authorization: await getToken(),
      },
      body: data,
    });

    const res = await response.json();
    logToTimber({
      timberData: {
        value: url,
        response: res,
      },
    });
    if (response.status === 200) {
      return res;
    }
    if (response.status === 401) {
      throw res.message;
    }
    throw res.error;
  } catch (err) {
    logToTimber({
      timberData: {
        value: url,
        response: err.message,
      },
    });
    if (err === Errors.UNAUTHORIZED) {
      sessionStorage.setItem(
        'saveData',
        JSON.stringify({ data: data, url: url })
      );
      loginDialog(handleOkClick);
    }
    throw err;
  }
};

export const deleteObject = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Authorization: await getToken(),
      },
      body: JSON.stringify(data),
    });

    const res = await response.json();
    logToTimber({
      timberData: {
        value: url,
        response: res,
      },
    });
    if (response.status === 200) {
      return res;
    }
    if (response.status === 401) {
      throw res.message;
    }
    throw res.error;
  } catch (err) {
    logToTimber({
      timberData: {
        value: url,
        response: err.message,
      },
    });
    if (err === Errors.UNAUTHORIZED) {
      loginDialog(handleOkClick);
    }
    throw err;
  }
};

export const ipData = async () => {
  const response = await (
    await fetch('https://api.ipify.org/?format=json')
  ).json();
  return response.ip;
};
