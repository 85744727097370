import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from '../ExportType.module.css';
import SelectedFields from './SelectedFields';
import { ReportSelectedType, ReportType } from '../reportsEnum';
import { useGetTaskListLogic } from '../../../../hooks';
import { NotificationManager } from 'react-notifications';
import {
  getCampaignList,
  getAllCampaignListActive,
  getAllCampaignListHidden,
} from '../../../../config/configUtil';
import { useSelector } from 'react-redux';
import { selectConfig } from '../../../../redux/tasks/tasksSlice';
import { getUserName } from '../../TasksList.logic';
import {
  downloadArn,
  getCampaignTreeData,
  getFlattenTreeNodeValues,
} from '../SharedLogic';

const DATE = 'Date';
const NONE = 'None';
const params = 'params';
const batch = 'batches';
const Active = 'Active';
const Hidden = 'Hidden';
export const MonitoringReport = ({
  campaignsToRolesMap,
  campaignsToRolesMapIncludeHidden,
}) => {
  const tasksListLogic = useGetTaskListLogic();
  const [optionsForExport, setOptionsForExport] = useState('');
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState(false);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const [dateFrom, setDateFrom] = useState(sevenDaysAgo);
  const [dateTo, setDateTo] = useState(new Date());
  const userName = getUserName();
  const config = useSelector(selectConfig);
  const campaignListActive = getAllCampaignListActive(config, userName);
  const campaignListHidden = getAllCampaignListHidden(config, userName);

  const campaignForCurrentUser =
    optionsForExport === ReportType.WORK_PER_CAMPAIGN ||
    optionsForExport === ReportType.LABELING_REPORT
      ? campaignsToRolesMapIncludeHidden
      : campaignsToRolesMap;

  const data =
    optionsForExport === ReportType.WORK_PER_CAMPAIGN ||
    optionsForExport === ReportType.LABELING_REPORT
      ? [
          getCampaignTreeData(
            config,
            Active,
            campaignListActive,
            campaignForCurrentUser
          ),
          getCampaignTreeData(
            config,
            Hidden,
            campaignListHidden,
            campaignForCurrentUser
          ),
        ]
      : [
          getCampaignTreeData(
            config,
            Active,
            campaignListActive,
            campaignForCurrentUser
          ),
        ];

  const onReportChange = (value) => {
    setShowCampaigns(true);
    setShowExport(false);
    setOptionsForExport(value);
  };

  const onReportClick = () => {
    const valuesToExport =
      ReportSelectedType[optionsForExport] === DATE ||
      ReportSelectedType[optionsForExport] === NONE
        ? null
        : getFlattenTreeNodeValues(selectedNodes, data);
    reports(optionsForExport, valuesToExport, dateFrom, dateTo);
  };

  const reports = async (
    optionsForExport,
    selectedNodesArray,
    dateFrom,
    dateTo
  ) => {
    try {
      const filteredMap = [...campaignsToRolesMap.keys()].filter(
        (key) =>
          campaignsToRolesMap.get(key).includes('supervisor') ||
          campaignsToRolesMap.get(key).includes('watcher')
      );

      const campaignsList = selectedNodesArray ?? filteredMap;

      const batchToCampaign = campaignsList.map((campaign) => {
        const campaigns = getCampaignList(config, campaign);
        const batches = campaigns?.[params]?.[batch];
        return { campaign: campaign, batches: batches };
      });

      const batchToCampaignJson = JSON.stringify(batchToCampaign);

      NotificationManager.success(
        'Request received. Data will be downloaded soon',
        'Success!'
      );

      tasksListLogic
        .getMonitoringReport(
          optionsForExport,
          batchToCampaignJson,
          dateFrom,
          dateTo,
          userName
        )
        .then((res) => {
          if (!res.executionArn) {
            throw new Error('Failed to retrieve execution ARN.');
          }
          downloadArn(
            tasksListLogic,
            res.executionArn,
            `output-${optionsForExport}.xlsx`
          );
        })
        .catch((err) => {
          console.error('Error:', err);
          NotificationManager.error(
            err.message || 'An error occurred.',
            'Download Error'
          );
        });
    } catch (err) {
      NotificationManager.error(err, 'Warning');
    }
  };

  const onTreeChange = (currentNode, selectedNodes) => {
    setShowExport(true);
    setSelectedNodes(selectedNodes);
  };

  return (
    <div className={styles.formcontainer}>
      <SelectedFields
        onTreeChange={onTreeChange}
        onReportChange={onReportChange}
        optionsForExport={optionsForExport}
        showCampaigns={showCampaigns}
        data={data}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        dateFrom={dateFrom}
        dateTo={dateTo}
        dataList={ReportSelectedType}
      />
      <ButtonReport
        onReportClick={onReportClick}
        showExport={
          showExport ||
          ReportSelectedType[optionsForExport] === DATE ||
          ReportSelectedType[optionsForExport] === NONE
        }
      />
    </div>
  );
};

const ButtonReport = ({ onReportClick, showExport }) => {
  return (
    <button
      // className={styles.button}
      onClick={onReportClick}
      disabled={!showExport}
    >
      Report
    </button>
  );
};

MonitoringReport.propTypes = {
  exportLabelsForAi: PropTypes.func.isRequired,
  campaignsToRolesMap: PropTypes.object,
};

export default MonitoringReport;
