import { useCallback } from 'react';

const useOnLoadImage = ({ imageRef, calculateImageSize }) => {
  const onLoadImg = useCallback(() => {
    calculateImageSize && calculateImageSize(imageRef);
  }, [calculateImageSize, imageRef]);

  return onLoadImg;
};

export default useOnLoadImage;
