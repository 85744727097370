import { prevTierStateSelector } from '../../../redux/taskStateImages/outputSlice';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import {
  currentTypeSelector,
  currentPhotoIndexSelector,
  currentContouringSelector,
} from '../../../redux/marks/currentMarkingSlice';
import { setLowerPrevTierState } from '../../../redux/taskStateImages/stateImagesLowerSlice';
import { setPrevTierState } from '../../../redux/taskStateImages/outputSlice';
import { setUpperPrevTierState } from '../../../redux/taskStateImages/stateImagesUpperSlice';
import { currentTaskSelector } from '../../../redux/taskState/taskDetailsSlice';
import { setMarkEdited } from '../../../redux/labelingTool/labelingToolSlice';
import { findImageIndex } from '../shared-logic';
import { useCallback } from 'react';
import { isUpper } from '../../../shared-logic/taskLevelsTypesHelper';

const useDeletePrevTierMark = () => {
  const dispatch = useDispatch();
  const prevTierState = useSelector(prevTierStateSelector, isEqual);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentContouring = useSelector(currentContouringSelector, isEqual);
  const currentType = useSelector(currentTypeSelector);
  const currentTask = useSelector(currentTaskSelector);

  const setPrevTierMarkers = useCallback(
    (prevMarks) => {
      if (isUpper(currentTask)) {
        dispatch(setUpperPrevTierState(prevMarks));
      } else {
        dispatch(setLowerPrevTierState(prevMarks));
      }
      dispatch(setPrevTierState(prevMarks));
    },
    [currentTask, dispatch]
  );

  const deletePrevMark = useCallback(() => {
    const prevTierMarks = cloneDeep(prevTierState);
    const index = findImageIndex(prevTierMarks, currentPhotoIndex);
    dispatch(setMarkEdited(false));
    if (index !== -1) {
      prevTierMarks[index][currentType] = prevTierState[index][
        currentType
      ].filter((point) => {
        let prev = point.contouring[0].data.position;
        let current = currentContouring[0].data.position;
        return prev.x !== current.x && prev.y !== current.y;
      });
      if (!isEqual(prevTierMarks, prevTierState)) dispatch(setMarkEdited(true));
    }
    setPrevTierMarkers(prevTierMarks);
  }, [
    currentContouring,
    currentPhotoIndex,
    currentType,
    dispatch,
    prevTierState,
    setPrevTierMarkers,
  ]);

  return deletePrevMark;
};
export default useDeletePrevTierMark;
