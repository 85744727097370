import { setRectangle } from '../../../../../shared-logic';

const isStartPointPositionMoved = (nodeData, height) =>
  nodeData &&
  ((nodeData.scaleY < 0 && height < 0 && nodeData.skewX < 0) ||
    (nodeData.rotation && nodeData.scaleY >= 0) ||
    (height > 0 && nodeData.scaleY < 0 && nodeData.rotation < 0));

export const getRectDimensions = (
  { width, height, x, y },
  imageSize,
  nodeData
) => {
  if (isStartPointPositionMoved(nodeData, height)) {
    x = x - Math.abs(width);
    y = y - height;
  }
  x--;
  y++;
  if (width < 0) {
    width = Math.abs(width);
    x = x - width;
  }
  if (height < 0) {
    height = Math.abs(height);
    y = y - height;
  }
  return setRectangle(
    x,
    y,
    width,
    height,
    imageSize.naturalWidth,
    imageSize.naturalHeight,
    imageSize.width,
    imageSize.height,
    true
  );
};
