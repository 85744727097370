import { useMemo } from 'react';
import useFindingsDetails from './FindingsDetails';

const useDetailsMap = () => {
  const { findingsDetails } = useFindingsDetails();
  const detailsMap = useMemo(() => {
    const details = {};
    findingsDetails.forEach((fd) =>
      fd.Details.forEach((d) => (details[d.id] = d.name))
    );
    return details;
  }, [findingsDetails]);

  return { detailsMap };
};

export default useDetailsMap;
