import React, { useRef } from 'react';
import { selectCampaign } from '../../../redux/tasks/tasksSlice';
import tasksLogic from '../TasksList.logic';
import { NotificationManager } from 'react-notifications';
import styles from './ExportAndImportToTier2.module.css';
import importTasksDialog from '../../LabelingToolPage/Dialogs/importTasksDialog';
import * as JSZip from 'jszip';
import { selectConfig } from '../../../redux/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import { getBatchesToCampaign } from '../../../config/configUtil';
export const ExportAndImportToTier2 = () => {
  const config = useSelector(selectConfig);
  const exportFileInputRef = useRef(null);
  const importFileInputRef = useRef(null);

  const exportHandleFileSelect = () => {
    exportFileInputRef.current.click();
  };

  const importhandleFileSelect = () => {
    importFileInputRef.current.click();
  };

  const uploadFileToS3 = async (presignedUrl, file) => {
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!response.ok) {
        NotificationManager.error('Error uploading file');
      }
    } catch (error) {
      NotificationManager.error('Error uploading file');
    }
  };

  const handleImportFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        const presignedDataUrl = await tasksLogic.getPresignedUrlPutImportData(
          selectedFile.name
        );
        await uploadFileToS3(presignedDataUrl.url, selectedFile);

        const res = await tasksLogic.importTasks(selectedFile, selectCampaign);
        const executionArn = res.executionArn;

        const output = await tasksLogic.checkExecutionAndRetrieveOutput(
          executionArn
        );
        const { batch_dict, items_fail } = output || [];
        const campaignList = getBatchesToCampaign(config);

        const campaignCases = {};
        const nonAppearingBatches = {};

        const batchList = [
          ...campaignList.map((campaign) => campaign.batches).flat(),
        ];

        Object.keys(batch_dict).forEach((batch) => {
          if (!batchList.includes(batch)) {
            nonAppearingBatches[batch] = batch_dict[batch];
          }
        });
        Object.keys(campaignList).forEach((campaignId) => {
          const batches = campaignList[campaignId].batches;

          const campaignName = campaignList[campaignId].campaignId;

          campaignCases[campaignName] = batches.reduce(
            (sum, batch) => sum + batch_dict[batch],
            0
          );
        });

        const countToCampaignList = Object.keys(campaignCases).map(
          (campaignId) => ({
            campaignId: campaignId,
            count: campaignCases[campaignId],
          })
        );
        const batchesWithoutCampaign = Object.keys(nonAppearingBatches).map(
          (batch) => ({
            batch: batch,
            count: nonAppearingBatches[batch],
          })
        );
        importTasksDialog(
          countToCampaignList,
          batchesWithoutCampaign,
          items_fail
        );
      } catch (error) {
        NotificationManager.error('Error processing ZIP file');
      }
    }
  };

  const handleExportFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const zip = new JSZip();
      const extractedFiles = {};
      try {
        const zipData = await zip.loadAsync(selectedFile);
        for (const relativePath of Object.keys(zipData.files)) {
          const file = await zipData.files[relativePath];
          if (relativePath.endsWith('.json')) {
            const fileName = relativePath.match(/([^_]+)\.json$/)?.[1];
            const content = await file.async('text');
            extractedFiles[fileName] = await JSON.parse(content);
          }
        }
        if (!extractedFiles.casemap || !extractedFiles.imindices) {
          NotificationManager.error(
            "Can't find the 2 files needed : casemap and imindices "
          );
          return;
        }
        await tasksLogic.exportBatch(extractedFiles);
        NotificationManager.success('complete export data');
      } catch (error) {
        NotificationManager.error('Error processing ZIP file in export data');
      }
    }
  };

  return (
    <div className={styles.formcontainer}>
      <button onClick={exportHandleFileSelect}>Upload export Meta Data</button>
      <input
        type="file"
        accept=".zip"
        ref={exportFileInputRef}
        style={{ display: 'none' }}
        onChange={handleExportFileChange}
      />
      <button onClick={importhandleFileSelect}>Import Labels into tier1</button>
      <input
        type="file"
        accept=".zip"
        ref={importFileInputRef}
        style={{ display: 'none' }}
        onChange={handleImportFileChange}
      />
    </div>
  );
};
export default ExportAndImportToTier2;
