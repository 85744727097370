const getFlattenTreeNodeValues = (selectedNodes, data) => {
  return selectedNodes
    .map((node) =>
      node._parent
        ? node.value
        : node._children
        ? data.find((d) => d.value === node.value)?.children.map((x) => x.value)
        : node.value
    )
    .flat();
};

export default getFlattenTreeNodeValues;
