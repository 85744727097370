import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { currentPhotoIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import { outputSelector } from '../../../redux/taskStateImages/outputSlice';
import {
  currentTaskSelector,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { isTooth, isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import { onContrastOrBrightnessChange } from '../markingStateUtil/imageLevel/setOutputImageUtil';
import {
  colorBrightnessSelector,
  niriBrightnessSelector,
  niriContrastSelector,
  colorContrastSelector,
  contrastSelector,
  brightnessSelector,
} from '../../../redux/taskState/contrastBrightnessSlice';
import { onContrastOrBrightnessChangeXRay } from '../markingStateUtil/imageLevel/setOutputXRayUtil';
import useHandleNewOutputPerType from './useHandleNewOutputPerType';
import { isEqual } from 'lodash';

const useHandleConrastBrightnessChange = () => {
  const taskLevel = useSelector(taskLevelSelector);
  const tier = useSelector(tierSelector);
  const niriBrightness = useSelector(niriBrightnessSelector);
  const niriContrast = useSelector(niriContrastSelector);
  const colorBrightness = useSelector(colorBrightnessSelector);
  const colorContrast = useSelector(colorContrastSelector);
  const contrast = useSelector(contrastSelector);
  const brightness = useSelector(brightnessSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const output = useSelector(outputSelector, isEqual);
  const currentTask = useSelector(currentTaskSelector);

  const isToothLevel = isTooth(taskLevel);

  const handleNewOutputPerType = useHandleNewOutputPerType();

  const handleContrastOrBrigtnessChange = () => {
    if (isToothLevel) return;
    if (isXRay(currentTask)) {
      const imagesState = {
        contrast,
        brightness,
      };
      handleNewOutputPerType(
        onContrastOrBrightnessChangeXRay(
          cloneDeep(output),
          currentPhotoIndex,
          tier,
          imagesState
        )
      );
    } else {
      const imagesState = {
        niriBrightness,
        niriContrast,
        colorBrightness,
        colorContrast,
      };
      handleNewOutputPerType(
        onContrastOrBrightnessChange(
          cloneDeep(output),
          currentPhotoIndex,
          tier,
          imagesState
        )
      );
    }
  };

  return handleContrastOrBrigtnessChange;
};

export default useHandleConrastBrightnessChange;
