import React from 'react';
import FindingNameInput from './FindingNameInput';
import DetailsExplanationsListComp from './DetailsExplanationsListComp';

const AddImageLevelFindingForm = ({
  newFindingName,
  setNewFindingName,
  addDetail,
  detailsExplanations,
  setDetailsExplanations,
  updateDetail,
  deleteDetail,
}) => {
  return (
    <>
      <FindingNameInput
        label="Finding Group Name"
        newFindingName={newFindingName}
        setNewFindingName={setNewFindingName}
        addDetail={addDetail}
      />
      <DetailsExplanationsListComp
        list={detailsExplanations}
        setList={setDetailsExplanations}
        isDeleteable={true}
        updateDetail={updateDetail}
        deleteDetail={deleteDetail}
      />
    </>
  );
};

export default AddImageLevelFindingForm;
