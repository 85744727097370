class CircularFixedSizeUniqueQueue {
  constructor(size) {
    this.capacity = size + 1;
    this.elements = new Set();
    this.arr = [];
  }

  enqueue(element) {
    if (this.elements.has(element)) {
      this.elements.delete(element);
    }

    this.elements.add(element);

    if (this.elements.size >= this.capacity) {
      const oldest = this.arr[this.arr.length - 1];
      this.elements.delete(oldest);
    }
    this.arr = Array.from(this.elements).reverse();
  }

  getList() {
    return this.arr;
  }
}

export default CircularFixedSizeUniqueQueue;
