export const imageLevelColumns = [
  {
    dataKey: 'id',
    label: 'ID',
    width: 50,
  },
  {
    dataKey: 'findingName',
    label: 'Findings',
    width: 150,
  },
  {
    dataKey: 'detailName',
    label: 'Details',
    width: 200,
  },
];

export const imageLevelTrainingColumns = [
  {
    dataKey: 'X',
    label: 'ID',
    width: 50,
  },
  {
    dataKey: 'findings',
    label: 'Findings',
    width: 150,
  },
  {
    dataKey: 'details',
    label: 'Details',
    width: 200,
  },
];

export const toothLevelColumns = [
  {
    dataKey: 'id',
    label: 'ID',
    width: 50,
  },
  {
    dataKey: 'type',
    label: 'Type',
    width: 150,
  },
  {
    dataKey: 'subType',
    label: 'Sub Type',
    width: 150,
  },
  {
    dataKey: 'material',
    label: 'Material',
    width: 150,
  },
];
