const campaign_id = 'campaign_id';
const findings = 'findings';
const default_labelers_number = 'default_labelers_number';
const params = 'params';
const marking_area = 'marking_area';
const tier_2_navigation_mode = 'tier_2_navigation_mode';
const detection_points = 'detection_points';
const ai_algo_misses = 'ai_algo_misses';
const training_mode = 'training_mode';
const geometric_misses = 'geometric_misses';
const auto_correction_disable = 'auto_correction_disable';
const add_detection_to_aggreagation = 'add_detection_to_aggreagation';
const number_of_tier1_labelers = 'number_of_tier1_labelers';

export const findingsList = (config, campaign) => {
  return config.campaigns.find((v) => v[campaign_id] === campaign)?.[findings];
};

export const getCampaign = (config, campaignId) => {
  return config.campaigns.find((x) => x[campaign_id] === campaignId);
};

export const getCampaignName = (config, campaignId) => {
  return getCampaign(config, campaignId)?.campaign_name;
};

export const getCampaignOwner = (config, campaignId) => {
  return getCampaign(config, campaignId)?.campaign_owner;
};

export const getCampaignGroup = (config, campaignId) => {
  return getCampaign(config, campaignId)?.campaign_group;
};

export const getAllCampaignListActive = (config, user) => {
  return config.campaigns
    .filter((obj) => obj.users.hasOwnProperty(user) && !obj.hidden)
    .map((obj) => obj.campaign_id);
};

export const getAllCampaignListHidden = (config, user) => {
  return config.campaigns
    .filter((obj) => obj.users.hasOwnProperty(user) && obj.hidden)
    .map((obj) => obj.campaign_id);
};

export const getTier1LabelersNumber = (config, campaignId) => {
  return (
    config?.campaigns?.find((x) => x[campaign_id] === campaignId)?.[params]?.[
      number_of_tier1_labelers
    ] || config[default_labelers_number]
  );
};

export const getUserCampaigns = (config, user, campaignsRoles) => {
  if (campaignsRoles.size === 0) {
    config.campaigns.forEach((element) => {
      if (element.users.hasOwnProperty(user) && !element.hidden) {
        campaignsRoles.set(element.campaign_id, element.users[user]);
      }
    });
  }
  return campaignsRoles;
};

export const getUserCampaignsIncludeHidden = (config, user) => {
  const campaignsRoles = new Map();

  config.campaigns.forEach((element) => {
    if (element.users.hasOwnProperty(user)) {
      campaignsRoles.set(element.campaign_id, element.users[user]);
    }
  });
  return campaignsRoles;
};

export const getCampaignList = (config, campaignId) => {
  return config.campaigns.filter((x) => x[campaign_id] === campaignId)[0];
};

export const isMarkingAreaEnabled = (config, campaign) => {
  return config.campaigns.find((x) => x[campaign_id] === campaign)?.[params]?.[
    marking_area
  ];
};

export const isAddDetectionToAggreagation = (config, campaign) => {
  return config.campaigns.find((x) => x[campaign_id] === campaign)?.[params]?.[
    add_detection_to_aggreagation
  ];
};

const getDetectionPoints = (config, campaign) => {
  return config.campaigns.find((x) => x[campaign_id] === campaign)?.[params]?.[
    detection_points
  ];
};

export const isAiAlgoMisses = (config, campaign) => {
  return getDetectionPoints(config, campaign)?.[ai_algo_misses];
};

export const isTrainingMode = (config, campaign) => {
  return getDetectionPoints(config, campaign)?.[training_mode];
};

export const isGeometricMisses = (config, campaign) => {
  return getDetectionPoints(config, campaign)?.[geometric_misses];
};

export const getTier2NavigationMode = (config, campaign) =>
  config.campaigns.find((x) => x.campaign_id === campaign)?.[params]?.[
    tier_2_navigation_mode
  ];

export const getBatchesToCampaign = (config) => {
  const extractedData = [];

  config.campaigns.forEach((campaign) => {
    const extractedItem = {
      campaignId: campaign.campaign_id,
      batches: campaign.params.batches,
    };
    extractedData.push(extractedItem);
  });
  return extractedData;
};

export const getAutoCorrectionDisable = (config, campaign) =>
  config.campaigns.find((x) => x.campaign_id === campaign)?.[
    auto_correction_disable
  ];
