import React from 'react';
import PropTypes from 'prop-types';
import styles from '../LabelingToolPage.module.css';
import keyboardShortcuts from '../keyMap';
import MarkingStatus from './MarkingStatus';
import { TaskLevels } from '../../../shared-logic/enums';
import { selectConfig, selectCampaign } from '../../../redux/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import { getTier2NavigationMode } from '../../../config/configUtil';

const KeyboardShortcuts = ({
  tier,
  taskLevel,
  numMarked,
  numTotal,
  shapeInProgress,
}) => {
  const config = useSelector(selectConfig);
  const campaign = useSelector(selectCampaign);
  const tier2NavMode = getTier2NavigationMode(config, campaign);

  return (
    <div className={styles.legendWrapper}>
      <div className={styles.statusWrapper}>
        <div className={styles.categoryTitle}>keyboard shortcuts:</div>
        {[
          ...keyboardShortcuts(tier, taskLevel, shapeInProgress, tier2NavMode),
        ].map(([title, details]) => {
          return (
            <div key={title}>
              <span className={styles.statusTitle}>{title}</span>
              <span className={styles.statusDetail}>{details}</span>
            </div>
          );
        })}
      </div>
      <MarkingStatus
        numMarked={numMarked}
        numTotal={numTotal}
        taskLevel={taskLevel}
      />
    </div>
  );
};

KeyboardShortcuts.propTypes = {
  /**
   * Current task tier
   */
  tier: PropTypes.number,
  /**
   * Current task level
   */
  taskLevel: PropTypes.oneOf([TaskLevels.IMAGE, TaskLevels.TOOTH]),
  /**
   * Num images/teeth marked
   */
  numMarked: PropTypes.number,
  /**
   * Num total images/tooth
   */
  numTotal: PropTypes.number,
  /**
   * the shape string that in progress
   */
  shapeInProgress: PropTypes.string,
};

export default KeyboardShortcuts;
