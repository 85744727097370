import { useDispatch, useSelector } from 'react-redux';
import {
  findingSelector,
  detailSelector,
  currentTypeSelector,
  currentContouringSelector,
  setShapeInProgress,
  shapeInProgressSelector,
  currentMarkerSelector,
  setCurrentMarker,
} from '../../../redux/marks/currentMarkingSlice';
import {
  NOT_SELECTED_FINDING_ID,
  NOT_SELECTED_DETAIL_ID,
} from '../../../shared-logic/params';
import inProgressDialog from '../Dialogs/inProgressDialog';
import submitEditingDialog from '../Dialogs/submitEditingDialog';
import {
  rowSelectedSelector,
  setMarkEdited,
  setSelectedTableRowId,
} from '../../../redux/labelingTool/labelingToolSlice';
import { useSaveShapeData, useResetLabelingSelection } from '../customHooks';

const useHandleNewNavigation = (func) => {
  const dispatch = useDispatch();

  const currentMarker = useSelector(currentMarkerSelector);
  const { findingsId } = useSelector(findingSelector);
  const { detailsId } = useSelector(detailSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const { saveShapeData } = useSaveShapeData();
  const resetLabelingSelection = useResetLabelingSelection();

  const handleNewNavigation = (input) => {
    const storedCurrentMarker = currentMarker;
    dispatch(setCurrentMarker({}));
    if (
      (findingsId === NOT_SELECTED_FINDING_ID ||
        detailsId === NOT_SELECTED_DETAIL_ID) &&
      currentType !== null &&
      currentContouring !== null
    ) {
      inProgressDialog(
        rowSelected,
        // Yes
        () => {
          resetLabelingSelection();
          func(input);
        },
        // No
        (v) => {
          dispatch(setCurrentMarker(storedCurrentMarker));
          dispatch(setSelectedTableRowId(v));
        }
      );
    } else if (shapeInProgress) {
      submitEditingDialog(
        () => saveShapeData(shapeInProgress),
        () => dispatch(setShapeInProgress(null)),
        () => {
          resetLabelingSelection();
          func(input);
        }
      );
    } else {
      dispatch(setMarkEdited(false));
      resetLabelingSelection();
      func(input);
      document.activeElement.blur();
    }
  };

  return handleNewNavigation;
};

export default useHandleNewNavigation;
