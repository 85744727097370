import React from 'react';
import { NavigationArrows } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentPhotoIndexSelector,
  setCurrentContouring,
  setCurrentPhotoIndex,
  setDetail,
  setFinding,
  setPrevPhotoIndex,
  setProbability,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setLabelingDisabled,
  setSelectedTableRowId,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  outputSelector,
  picturesSelector,
  prevTierStateSelector,
} from '../../../redux/taskStateImages/outputSlice';
import {
  taskLevelSelector,
  currentTaskSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { useGetLabelingToolPageLogic } from '../../../hooks';
import NavigationKeys from './NavigationKeys';
import {
  NOT_SELECTED_FINDING,
  NOT_SELECTED_DETAIL,
} from '../../../shared-logic/params';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { useHandleNewNavigation } from '../customHooks';
import {
  selectConfig,
  selectCampaign,
  selectRole,
} from '../../../redux/tasks/tasksSlice';
import { getTier2NavigationMode } from '../../../config/configUtil';
import { isTier2 } from '../../../shared-logic/tiersHelpers';
import { isEqual } from 'lodash';
import ReturnToTasksButton from '../ReturnToTasksButton';
import FindingsDetailsDictionaryModalContainer from '../FindingsDetailsDictionary/FindingsDetailsDictionaryModalContainer';
import styles from './NavigationArrowsContainer.module.css';

const NavigationArrowsContainer = () => {
  const labelingToolLogic = useGetLabelingToolPageLogic();
  const dispatch = useDispatch();
  const currentTask = useSelector(currentTaskSelector);
  const config = useSelector(selectConfig);
  const campaign = useSelector(selectCampaign);
  const tier = useSelector(tierSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const role = useSelector(selectRole);
  const taskLevel = useSelector(taskLevelSelector);
  const output = useSelector(outputSelector, isEqual);
  const prevTierState = useSelector(prevTierStateSelector);
  const pictures = useSelector(picturesSelector);
  const numPictures = pictures.length;

  const width = 280;
  const amount = 20;
  const height = 30;

  const newNavigation = (input) => {
    let next = -1;
    switch (input) {
      case 'first':
        next = 0;
        break;
      case 'last':
        next = numPictures - 1;
        break;
      default:
        next =
          input < 0
            ? Math.max(currentPhotoIndex + input, 0)
            : Math.min(currentPhotoIndex + input, numPictures - 1);
    }

    dispatch(setPrevPhotoIndex(currentPhotoIndex));
    userActionLogs.addActionLog(
      `photo index for task ${currentTask} was set to ${next} numPictures ${numPictures}`
    );
    dispatch(setCurrentPhotoIndex({ task: currentTask, index: next }));
    dispatch(setLabelingDisabled(true));
    dispatch(setCurrentContouring(null));
    dispatch(setSelectedTableRowId(-1));
    dispatch(setFinding(NOT_SELECTED_FINDING));
    dispatch(setDetail(NOT_SELECTED_DETAIL));
    dispatch(setProbability('100%'));
  };

  const doubleForwardOrBack = (input) => input > 1 || input < -1;

  const onNavigate = (input) => {
    if (doubleForwardOrBack(input) && !isTooth(taskLevel)) {
      goToNextImagePairByMode(input / Math.abs(input));
    } else {
      handleNewNavigation(input);
    }
  };

  const handleNewNavigationHook = useHandleNewNavigation(newNavigation);
  const handleNewNavigation = (input) => {
    handleNewNavigationHook(input);
  };

  const goToNextImagePairByMode = (step) => {
    const input = labelingToolLogic.calculateNumSteps(
      role,
      step,
      currentTask,
      currentPhotoIndex,
      output,
      numPictures,
      prevTierState,
      isTier2(tier) ? getTier2NavigationMode(config, campaign) : undefined
    );
    handleNewNavigation(input);
  };

  return (
    <>
      <div className={styles.flexButtons}>
        <NavigationKeys
          handleNewNavigation={handleNewNavigation}
          goToNextImagePairByMode={goToNextImagePairByMode}
        />
        <NavigationArrows
          amount={amount}
          onNavigate={onNavigate}
          width={width}
          height={height}
        />
        <FindingsDetailsDictionaryModalContainer />
        <ReturnToTasksButton />
      </div>
    </>
  );
};

export default NavigationArrowsContainer;
