import React from 'react';
import styles from './SearchSubNickName.module.css';
import PropTypes from 'prop-types';

export const SearchSubNickName = ({
  handleSearchSubNickName,
  setSearchSubNickName,
  searchSubNickName,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubNickName();
    }
  };
  return (
    <div className={styles.search}>
      <input
        placeholder={'search by task'}
        type="text"
        value={searchSubNickName}
        onChange={(e) => setSearchSubNickName(e.target.value)}
        className={styles.searchText}
        onKeyDown={handleKeyDown}
      ></input>
      <button
        className={styles.searchNickNameBtn}
        onClick={() => handleSearchSubNickName()}
      >
        search
      </button>
    </div>
  );
};

SearchSubNickName.propTypes = {
  /**
   * function to handle search
   */
  handleSearchSubNickName: PropTypes.func,
  /**
   * function to set search text
   */
  setSearchSubNickName: PropTypes.func,
  /**
   * string of search
   */
  searchSubNickName: PropTypes.string,
};

export default SearchSubNickName;
