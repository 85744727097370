import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentMarkerSelector,
  currentContouringSelector,
  currentTypeSelector,
  detailSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  rowSelectedSelector,
  setDisableShortcuts,
} from '../../../redux/labelingTool/labelingToolSlice';
import { currentTaskSelector } from '../../../redux/taskState/taskDetailsSlice';
import { NOT_SELECTED_DETAIL_ID } from '../../../shared-logic/params';
import { isXRay } from '../../../shared-logic/taskLevelsTypesHelper';
import {
  usePrevMarks,
  useSelectMarking,
  useShouldDisableMarking,
} from '../customHooks';

const useSelectMarkingHook = () => {
  const dispatch = useDispatch();
  const currentMarker = useSelector(currentMarkerSelector);
  const rowSelected = useSelector(rowSelectedSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const { detailsId } = useSelector(detailSelector);
  const currentType = useSelector(currentTypeSelector);
  const currentTask = useSelector(currentTaskSelector);

  const { prevMarks, markersData } = usePrevMarks();

  const disableMarking = useShouldDisableMarking();

  const { selectXRayMarking } = useSelectMarking({
    prevMarks,
    markersData,
    disableMarking,
  });

  const { selectMarking } = useSelectMarking({
    prevMarks,
    markersData,
    disableMarking,
  });

  useEffect(() => {
    if (Object.keys(currentMarker).length !== 0) return;
    if (
      !rowSelected &&
      currentContouring !== null &&
      detailsId !== NOT_SELECTED_DETAIL_ID
    ) {
      dispatch(setDisableShortcuts(false));
      const obj = {
        x: currentContouring[0].data.position.x,
        y: currentContouring[0].data.position.y,
        type: currentType,
      };
      const xRayObj = {
        x: currentContouring[0].data.position.x,
        y: currentContouring[0].data.position.y,
      };
      isXRay(currentTask) ? selectXRayMarking(xRayObj) : selectMarking(obj);
    }
  }, [
    currentContouring,
    currentMarker,
    currentTask,
    currentType,
    detailsId,
    dispatch,
    rowSelected,
    selectMarking,
    selectXRayMarking,
  ]);
};

export default useSelectMarkingHook;
