import { useRef, useState } from 'react';

const AddImageLevelFindingStates = ({ tasksListLogic, findingsList }) => {
  const [newFindingName, setNewFindingName] = useState('');
  const [detailsExplanations, setDetailsExplanations] = useState([
    { name: '', explanation: '' },
  ]);
  const submitRef = useRef();

  const addImageLevelFindingProps = {
    tasksListLogic,
    findingsList,
    newFindingName,
    setNewFindingName,
    detailsExplanations,
    setDetailsExplanations,
    submitRef,
  };

  const addImageLevelFindingButtons = [
    {
      label: 'Add Finding and Details',
      onClick: () => submitRef.current.click(),
    },
  ];

  return { addImageLevelFindingProps, addImageLevelFindingButtons };
};

export default AddImageLevelFindingStates;
