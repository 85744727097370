import { useRef, useState } from 'react';

const AddUserStates = ({ tasksListLogic }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [name, setName] = useState('');
  const [group, setGroup] = useState('');
  const [isActive] = useState(true);
  const [isAdmin] = useState(false);
  const [isEdit] = useState(false);
  const submitRef = useRef();

  const addUserProps = {
    tasksListLogic,
    email,
    setEmail,
    emailError,
    setEmailError,
    groupList,
    setGroupList,
    name,
    setName,
    group,
    setGroup,
    isActive,
    isAdmin,
    isEdit,
    submitRef,
  };

  const addUserButtons = [
    {
      label: 'Add User',
      onClick: () => submitRef.current.click(),
    },
  ];

  return { addUserProps, addUserButtons };
};

export default AddUserStates;
