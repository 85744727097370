import { Details } from '../MarkLabeling/Labeling/Details';
import logger from '../../../logger';
import {
  useGetTaskListLogic,
  useGetLabelingToolPageLogic,
} from '../../../hooks';
import { useMemo, useCallback, useState } from 'react';

const ABORT_ERR_NAME = 'AbortError';
const isAbortError = (err) => err?.name === ABORT_ERR_NAME;

const useFindingsDetailsDictionary = () => {
  const tasksListLogic = useGetTaskListLogic();
  const labelingToolLogic = useGetLabelingToolPageLogic();
  const [findingsDetailsDictionary, setFindingsDetailsDictionary] = useState({
    DictionaryData: [],
  });

  const getFindingsDetailsDictionary = useCallback(() => {
    const findingsDetailsDictionaryTmp = {
      DictionaryData: [],
    };
    tasksListLogic.getListOfFindings().then((res) => {
      try {
        Object.values(res).forEach((val) => {
          val.Details.forEach((detail) => {
            if (detail.explanation) {
              findingsDetailsDictionaryTmp.DictionaryData.push({
                details: labelingToolLogic.getIndicationId(
                  Details,
                  detail.name
                ),
                explanation: detail.explanation,
              });
            }
          });
        });
        setFindingsDetailsDictionary(findingsDetailsDictionaryTmp);
      } catch (error) {
        if (isAbortError(error)) throw error;
        logger
          .error('getFindingsDetailsDictionary')
          .data({ module: 'useFindingsDetailsDictionary.js', err: error })
          .end();
        return Promise.reject(null);
      }
    });
  }, [labelingToolLogic, tasksListLogic]);

  useMemo(() => getFindingsDetailsDictionary(), [getFindingsDetailsDictionary]);
  return { findingsDetailsDictionary };
};

export default useFindingsDetailsDictionary;
