import React from 'react';
import styles from './cancelTaskDialog.module.css';
import { confirmAlert } from 'react-confirm-alert';

const cancelTaskDialog = (
  deleteTask,
  reasonRef,
  onReasonInput,
  validationRef
) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={styles.customUi}>
          <h3>Are you sure this task is inapplicable?</h3>
          <p>Please provide a reason:</p>
          <textarea
            type="text"
            ref={reasonRef}
            className={styles.dialogTextArea}
            rows="5"
            onChange={onReasonInput}
          />
          <p className={styles.validationError} ref={validationRef}></p>
          <div className={styles.dialogBtns}>
            <button className={styles.dialogBtn} onClick={onClose}>
              No
            </button>
            <button
              className={styles.dialogBtn}
              onClick={async () => {
                const hasReason = await deleteTask(reasonRef);
                if (hasReason) {
                  onClose();
                }
              }}
            >
              Yes
            </button>
          </div>
        </div>
      );
    },
  });
};

export default cancelTaskDialog;
