import React, { useState, useEffect, useCallback } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { lower as lowerTeeth, upper as upperTeeth, isFDI } from './teeth/teeth';
import { fromUniversalToFDI } from './teeth/toothNumberConverter';
import Tooth from '../ToothLabeling/toothEnum';
import JawNavigation from './JawNavigation';
import jawNavigationLogic from './JawNavigation.logic';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentPhotoIndexSelector,
  currentToothIndexSelector,
  setCurrentContouring,
  setCurrentPhotoIndex,
  setCurrentToothIndex,
  setPrevPhotoIndex,
  setProbability,
  typeSelector,
  subTypeSelector,
  materialSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  setToothRightClick,
  setSelectedTableRowId,
  setLabelingDisabled,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  outputSelector,
  picturesSelector,
} from '../../../redux/taskStateImages/outputSlice';
import {
  currentTaskSelector,
  setRecentIndication,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  setUpperConflictedTeeth,
  upperPrevTierStateSelector,
  upperStateSelector,
} from '../../../redux/taskStateImages/stateImagesUpperSlice';
import {
  lowerPrevTierStateSelector,
  setLowerConflictedTeeth,
} from '../../../redux/taskStateImages/stateImagesLowerSlice';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { usePreviousValue, useResetToothControls } from '../customHooks';
import { findCurrentToothData, isValidToothIndication } from '../shared-logic';
import { inProgressDialog } from '../Dialogs';
import { NOT_SELECTED_STR } from '../../../shared-logic/params';
import { isLower, isUpper } from '../../../shared-logic/taskLevelsTypesHelper';
import { isTierWithPrevMarks } from '../../../shared-logic/tiersHelpers';

const JawNavigationContainer = () => {
  const dispatch = useDispatch();
  const currentTask = useSelector(currentTaskSelector);
  const type = useSelector(typeSelector);
  const subType = useSelector(subTypeSelector);
  const material = useSelector(materialSelector);
  const pictures = useSelector(picturesSelector);
  const numPictures = pictures.length;

  const isUpperJaw = isUpper(currentTask);
  const isLowerJaw = isLower(currentTask);
  const [preps, setPreps] = useState(
    currentTask === true ? cloneDeep(upperTeeth) : cloneDeep(lowerTeeth)
  );
  const [prevMarkedTeeth, setPrevMarkedTeeth] = useState([]);
  const [checkedId, setCheckedId] = useState(
    isUpperJaw ? `${Tooth.PREFIX}1` : `${Tooth.PREFIX}17`
  );
  const [firstRender, setFirstRender] = useState(true);
  const currentToothIndex = useSelector(currentToothIndexSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const output = useSelector(outputSelector, isEqual);
  const tier = useSelector(tierSelector);
  const upperState = useSelector(upperStateSelector);
  const upperPrevTierState = useSelector(upperPrevTierStateSelector);
  const lowerPrevTierState = useSelector(lowerPrevTierStateSelector);
  const currentTaskPrevPreps = isLower(currentTask)
    ? lowerPrevTierState
    : upperPrevTierState;
  const isRelevantPreps =
    (isLowerJaw && preps[Tooth.LAST_LOWER]) ||
    (isUpperJaw && preps[Tooth.FIRST_UPPER]);
  const prevMarks = isTierWithPrevMarks(tier) ? currentTaskPrevPreps : [];
  const prevOutput = usePreviousValue(output);
  const resetToothControls = useResetToothControls();

  useEffect(() => {
    if (isLowerJaw) {
      setPreps(cloneDeep(lowerTeeth));
    } else if (isUpperJaw) {
      setPreps(cloneDeep(upperTeeth));
    }
    setCheckedId(isUpperJaw ? `${Tooth.PREFIX}1` : `${Tooth.PREFIX}17`);
    setPrevMarkedTeeth([]);
  }, [currentTask, isLowerJaw, isUpperJaw]);

  const toothInProgress = (onNavigate) => {
    if (
      type !== NOT_SELECTED_STR &&
      !isValidToothIndication(type, subType, material)
    ) {
      inProgressDialog(-1, onNavigate, (v) =>
        dispatch(setSelectedTableRowId(v))
      );
      return true;
    }
    return false;
  };

  const handleAddFinding = () => {
    if (!toothInProgress(resetToothControls)) {
      resetToothControls();
    }
  };

  const markTeeth = useCallback(
    (prevMarked, markedTeeth) => {
      let prepsClone;
      prepsClone = jawNavigationLogic.addOrRemoveMarkingsFromTeeth(
        prevMarked,
        preps,
        false,
        isFDI,
        prevMarks
      );
      prepsClone = jawNavigationLogic.addOrRemoveMarkingsFromTeeth(
        markedTeeth,
        prepsClone,
        true,
        isFDI,
        prevMarks
      );

      prepsClone = jawNavigationLogic.addPointsIndicationToPreps(
        prepsClone,
        output?.teeth
      );

      if (!isEqual(prepsClone, preps) && isRelevantPreps) {
        setPrevMarkedTeeth(markedTeeth);
        setPreps(prepsClone);
      }
    },
    [isRelevantPreps, output, preps, prevMarks]
  );

  useEffect(() => {
    const markedTeeth = output.teeth
      .filter((t) => t.markings && t.markings.length > 0)
      .map((t) => t.id);
    const prevMarked = prevMarkedTeeth.filter((t) => !markedTeeth.includes(t));
    markTeeth(prevMarked, markedTeeth);
  }, [markTeeth, output, prevMarkedTeeth]);

  useEffect(() => {
    if (isTierWithPrevMarks(tier)) {
      if (firstRender) {
        const conflictedTeeth = jawNavigationLogic.countNonOverriddenTeeth(
          upperPrevTierState,
          upperState
        );
        dispatch(setUpperConflictedTeeth(conflictedTeeth));
        setFirstRender(false);
      }

      const conflictedTeeth = jawNavigationLogic.countNonOverriddenTeeth(
        currentTaskPrevPreps,
        output
      );
      isUpperJaw
        ? dispatch(setUpperConflictedTeeth(conflictedTeeth))
        : dispatch(setLowerConflictedTeeth(conflictedTeeth));
    }
  }, [
    currentTaskPrevPreps,
    dispatch,
    firstRender,
    isUpperJaw,
    output,
    tier,
    upperPrevTierState,
    upperState,
  ]);

  useEffect(() => {
    const tooth = findCurrentToothData(output, currentToothIndex);
    const prevTooth = findCurrentToothData(prevOutput, currentToothIndex);
    if (isRelevantPreps && !isEqual(tooth, prevTooth)) {
      const clonedPreps = jawNavigationLogic.updateEditedTooth(tooth, preps);
      setPreps(clonedPreps);
    }
  }, [currentToothIndex, isRelevantPreps, output, preps, prevOutput]);

  const handleClick = (id, numToAdvance) => {
    if (
      numToAdvance === 0 &&
      jawNavigationLogic.isSameToothClicked(id, currentToothIndex, isFDI)
    ) {
      return;
    }
    if (id.includes(Tooth.PREFIX)) {
      dispatch(setSelectedTableRowId(-1));
      let toothIndexSelected = parseInt(id.split(Tooth.PREFIX)[1]);
      if (numToAdvance) {
        let tempId = `${Tooth.PREFIX}${toothIndexSelected + numToAdvance}`;
        if (!preps[tempId]) {
          return;
        }
        id = tempId;
        toothIndexSelected = toothIndexSelected + numToAdvance;
      }
      const clonedPreps = cloneDeep(preps);
      clonedPreps[id].checked = true;
      clonedPreps[checkedId].checked = false;
      toothIndexSelected = isFDI
        ? fromUniversalToFDI(toothIndexSelected)
        : toothIndexSelected;
      setCheckedId(id);
      setPreps(clonedPreps);
      userActionLogs.addActionLog(
        `toothIndex is set to ${toothIndexSelected} on navigating to tooth in task ${currentTask}`
      );

      const prevToothMarkings = findCurrentToothData(output, currentToothIndex);
      const recentIndication = prevToothMarkings
        ? {
            prevToothIndex: currentToothIndex,
            prevToothMarkings: prevToothMarkings.markings,
          }
        : undefined;
      dispatch(setRecentIndication(recentIndication));
      dispatch(setCurrentToothIndex(toothIndexSelected));
      const currentTooth = findCurrentToothData(output, toothIndexSelected);
      const point = currentTooth?.point;
      if (point) {
        const { position, imageNumber } = point;
        if (
          (imageNumber || imageNumber === 0) &&
          imageNumber !== currentPhotoIndex
        ) {
          dispatch(setPrevPhotoIndex(currentPhotoIndex));
          userActionLogs.addActionLog(
            `photo index for task ${currentTask} was set to ${imageNumber} numPictures ${numPictures}`
          );
          dispatch(
            setCurrentPhotoIndex({ task: currentTask, index: imageNumber })
          );
          dispatch(setLabelingDisabled(true));
          dispatch(setSelectedTableRowId(-1));
          dispatch(setProbability('100%'));
        }
        dispatch(
          setCurrentContouring(
            position
              ? [
                  {
                    type: 'point',
                    data: {
                      position: {
                        x: point.position.x,
                        y: point.position.y,
                      },
                      type: point.type,
                      id: currentToothIndex,
                    },
                  },
                ]
              : null
          )
        );
      } else {
        dispatch(setCurrentContouring(null));
      }
    }
  };

  const handleJawClick = (id, numToAdvance) => {
    const clicked = () => {
      handleClick(id, numToAdvance);
      resetToothControls();
    };

    if (!toothInProgress(clicked)) {
      handleClick(id, numToAdvance);
    }
  };

  const handleRightClick = (e, onClick) => {
    const rightClick = e.nativeEvent.which === 3;
    if (rightClick) {
      e.preventDefault();
      dispatch(setToothRightClick(rightClick));
      onClick();
    }
  };

  return (
    <JawNavigation
      preps={preps}
      jawPart={currentTask}
      handleJawClick={handleJawClick}
      handleAddFinding={handleAddFinding}
      currentToothIndex={currentToothIndex}
      checkedId={checkedId}
      handleRightClick={handleRightClick}
    />
  );
};

export default JawNavigationContainer;
