import React from 'react';
import styles from './AutoCorrection.module.css';
import PropTypes from 'prop-types';

const autoCorrectionText = 'Auto Correction';
const undoCorrectionText = 'Undo Correction';

const AutoCorrection = ({
  canvasRef,
  onClick,
  naturalWidth,
  naturalHeight,
  autoCorrectionMode,
  sliderRef,
  setSliderValue,
  signal,
}) => {
  return (
    <div>
      <input
        type="button"
        className={styles.buttonCdn}
        onClick={onClick}
        value={autoCorrectionMode ? undoCorrectionText : autoCorrectionText}
      ></input>
      <div className={styles.sliderContainer}>
        <input
          onChange={(e) => {
            setSliderValue(+e.target.value);
            signal.abort();
          }}
          readOnly={true}
          type="range"
          ref={sliderRef}
          hidden={!autoCorrectionMode}
        />
      </div>
      <canvas
        ref={canvasRef}
        width={naturalWidth}
        height={naturalHeight}
        hidden={true}
      ></canvas>
    </div>
  );
};

export default AutoCorrection;

AutoCorrection.propTypes = {
  canvasRef: PropTypes.object,
  onClick: PropTypes.func,
  naturalWidth: PropTypes.number,
  naturalHeight: PropTypes.number,
  autoCorrectionMode: PropTypes.bool,
  setSliderValue: PropTypes.func,
  sliderRef: PropTypes.object,
  signal: PropTypes.object,
};
