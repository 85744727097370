export const adalAppConfig = (function () {
  return {
    init: async function () {
      try {
        if (this.config) {
          return;
        }
        const response = await fetch('/configs/adalConfig.json');
        this.config = await response.json();
      } catch (error) {
        console.error('Failed to load configuration:', error);
      }
    },
    getConfig: function () {
      return this.config;
    },
  };
})();
