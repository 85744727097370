import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentPhotoIndexSelector,
  supervisorNoteSelector,
} from '../../../redux/marks/currentMarkingSlice';
import {
  currentTaskSelector,
  taskShortNickNameSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  setIsOutputModified,
  setSupervisorNotesList,
  supervisorNotesListSelector,
} from '../../../redux/taskStateImages/outputSlice';
import { setLowerNotes } from '../../../redux/taskStateImages/stateImagesLowerSlice';
import { setUpperNotes } from '../../../redux/taskStateImages/stateImagesUpperSlice';
import { setXRayNotes } from '../../../redux/taskStateImages/stateImagesXRaySlice';
import { Tasks } from '../../../shared-logic/enums';

const useHandleNewSupervisorNotesList = () => {
  const taskShortNickName = useSelector(taskShortNickNameSelector);
  const supervisorNote = useSelector(supervisorNoteSelector);
  const supervisorNotesList = useSelector(supervisorNotesListSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentTask = useSelector(currentTaskSelector);

  const dispatch = useDispatch();

  const handleNewSupervisorNotesList = () => {
    if (supervisorNotesList) {
      let newSupervisorNotesList = cloneDeep(supervisorNotesList);
      const index = newSupervisorNotesList.findIndex(
        (item) => item.id === currentPhotoIndex
      );

      const now = new Date();
      const eventTime = now.toISOString();
      const year = now.getFullYear();

      if (index === -1 && supervisorNote !== '') {
        newSupervisorNotesList.push({
          taskId: taskShortNickName,
          id: currentPhotoIndex,
          notesContent: supervisorNote,
          tier: 2,
          edited: true,
          type: currentTask,
          year,
          eventTime,
        });
      } else {
        const currentNote = newSupervisorNotesList[index];
        newSupervisorNotesList[index] = {
          ...currentNote,
          notesContent: supervisorNote,
          edited: true,
          year,
          eventTime,
        };
      }

      dispatch(setSupervisorNotesList(newSupervisorNotesList));

      switch (currentTask) {
        case Tasks.LOWER:
          dispatch(setLowerNotes(newSupervisorNotesList));
          break;
        case Tasks.UPPER:
          dispatch(setUpperNotes(newSupervisorNotesList));
          break;
        case Tasks.XRAY:
          dispatch(setXRayNotes(newSupervisorNotesList));
          break;
        default:
          break;
      }
      dispatch(setIsOutputModified(true));
    }
  };

  return handleNewSupervisorNotesList;
};

export default useHandleNewSupervisorNotesList;
