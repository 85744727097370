export const labelerColumns = [
  {
    dataKey: 'id',
    hidden: 'true',
  },
  {
    dataKey: 'labeler',
    label: 'Labeler',
    width: 420,
  },
  {
    dataKey: 'tier',
    label: 'Role',
    width: 130,
  },
];

export const tasksColumns = [
  {
    dataKey: 'id',
    label: 'Task',
    width: 250,
  },
  {
    dataKey: 'Type',
    label: 'Type',
    width: 100,
  },
  {
    dataKey: 'Level',
    label: 'Level',
    width: 100,
  },
];

export const campaignsColumns = [
  {
    dataKey: 'favorite',
    label: '⭐',
    width: 60,
    type: 'checkbox',
  },
  {
    dataKey: 'name',
    label: 'Campaigns',
    width: 320,
  },
  {
    dataKey: 'group',
    label: 'Groups',
    width: 320,
  },
];

export const deletedTasksColumns = [
  {
    dataKey: 'id',
    label: 'Task',
    width: 170,
  },
  {
    dataKey: 'Type',
    label: 'Type',
    width: 85,
  },
  {
    dataKey: 'Labeler',
    label: 'Labeler',
    width: 135,
  },
  {
    dataKey: 'Reason',
    label: 'Reason',
    width: 410,
  },
];
