const checkIfIntersects = (
  labelX,
  labelY,
  labelHeight,
  labelWidth,
  id,
  allNodes,
  width,
  height
) => {
  const intersectionOffset = 4;
  // Check if label intersects with the container boundaries
  if (
    labelX + labelWidth > width + intersectionOffset ||
    labelY + labelHeight > height + intersectionOffset ||
    labelX < 0 ||
    labelY < 0
  ) {
    return true;
  }

  let result = false;

  // Check if label intersects with any other nodes
  allNodes.forEach((node) => {
    if (node.id === id || (node.id > id && node.group !== 'border')) return;
    let [nodeWidth, nodeHeight] = [node.width, node.height];
    if (
      !(
        node.x > labelX + labelWidth + intersectionOffset ||
        labelX > node.x + nodeWidth + intersectionOffset ||
        node.y > labelY + labelHeight + intersectionOffset ||
        labelY > node.y + nodeHeight + intersectionOffset
      )
    ) {
      result = true;
    }
  });
  return result;
};

const countHowManyCharsInAString = (str, char) => {
  const regxp = new RegExp(char, 'g');
  const length = str.match(regxp || [])?.length;
  return length ? length : 1;
};

const findMaxRowLen = (str) => {
  const rows = str.split(/\r\n|\r|\n/);
  return Math.max(...rows.map((i) => i.length));
};

/**
 * Set The Hight & Width of the text background
 */
const setLabelSize = (
  text,
  tagsFontSize,
  imageScaleCompensation,
  isRotatedImage
) => {
  const preScaleTaggingWantedHeightInPx = 22;
  const numberOfLines = countHowManyCharsInAString(text, ':');
  const minHeight = isRotatedImage ? 5 : 4;
  const calc =
    numberOfLines * preScaleTaggingWantedHeightInPx * imageScaleCompensation;
  const height =
    calc / numberOfLines > minHeight ? calc : minHeight * numberOfLines;
  const maxRowLen = findMaxRowLen(text);
  return [
    isRotatedImage ? height * 1.3 : height,
    maxRowLen * (tagsFontSize / 2),
  ];
};

const calculateLabelsSize = (
  labels,
  imageScaleCompensation,
  isRotatedImage
) => {
  labels.forEach((label) => {
    label.tagsFontSize = isRotatedImage
      ? 20 * imageScaleCompensation > 3
        ? 20 * imageScaleCompensation
        : 3
      : 14 * imageScaleCompensation > 3
      ? 14 * imageScaleCompensation
      : 3;
    [label.height, label.width] = setLabelSize(
      label.text,
      label.tagsFontSize,
      imageScaleCompensation,
      isRotatedImage
    );
  });
};

const prepareNGLabels = (labels) => {
  labels.forEach((label) => {
    [label.height, label.width] = [label.width, label.height];
  });
};

const revertNGLabels = (labels) => {
  labels.forEach((label) => {
    [label.height, label.width] = [label.width, label.height];

    label.ngTransformStyle = `rotate(-90deg)`;
    label.ngTransformOriginStyle = `${label.x + label.width / 2}px ${
      label.y + label.height / 2
    }px`;
  });
};

const bordersAndLabelsLogic = {
  sortOverlapping(
    nodes,
    width,
    height,
    isNg,
    imageScaleCompensation,
    isRotatedImage
  ) {
    const labels = nodes.filter((d) => d.group === 'label');
    const borders = nodes.filter((d) => d.group === 'border');
    const allNodes = [...labels, ...borders];
    calculateLabelsSize(labels, imageScaleCompensation, isRotatedImage);
    isNg && prepareNGLabels(labels);

    labels.forEach((label) => {
      for (let r = 0; r < 1000; r += 20) {
        for (let a = 0; a < 360; a += 60) {
          const radAngle = (a * Math.PI) / 180;
          let labelX = label.x + r * Math.cos(radAngle);
          let labelY = label.y + r * Math.sin(radAngle);

          if (isNg) {
            labelX += (label.width / 2) * Math.sin(radAngle);
            labelY += (label.height / 2 + label.width / 2) * Math.cos(radAngle);
          }

          const intersects = checkIfIntersects(
            labelX,
            labelY,
            label.height,
            label.width,
            label.id,
            allNodes,
            width,
            height,
            isNg
          );
          if (
            !intersects &&
            labelX + label.width < width &&
            labelY + label.height < height
          ) {
            label.x = labelX;
            label.y = labelY;
            label.fx = labelX;
            label.fy = labelY;
            return;
          }
        }
      }
    });

    isNg && revertNGLabels(labels);
    return nodes;
  },
};

export default bordersAndLabelsLogic;
