import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GroupedDropDownList } from '../../../components';
import styles from './ToothLabeling.module.css';
import { NOT_SELECTED_STR } from '../../../shared-logic/params';
const { TypesSubTypesMaterials } = require('./TypesSubTypesMaterials.js');

const ToothLabeling = ({
  subTypeRef,
  materialRef,
  type,
  subType,
  material,
  onChangedOutput,
}) => {
  const inputSubTypeRef = useRef();
  const inputMaterialRef = useRef();

  useImperativeHandle(subTypeRef, () => ({
    focus: () => {
      inputSubTypeRef.current.focus();
    },
  }));
  useImperativeHandle(materialRef, () => ({
    focus: () => {
      inputMaterialRef.current.focus();
    },
  }));

  const shouldDisable = (options) => {
    return options.length === 1 && type !== NOT_SELECTED_STR;
  };

  const subTypeOptions = TypesSubTypesMaterials[type]['Sub Type'];
  const materialOptions = TypesSubTypesMaterials[type]['Material'];

  const shouldDisableSubType = shouldDisable(subTypeOptions);
  const shouldDisableMaterial = shouldDisable(materialOptions);

  const subTypeClassName = classNames({
    [styles.disabled]: shouldDisableSubType,
  });
  const materialClassName = classNames({
    [styles.disabled]: shouldDisableMaterial,
  });

  return (
    <div className={styles.container}>
      <div className={styles.groupedDropDownListContainer}>
        <GroupedDropDownList
          label="Type"
          items={[
            {
              label: 'Type',
              content: Object.keys(TypesSubTypesMaterials),
            },
          ]}
          selectedValue={type}
          onChange={(selection) => onChangedOutput('type', selection)}
          className={'type'}
        />
        <div className={subTypeClassName}>
          <GroupedDropDownList
            label="Sub Type"
            disabled={shouldDisableSubType}
            items={[
              {
                label: 'Sub Type',
                content: subTypeOptions,
              },
            ]}
            innerRef={inputSubTypeRef}
            selectedValue={subType}
            onChange={(selection) => onChangedOutput('subType', selection)}
            className={'subtype'}
          />
        </div>
        <div className={materialClassName}>
          <GroupedDropDownList
            label="Material"
            disabled={shouldDisableMaterial}
            items={[
              {
                label: 'Material',
                content: materialOptions,
              },
            ]}
            innerRef={inputMaterialRef}
            selectedValue={material}
            onChange={(selection) => onChangedOutput('material', selection)}
            className={'material'}
          />
        </div>
      </div>
    </div>
  );
};

ToothLabeling.propTypes = {
  /**
   * Type value
   */
  type: PropTypes.string,
  /**
   * SubType value
   */
  subType: PropTypes.string,
  /**
   * Material value
   */
  material: PropTypes.string,
  /**
   * SubType component reference
   */
  subTypeRef: PropTypes.object,
  /**
   * Material component reference
   */
  materialRef: PropTypes.object,
  /**
   * onChangedOutput callback
   */
  onChangedOutput: PropTypes.func,
};

export default ToothLabeling;
