import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  usePreviousValue,
  useHandleCommitOrApprovalChange,
} from '../customHooks';
import { currentTaskSelector } from '../../../redux/taskState/taskDetailsSlice';
import { isXRay } from '../../../shared-logic/taskLevelsTypesHelper';

const useCommitOrApprovalChange = () => {
  const currentTask = useSelector(currentTaskSelector);
  const prevCurrentTask = usePreviousValue(currentTask);
  const { handleCommitOrApprovalChange, handleCommitOrApprovalXRayChange } =
    useHandleCommitOrApprovalChange();

  useEffect(() => {
    if (!prevCurrentTask) return;
    if (!isXRay(currentTask)) {
      handleCommitOrApprovalChange();
    } else {
      handleCommitOrApprovalXRayChange();
    }
  }, [
    currentTask,
    handleCommitOrApprovalChange,
    handleCommitOrApprovalXRayChange,
    prevCurrentTask,
  ]);
};

export default useCommitOrApprovalChange;
