import { TaskLevels, TaskTypes, Tasks } from './enums';
import { isTier2 } from './tiersHelpers';

export const isTooth = (level) => level === TaskLevels.TOOTH;
export const isImage = (level) => level === TaskLevels.IMAGE;
export const isXRay = (task) => task === Tasks.XRAY;

export const isUpper = (task) => task === Tasks.UPPER;
export const isLower = (task) => task === Tasks.LOWER;

export const isNG = (taskType) => taskType === TaskTypes.NG;

export const isTier2ImageLevel = (tier, level) =>
  isImage(level) && isTier2(tier);

export const isToothLevelWithoutXRay = (level, task) =>
  isTooth(level) && !isXRay(task);
