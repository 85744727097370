import React, { useEffect } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import styles from './ImageRotationController.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setRotation } from '../../../../redux/taskState/rotationSlice';
import { outputSelector } from '../../../../redux/taskStateImages/outputSlice';
import { currentPhotoIndexSelector } from '../../../../redux/marks/currentMarkingSlice';
import { tierSelector } from '../../../../redux/taskState/taskDetailsSlice';
import { rotationSelector } from '../../../../redux/taskState/rotationSlice';
import {
  brightnessSelector,
  contrastSelector,
} from '../../../../redux/taskState/contrastBrightnessSlice';
//images
import ImageMirrorIcon from './assets/arrow.svg';
import ImageRotationIcon from './assets/flip.svg';
//custom hooks
import { useHandleNewOutputPerType } from '../../customHooks';
//utils
import { onRotationChange } from '../../markingStateUtil/imageLevel/setOutputXRayUtil';

const rotationValues = {
  rotationX: 'rotateX',
  rotationY: 'rotateY',
  rotationZ: 'rotateZ',
};

const { rotationX, rotationY, rotationZ } = rotationValues;
const {
  imageRotationController,
  imageRotationControllerButton,
  horizontalIcon,
  rotateIcon,
} = styles;

const ImageRotationController = () => {
  const dispatch = useDispatch();
  const output = useSelector(outputSelector, isEqual);
  const rotation = useSelector(rotationSelector, isEqual);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const tier = useSelector(tierSelector);
  const brightness = useSelector(brightnessSelector);
  const contrast = useSelector(contrastSelector);
  const handleNewOutputPerType = useHandleNewOutputPerType();

  const { rotateX, rotateY, rotateZ } = rotation;

  const handleRotation = (key, value) => {
    dispatch(setRotation({ key, value }));
  };

  useEffect(() => {
    const imageState = {
      brightness,
      contrast,
      rotation,
    };

    handleNewOutputPerType(
      onRotationChange(cloneDeep(output), currentPhotoIndex, tier, imageState)
    );
  }, [
    rotateZ,
    rotateY,
    rotateX,
    brightness,
    contrast,
    rotation,
    handleNewOutputPerType,
    output,
    currentPhotoIndex,
    tier,
  ]);

  return (
    <div className={imageRotationController}>
      <button
        className={imageRotationControllerButton}
        onClick={() => handleRotation(rotationZ, 90)}
      >
        <img src={ImageRotationIcon} alt="rotate-right" />
      </button>
      <button
        className={imageRotationControllerButton}
        onClick={() => handleRotation(rotationZ, -90)}
      >
        <img
          src={ImageRotationIcon}
          alt="rotate-left"
          className={horizontalIcon}
        />
      </button>
      <button
        className={imageRotationControllerButton}
        onClick={() => handleRotation(rotationX, 180)}
      >
        <img src={ImageMirrorIcon} alt="flip-vertical" />
      </button>
      <button
        className={imageRotationControllerButton}
        onClick={() => handleRotation(rotationY, -180)}
      >
        <img
          src={ImageMirrorIcon}
          alt="flip-horizontal"
          className={rotateIcon}
        />
      </button>
    </div>
  );
};

export default ImageRotationController;
