import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setMarkEdited,
  setRowSelected,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  setCurrentContouring,
  setCurrentMarker,
  setCurrentType,
  setDetail,
  setFinding,
  setProbability,
} from '../../../redux/marks/currentMarkingSlice';
import {
  createDetailData,
  createFindingData,
} from '../../../shared-logic/dataStructures';

const useSetSelectedMarkData = () => {
  const dispatch = useDispatch();
  const setSelectedMarkData = useCallback(
    (data) => {
      dispatch(
        setCurrentContouring([
          {
            type: 'point',
            data: {
              position: {
                x: data.x,
                y: data.y,
              },
            },
          },
        ])
      );
      const { findingsId, findings, detailsId, details, probability, type } =
        data;
      dispatch(setCurrentType(type));
      dispatch(setCurrentMarker(data));
      dispatch(setFinding(createFindingData(findingsId, findings)));
      dispatch(setDetail(createDetailData(detailsId, details)));
      dispatch(setProbability(probability));
      dispatch(setRowSelected(true));
      dispatch(setMarkEdited(false));
    },
    [dispatch]
  );

  return setSelectedMarkData;
};

export default useSetSelectedMarkData;
