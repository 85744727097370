import React from 'react';
import styles from './ManagementConsoleWindow.module.css';
import { ManagementConsole } from '../ManagementConsole';

const ManagementConsoleWindow = (props) => {
  const onClick = () => {
    props.setIsDataManagmentScreen(false);
  };
  const linkUrl =
    'https://wiki.aligntech.com/display/RDITGITERO/Data+management+screen';
  return (
    <div className={styles.popup}>
      <div className={styles.popupInner}>
        <button className={styles.closeButton} onClick={onClick}>
          X
        </button>
        <a
          className={styles.linkButton}
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          ?
        </a>
        <div className={styles.popupContent}>
          <ManagementConsole
            campaignsToRolesMapIncludeHidden={
              props.campaignsToRolesMapIncludeHidden
            }
            campaignsToRolesMap={props.campaignsToRolesMap}
          />
        </div>
      </div>
    </div>
  );
};

export default ManagementConsoleWindow;
