import React, { useImperativeHandle, useRef } from 'react';
import styles from './TextArea.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';

const TextArea = ({
  content,
  header,
  buttons,
  onChange,
  onKeyDown,
  onKeyPress,
  wrapperClassNameProp,
  headerClassNameProp,
  textClassNameProp,
  rows,
  disabled,
  textRef,
}) => {
  const headerClassName = classNames(styles.textHeader, headerClassNameProp);
  const wrapperClassName = classNames(styles.wrapper, wrapperClassNameProp);
  const textClassName = classNames(styles.textArea, textClassNameProp);

  const textAreaRef = useRef();
  useImperativeHandle(textRef, () => ({
    focus: () => {
      textAreaRef.current.focus();
    },
    blur: () => {
      textAreaRef.current.blur();
    },
  }));

  return (
    <div className={wrapperClassName}>
      <div className={styles.headerButtonsContainer}>
        <p className={headerClassName}>{header}:</p>
        {buttons}
      </div>
      <textarea
        className={textClassName}
        value={content}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        ref={textAreaRef}
        type="text"
        rows={rows}
        disabled={disabled}
      />
    </div>
  );
};

TextArea.propTypes = {
  /**
   * content of textArea
   */
  content: PropTypes.string,
  /**
   * header of textArea
   */
  header: PropTypes.string,
  /**
   * buttons near header
   */
  buttons: PropTypes.object,
  /**
   * Callback, fire when content of textarea is changed
   */
  onChange: PropTypes.func,
  /**
   * Callback, fire when key down
   */
  onKeyDown: PropTypes.func,
  /**
   * Callback, fire when key is pressed
   */
  onKeyPress: PropTypes.func,
  /**
   * className for styling div
   */
  wrapperClassNameProp: PropTypes.string,
  /**
   * className for styling header
   */
  headerClassNameProp: PropTypes.string,
  /**
   * className for styling textarea
   */
  textClassNameProp: PropTypes.string,
  /**
   * The number of rows to textArea
   */
  rows: PropTypes.string,
  /**
   * If `true`, textarea is disabled
   */
  disabled: PropTypes.bool,
  /**
   * text ref
   */
  textRef: PropTypes.object,
};

TextArea.defaultProps = {
  disabled: false,
  onKeyDown: noop,
  onKeyPress: noop,
};

export default TextArea;
