import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CompleteTask from './CompleteTask';
import { NotificationManager } from 'react-notifications';
import { useCreateCaseIndex, useSaveState } from '../customHooks';
import completeTaskDialog from '../Dialogs/completeTaskDialog';
import showMissPointsDialog from '../Dialogs/showMissPointsDialog';
import { setUnCommited } from '../../../shared-logic/potentialMissPointLogic';
import { post } from '../../../shared-logic/fetchApi';
import {
  tierSelector,
  pathSelector,
  assigneeSelector,
  setCompleted,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  selectConfig,
  selectMinNumberOfLabelers,
  selectCampaign,
} from '../../../redux/tasks/tasksSlice';
import {
  upperStateSelector,
  upperImagesSelector,
  setUpperState,
} from '../../../redux/taskStateImages/stateImagesUpperSlice';
import {
  lowerStateSelector,
  lowerImagesSelector,
  setLowerState,
} from '../../../redux/taskStateImages/stateImagesLowerSlice';
import {
  xRayStateSelector,
  xRayImagesSelector,
  setXRayState,
} from '../../../redux/taskStateImages/stateImagesXRaySlice';
import {
  isOutputModifiedSelector,
  setIsOutputModified,
} from '../../../redux/taskStateImages/outputSlice';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { cloneDeep } from 'lodash';
import {
  showMissPointsSelector,
  setShowMissPoints,
  setCurrentPhotoIndex,
  setCurrentContouring,
  setProbability,
  setDetail,
  setFinding,
  setCurrentType,
  setCurrentMarker,
  setShapeInProgress,
} from '../../../redux/marks/currentMarkingSlice';
import {
  NOT_SELECTED_DETAIL,
  NOT_SELECTED_FINDING,
} from '../../../shared-logic/params';
import {
  setOutput,
  setPictures,
} from '../../../redux/taskStateImages/outputSlice';
import {
  potentialMissPointSelector,
  setCurrentTask,
} from '../../../redux/taskState/taskDetailsSlice';
import { Tiers, Tasks } from '../../../shared-logic/enums';
import {
  isTrainingMode,
  isAiAlgoMisses,
  isAddDetectionToAggreagation,
} from '../../../config/configUtil';
import { setAutoCorrection } from '../../../redux/taskState/contrastBrightnessSlice';
import { resetImagesACsrc } from '../shared-logic';
import { useHistory } from 'react-router-dom';
import {
  setLabelingDisabled,
  setSelectedTableRowId,
} from '../../../redux/labelingTool/labelingToolSlice';
import {
  TYPE_NIRI,
  TYPE_COLOR,
  TYPE_XRAY,
} from '../../../shared-logic/enums/markingStateEnum';
import { useGetApiConfigs } from '../../../hooks';

const campaign_id = 'campaign_id';
const users = 'users';
const tier2 = 'tier2';

const CompleteTaskContainer = ({ isToothLevel, disabled }) => {
  const apiConfigs = useGetApiConfigs();
  const tier = useSelector(tierSelector);
  const assignee = useSelector(assigneeSelector);
  const path = useSelector(pathSelector);
  const isOutputModified = useSelector(isOutputModifiedSelector);
  const minNumberOfLabelers = useSelector(selectMinNumberOfLabelers);
  const config = useSelector(selectConfig);
  const campaign = useSelector(selectCampaign);
  const lowerState = useSelector(lowerStateSelector);
  const upperState = useSelector(upperStateSelector);
  const xRayState = useSelector(xRayStateSelector);
  const showMissPoints = useSelector(showMissPointsSelector);
  const potentialMissPoint = useSelector(potentialMissPointSelector);
  const lowerImages = useSelector(lowerImagesSelector);
  const upperImages = useSelector(upperImagesSelector);
  const xRayImages = useSelector(xRayImagesSelector);
  const dispatch = useDispatch();

  const caseSummaryHook = useCreateCaseIndex();
  const caseSummary = caseSummaryHook();
  const { committed_images, total_case_images } =
    caseSummary.DataMarkingTask.Summary;

  const usersForCampaign =
    config.campaigns.find((x) => x[campaign_id] === campaign)?.[users] || [];

  let tier2Assignees = Object.keys(usersForCampaign).filter((name) =>
    usersForCampaign[name].includes(tier2)
  );

  tier2Assignees = tier2Assignees.length ? tier2Assignees : [];

  const isCaseCommitted = committed_images === total_case_images;

  const isAddDetection = isAddDetectionToAggreagation(config, campaign);
  const saveState = useSaveState();
  const history = useHistory();

  const completeTask = async () => {
    try {
      await saveState();
      dispatch(setIsOutputModified(false));
      dispatch(setCompleted(true));

      userActionLogs.addActionLog('complete task action');
      await post(
        `${apiConfigs.API_ENDPOINT}/completeTask/?assignee=${assignee}&path=${path}&bucketName=${apiConfigs.BUCKET_NAME}&tier=${tier}&minNumberOfLabelers=${minNumberOfLabelers}&addDetectionFile=${isAddDetection}&isOutputModified=${isOutputModified}`,
        JSON.stringify({ outputIndex: caseSummary, tier2Assignees })
      );

      NotificationManager.success('Case successfully completed', 'Success!');
      await returnToTaskList();
    } catch (err) {
      NotificationManager.error('Failed to complete Case', 'Warning');
    }
  };

  const returnToTaskList = () => {
    dispatch(setAutoCorrection(false));
    resetImagesACsrc(dispatch);
    userActionLogs.addActionLog(`returning to tasks`);
    history.push('/');
  };

  const onClick = () => {
    const lowerMarkings = cloneDeep(lowerState);
    const upperMarkings = cloneDeep(upperState);
    const xRayMarkings = cloneDeep(xRayState);
    const tasksState = new Map([
      [Tasks.LOWER, lowerMarkings],
      [Tasks.UPPER, upperMarkings],
      [Tasks.XRAY, xRayMarkings],
    ]);
    const isTraining = isTrainingMode(config, campaign);
    if (
      showMissPoints ||
      tier !== Tiers.TIER_1 ||
      !potentialMissPoint ||
      (!isAiAlgoMisses(config, campaign) && !isTraining)
    ) {
      dispatch(setShowMissPoints(false));
      completeTaskDialog(completeTask);
    } else {
      showMissPointsDialog(isTraining);
      tasksState.forEach((state, task) => {
        const imagePairs = potentialMissPoint[task]?.imagePairs;
        imagePairs &&
          imagePairs.forEach((pair) => {
            const key = pair.imageName;
            const niriMarkings = pair[TYPE_NIRI]?.markings ?? [];
            const colorMarkings = pair[TYPE_COLOR]?.markings ?? [];
            const xRayMarkings = pair[TYPE_XRAY]?.markings ?? [];

            const detectionPoints = [
              ...niriMarkings,
              ...colorMarkings,
              ...xRayMarkings,
            ];
            setUnCommited(key, state, detectionPoints, campaign && isTraining);
          });
      });
      dispatch(setLowerState(lowerMarkings));
      dispatch(setUpperState(upperMarkings));
      dispatch(setXRayState(xRayMarkings));

      dispatch(setShowMissPoints(true));
      const findUncommittedImage = (markings) =>
        markings?.imagePairs?.find((image) => !image.commited);

      const lowerUncommittedImage = findUncommittedImage(lowerMarkings);
      const upperUncommittedImage = findUncommittedImage(upperMarkings);
      const xRayUncommittedImage = findUncommittedImage(xRayMarkings);

      if (lowerUncommittedImage) {
        const task = Tasks.LOWER;
        dispatch(setOutput(lowerMarkings));
        dispatch(setPictures(lowerImages));
        dispatch(setCurrentTask(task));
        dispatch(
          setCurrentPhotoIndex({ task, index: lowerUncommittedImage.id })
        );
      } else if (upperUncommittedImage) {
        const task = Tasks.UPPER;
        dispatch(setOutput(upperMarkings));
        dispatch(setPictures(upperImages));
        dispatch(setCurrentTask(task));
        dispatch(
          setCurrentPhotoIndex({ task, index: upperUncommittedImage.id })
        );
      } else if (xRayUncommittedImage) {
        const task = Tasks.XRAY;
        dispatch(setOutput(xRayMarkings));
        dispatch(setPictures(xRayImages));
        dispatch(setCurrentTask(task));
        dispatch(
          setCurrentPhotoIndex({ task, index: xRayUncommittedImage.id })
        );
      } else {
        dispatch(setShowMissPoints(false));
        completeTaskDialog(completeTask);
      }

      if (
        lowerUncommittedImage ||
        upperUncommittedImage ||
        xRayUncommittedImage
      ) {
        dispatch(setCurrentType(null));
        dispatch(setCurrentMarker({}));
        dispatch(setLabelingDisabled(true));
        dispatch(setFinding(NOT_SELECTED_FINDING));
        dispatch(setDetail(NOT_SELECTED_DETAIL));
        dispatch(setProbability('100%'));
        dispatch(setCurrentContouring(null));
        dispatch(setShapeInProgress(null));
        dispatch(setSelectedTableRowId(-1));
      }
    }
  };

  return (
    <CompleteTask
      isCaseCommitted={isCaseCommitted}
      isToothLevel={isToothLevel}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

CompleteTaskContainer.propTypes = {
  /**
   * If 'true' apply tooth mode className to button
   */
  isToothLevel: PropTypes.bool,
  /**
   * If `true`, button is disabled
   */
  disabled: PropTypes.bool,
};

export default CompleteTaskContainer;
