import { useRef, useState } from 'react';

const AddBatchStates = ({ tasksListLogic, campaignsList }) => {
  const [batchName, setBatchName] = useState('');
  const [isDataForAIDetections, setIsDataForAIDetections] = useState(false);
  const [isDataForGroundTruthLabels, setIsDataForGroundTruthLabels] =
    useState(false);
  const [connectToCampaign, setConnectToCampaign] = useState(undefined);
  const submitRef = useRef();

  const addBatchProps = {
    tasksListLogic,
    campaignsList,
    batchName,
    setBatchName,
    isDataForAIDetections,
    setIsDataForAIDetections,
    isDataForGroundTruthLabels,
    setIsDataForGroundTruthLabels,
    connectToCampaign,
    setConnectToCampaign,
    submitRef,
  };

  const addBatchButtons = [
    {
      label: 'Add Batch',
      onClick: () => submitRef.current.click(),
    },
  ];

  return { addBatchProps, addBatchButtons };
};

export default AddBatchStates;
