import { IconButton, List, ListItem, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import styles from './DetailsExplanationsListComp.module.css';

const DetailsExplanationsListComp = ({
  list,
  setList,
  isDeleteable,
  updateDetail,
  deleteDetail,
}) => (
  <List>
    {list.map((data, index) => (
      <ListItem key={index} className={styles.listItem}>
        <div className={styles.detailsExplanationField}>
          <TextField
            required
            label="Detail Name"
            value={list[index].name}
            onChange={(e) =>
              updateDetail({
                list,
                setList,
                index,
                detailNewName: e.target.value,
                detailNewExplanation: list[index].explanation,
              })
            }
            className={styles.textFieldName}
          />
          <TextField
            label="Detail Explanation"
            value={list[index].explanation}
            onChange={(e) =>
              updateDetail({
                list,
                setList,
                index,
                detailNewName: list[index].name,
                detailNewExplanation: e.target.value,
              })
            }
            onKeyDown={(e) => e.stopPropagation()}
            className={styles.textFieldExplanation}
            multiline
          />
        </div>
        {isDeleteable && (
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => deleteDetail(index)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </ListItem>
    ))}
  </List>
);

export default DetailsExplanationsListComp;
