import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { forceSimulation, forceLink } from 'd3-force';
import { select } from 'd3-selection';
import styles from './BordersAndLabels.module.css';
import bordersAndLabelsLogic from './BordersAndLabels.logic';
import { useSelector } from 'react-redux';
import { rotationSelector } from '../../../../redux/taskState/rotationSlice';
import { debounce } from 'lodash';
import classNames from 'classnames';

const OPACITY = 0;
const BORDER_PX_REDUCE = 6;
const TagsTextOffsetXNeededToAlignToCenter = 5;
const TagsTextOffsetYNeededToAlignToCenter = -4;
const ROTATED = 180;

const calcCompensationAccordingToScale = (
  { rotateX, rotateY, rotateZ },
  calcHeight,
  imageScale
) => {
  const isRotated =
    (Math.abs(rotateZ) === 90 || Math.abs(rotateZ) === 270) &&
    rotateX !== ROTATED;

  const extraHeight = isRotated ? 1.5 : 0;

  const isCurrentPosition =
    (rotateX !== ROTATED && Math.abs(rotateZ) !== ROTATED) ||
    (rotateX === ROTATED && Math.abs(rotateZ) === ROTATED) ||
    rotateY === ROTATED;

  const getTranslateY = (number = 0) =>
    (20 + imageScale * (imageScale / 2 + number)) / imageScale + extraHeight;

  if (isCurrentPosition) return getTranslateY();
  return calcHeight - getTranslateY(5);
};

const calculateClusterTextRotations = (
  svgTextData,
  d3Container,
  rotationState,
  imageScale = 1
) => {
  const { x, y, height, width } = svgTextData;
  const { rotateX, rotateY, rotateZ } = rotationState;
  const containerWidth = d3Container.current.width.animVal.value;
  const containerHeight = d3Container.current.height.animVal.value;

  const calcWidth = x - (containerWidth - x - width);
  const calcHeight = y - (containerHeight - y - height);
  const translateX = calcWidth;
  const translateY = calcCompensationAccordingToScale(
    rotationState,
    calcHeight,
    imageScale
  );

  const transformString = (translateX, translateY, rotateX = 1, rotateY = 1) =>
    `translate(${translateX}, ${translateY}) scale(${rotateX}, ${rotateY})`;

  if (Math.abs(rotateZ) === ROTATED) {
    if (rotateY === -ROTATED && rotateX === ROTATED) {
      return transformString(0, translateY);
    } else if (rotateY === -ROTATED) {
      return transformString(0, translateY, 1, -1);
    } else if (rotateX === ROTATED) {
      return transformString(translateX, translateY, -1, 1);
    } else {
      return transformString(translateX, translateY, -1, -1);
    }
  } else if (rotateY === -ROTATED && rotateX === ROTATED) {
    return transformString(translateX, translateY, -1, -1);
  } else if (rotateY === -ROTATED) {
    return transformString(translateX, translateY, -1, 1);
  } else if (rotateX === ROTATED) {
    return transformString(0, translateY, 1, -1);
  } else {
    return transformString(0, translateY);
  }
};

const isValid = (value) => value || value === 0;

const BordersAndLabels = (props) => {
  const {
    data,
    width,
    height,
    imageScale,
    setHoveredClusterNumber,
    className,
    isNg,
  } = props;
  const imageScaleCompensation = 1 / imageScale;
  const d3Container = useRef(null);
  const imageRotation = useSelector(rotationSelector);
  const { rotateZ } = imageRotation;
  const [hoveredId, setHoveredId] = useState(null);
  const isRotatedImage = Math.abs(rotateZ) === 90 || Math.abs(rotateZ) === 270;

  const handleMouseMove = useCallback((e) => {
    if (e.target.nodeName.toLowerCase() === 'svg') {
      setHoveredId(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', debounce(handleMouseMove, 10));
    return () => {
      document.removeEventListener('mousemove', debounce(handleMouseMove, 10));
    };
  }, [handleMouseMove]);

  useEffect(() => {
    setHoveredClusterNumber(hoveredId);
  }, [hoveredId, setHoveredClusterNumber]);

  useEffect(() => {
    if (d3Container.current) {
      const svg = select(d3Container.current);

      svg.selectAll('*').remove(); //cleanup prev simulation

      if (data.nodes.length > 0) {
        function wrap(text) {
          text.each((d, i, nodes) => {
            let text = select(nodes[i]),
              words = text.text().split(/\n/).reverse(),
              word,
              lineNumber = 0,
              lineHeight = 1.1,
              y = text.attr('y'),
              x = text.attr('x');

            text.text(null);

            while (words.length > 0) {
              word = words.pop();
              text
                .append('tspan')
                .style('cursor', 'pointer')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', lineNumber++ * lineHeight + 'em')
                .text(word)
                .style('fill', 'white')
                .style('font-size', (d) => d.tagsFontSize);
            }
          });
        }

        svg
          .selectAll('hidden')
          .append('text')
          .data(data.nodes)
          .enter()
          .append('text')
          .text((d) => d.text || '')
          .style('visibility', 'hidden')
          .attr('x', (d) => (isValid(d.x) ? d.x : 0))
          .attr('y', (d) => (isValid(d.y) ? d.y : 0))
          .attr('dy', 0)
          .call(wrap)
          .each((d, i, nodes) => {
            const dimensions = nodes[i].getBBox();
            d.width = dimensions.width ? dimensions.width + 10 : 0 || d.width;
            d.height = dimensions.height
              ? dimensions.height + 5
              : 0 || d.height;
          });

        const nodes = data.nodes.map((n, i) => {
          const subtractPxFromBorders =
            n.group === 'border' ? BORDER_PX_REDUCE / 2 : 0;

          return {
            id: n.id,
            x:
              (isValid(n.positionX) ? n.positionX : n.x) +
              subtractPxFromBorders,
            y:
              (isValid(n.positionY) ? n.positionY : n.y) +
              subtractPxFromBorders,
            fx: isValid(n.positionX)
              ? n.positionX + subtractPxFromBorders
              : undefined,
            fy: isValid(n.positionY)
              ? n.positionY + subtractPxFromBorders
              : undefined,
            width: n.width - subtractPxFromBorders * 2,
            height: n.height - subtractPxFromBorders * 2,
            fill: isValid(n.positionX) ? 'transparent' : n.color,
            border: n.color,
            group: n.group,
            text: n.text,
            cluster: n.cluster,
          };
        });

        bordersAndLabelsLogic.sortOverlapping(
          nodes,
          width,
          height,
          isNg,
          imageScaleCompensation,
          isRotatedImage
        );

        /** Create The hidden line of each aggregetion */
        const link = svg
          .selectAll('line')
          .data(data.links)
          .enter()
          .append('line')
          .style('stroke', (d) => d.color)
          .style('opacity', (d) =>
            hoveredId !== null && Number(hoveredId) !== d.cluster ? OPACITY : 1
          )
          .style('stroke-width', 2 / imageScale);

        /** Create The parent of each aggregetion */
        const rects = svg
          .selectAll('g')
          .data(nodes)
          .enter()
          .append('g')
          .style('transform', (d) =>
            isValid(d.ngTransformStyle) ? d.ngTransformStyle : null
          )
          .style('transform-origin', (d) =>
            isValid(d.ngTransformOriginStyle) ? d.ngTransformOriginStyle : null
          )
          .attr('data-cluster', (d) => d.cluster);

        /** Init The rects with common fields*/
        const rect = rects
          .append('rect')
          .on('mouseover', (d) => {
            setHoveredId(d.target.parentNode.getAttribute('data-cluster'));
          })
          .attr('stroke', (d) => d.border)
          .style('opacity', (d) =>
            hoveredId !== null && Number(hoveredId) !== d.cluster ? OPACITY : 1
          )
          .attr('stroke-width', 2 / imageScale)
          .attr('fill', (d) => d.fill)
          .attr('width', (d) => (isValid(d.width) ? d.width : undefined))
          .attr('height', (d) => (isValid(d.height) ? d.height : undefined))
          .attr('rx', 3 * imageScaleCompensation);

        const text = rects
          .append('text')
          .on('mouseover', (d) => {
            setHoveredId(
              d.target.parentNode.parentNode.getAttribute('data-cluster')
            );
          })
          .style('opacity', (d) =>
            hoveredId !== null && Number(hoveredId) !== d.cluster ? OPACITY : 1
          )
          .data(nodes)
          .attr('transform', (d) =>
            calculateClusterTextRotations(
              d,
              d3Container,
              imageRotation,
              imageScale,
              imageScaleCompensation
            )
          )
          .attr('transform-origin', 'center')
          .text((d) => d.text || '')
          .attr('x', (d) =>
            isValid(d.x)
              ? d.x + TagsTextOffsetXNeededToAlignToCenter
              : undefined
          )
          .attr('y', (d) =>
            isValid(d.y)
              ? d.y + TagsTextOffsetYNeededToAlignToCenter
              : undefined
          )
          .attr('dy', 0)
          .call(wrap);

        forceSimulation(nodes)
          .force(
            'link',
            forceLink()
              .id((d) => d.id)
              .links(data.links)
          )
          .on('tick', ticked);

        function isOnRightSide(d) {
          return d.source.x + d.source.width < d.target.x;
        }

        function isOnLeftSide(d) {
          return d.target.x + d.target.width < d.source.x;
        }

        function isBelow(d) {
          return d.source.y < d.target.y;
        }

        function ticked() {
          link
            .attr('x1', (d) => {
              if (
                !isValid(d.source.x) ||
                !isValid(d.source.height) ||
                !isValid(d.source.width)
              ) {
                return 1;
              }
              if (isBelow(d)) {
                return d.source.x + d.source.width / 2;
              } else if (isOnRightSide(d)) {
                return d.source.x + d.source.width;
              } else if (isOnLeftSide(d)) {
                return d.source.x;
              }
              return d.source.x + d.source.width / 2;
            })
            .attr('y1', (d) => {
              if (
                !isValid(d.source.y) ||
                !isValid(d.source.height) ||
                !isValid(d.source.width)
              ) {
                return 1;
              }
              if (isBelow(d)) {
                return d.source.y + d.source.height;
              } else if (isOnRightSide(d) || isOnLeftSide(d)) {
                return d.source.y + d.source.height / 2;
              }
              return d.source.y;
            })
            .attr('x2', (d) => d.target.x + d.target.width / 2)
            .attr('y2', (d) => d.target.y + d.target.height / 2);
          rect
            .attr('x', (d) => (isValid(d.x) ? d.x : undefined))
            .attr('y', (d) => (isValid(d.y) ? d.y : undefined));

          text
            .attr('x', (d) => {
              const node = nodes.filter((n) => n.id === d.id);
              return node[node.length - 1].x;
            })
            .attr('y', (d) => {
              const node = nodes.filter((n) => n.id === d.id);
              return node[node.length - 1].y;
            });
        }
        svg.exit().remove();
      }
    }
  }, [
    data.nodes,
    data.links,
    width,
    height,
    hoveredId,
    imageRotation,
    imageScale,
    imageScaleCompensation,
    isNg,
    isRotatedImage,
  ]);

  return (
    <div className="svg">
      <svg
        width={width}
        height={height}
        className={classNames([styles.svg, className])}
        ref={d3Container}
      />
    </div>
  );
};

BordersAndLabels.propTypes = {
  /**
   * The data (nodes, labels, links)
   */
  data: PropTypes.object.isRequired,
  /**
   * The image width
   */
  width: PropTypes.number.isRequired,
  /**
   * The image width
   */
  height: PropTypes.number.isRequired,
  /**
   * image scale number
   */
  imageScale: PropTypes.number,
  /**
   * sets hovered cluster number to parent component
   */
  setHoveredClusterNumber: PropTypes.func,
  /**
   * adds classname to create a unique css selector
   */
  className: PropTypes.string,
  /**
   * boolean if this type is NG
   */
  isNg: PropTypes.bool,
};

BordersAndLabels.defaultProps = {
  imageScale: 1,
  setHoveredClusterNumber: () => {},
};

export default React.memo(BordersAndLabels);
