import React, { useState } from 'react';
import styles from './ExportType.module.css';
import ExportLabelsforAI from './ExportLabelsforAI';
import MonitoringReport from './MonitoringReport';
import { ExportAndImportToTier2 } from './ExportAndImportToTier2';

const thirdPartyLabeling = 'thirdPartyLabeling';
const monitoringReport = 'monitoringReport';
const exportLabelsforAI = 'exportLabelsforAI';

export const ManagementConsole = ({
  campaignsToRolesMap,
  campaignsToRolesMapIncludeHidden,
}) => {
  const [openType, setOpenType] = useState(null);
  return (
    <>
      <h3
        className={styles.paragraph}
        onClick={() => setOpenType(thirdPartyLabeling)}
      >
        {thirdPartyLabeling === openType ? '▲' : '▼'} Third Party Labeling
      </h3>
      {openType === thirdPartyLabeling && <ExportAndImportToTier2 />}

      <h3
        className={styles.paragraph}
        onClick={() => setOpenType(monitoringReport)}
      >
        {monitoringReport === openType ? '▲' : '▼'} Monitoring Reports
      </h3>
      {openType === monitoringReport && (
        <MonitoringReport
          campaignsToRolesMap={campaignsToRolesMap}
          campaignsToRolesMapIncludeHidden={campaignsToRolesMapIncludeHidden}
        />
      )}
      <h3
        className={styles.paragraph}
        onClick={() => setOpenType(exportLabelsforAI)}
      >
        {exportLabelsforAI === openType ? '▲' : '▼'} Export Labels
      </h3>
      {openType === exportLabelsforAI && <ExportLabelsforAI />}
    </>
  );
};

export default ManagementConsole;
