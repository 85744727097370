import { MISSING_TOOTH, NOT_SELECTED_STR } from '../../../shared-logic/params';
import { Types } from '../ToothLabeling/Labeling/Types';
import typesHelper from '../ToothLabeling/toothTypesHelper';

export const isValidToothIndication = (type, subType, material) => {
  if (typesHelper.typeOnly.includes(type)) {
    return true;
  }
  if (typesHelper.typeSubTypeMaterial.includes(type)) {
    return subType !== NOT_SELECTED_STR && material !== NOT_SELECTED_STR;
  }
  if (typesHelper.typeSubType.includes(type)) {
    return subType !== NOT_SELECTED_STR;
  }
  if (typesHelper.typeMaterial.includes(type)) {
    return material !== NOT_SELECTED_STR;
  } else {
    return false;
  }
};

export const isEmptyToothMark = (type, subType, material, comments) => {
  return (
    type === NOT_SELECTED_STR &&
    subType === NOT_SELECTED_STR &&
    material === NOT_SELECTED_STR &&
    comments.toLowerCase() === ''
  );
};

export const isMissingTooth = (tooth) => {
  return tooth?.markings?.find((mark) => mark.type === Types[MISSING_TOOTH])
    ? true
    : false;
};

export const findCurrentToothData = (output, currentToothIndex) => {
  return output?.teeth?.find((t) => t.id === currentToothIndex);
};
