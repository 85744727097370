import { useDispatch } from 'react-redux';
import {
  setRecentlyUsedDetails,
  setOpenSelectHistory,
} from '../../../redux/marks/currentMarkingSlice';
import CircularFixedSizeUniqueQueue from '../../../shared-logic/dataStructures/CircularFixedSizeUniqueQueue';

const recentlyUsedDetailsQueue = new CircularFixedSizeUniqueQueue(3);

const useSetRecentlyUsedDetails = () => {
  const dispatch = useDispatch();

  const updateRecentlyUsedDetails = (selected) => {
    recentlyUsedDetailsQueue.enqueue(selected);
    dispatch(setRecentlyUsedDetails(recentlyUsedDetailsQueue.getList()));
    dispatch(setOpenSelectHistory(false));
  };

  return updateRecentlyUsedDetails;
};

export default useSetRecentlyUsedDetails;
