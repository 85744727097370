import labelingToolLogic from '../pages/LabelingToolPage/LabelingToolPage.logic';
import useGetApiConfigs from './apiConfigs';

let labelingToolLogicInstance = null;
const useGetLabelingToolPageLogic = () => {
  const apiConfigs = useGetApiConfigs();
  if (labelingToolLogicInstance === null) {
    labelingToolLogicInstance = labelingToolLogic(apiConfigs);
  }
  return labelingToolLogicInstance;
};

export default useGetLabelingToolPageLogic;
