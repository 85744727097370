import { Findings } from './Findings';
import { Details } from './Details';
import {
  NOT_SELECTED_STR,
  NOT_SELECTED_FINDING_ID,
  NOT_SELECTED_DETAIL_ID,
} from '../../../../shared-logic/params';
import logger from '../../../../logger';
import {
  useGetTaskListLogic,
  useGetLabelingToolPageLogic,
} from '../../../../hooks';
import { useMemo, useCallback, useState } from 'react';

const ABORT_ERR_NAME = 'AbortError';
const isAbortError = (err) => err?.name === ABORT_ERR_NAME;

const useFindingsDetails = () => {
  const tasksListLogic = useGetTaskListLogic();
  const labelingToolLogic = useGetLabelingToolPageLogic();
  const notSelectedDetail = useMemo(
    () => [{ id: NOT_SELECTED_DETAIL_ID, name: NOT_SELECTED_STR }],
    []
  );

  const [findingsDetails, setFindingsDetails] = useState([
    {
      FindingId: NOT_SELECTED_FINDING_ID,
      FindingName: NOT_SELECTED_STR,
      Details: notSelectedDetail,
    },
  ]);

  const getListOfFindings = useCallback(
    (res) => {
      const listOfFindings = [
        {
          FindingId: NOT_SELECTED_FINDING_ID,
          FindingName: NOT_SELECTED_STR,
          Details: notSelectedDetail,
        },
      ];
      Object.values(res).forEach((val) => {
        const findingIndicationId = labelingToolLogic.getIndicationId(
          Findings,
          val.FindingName
        );
        const details = val.Details.map((detail) => {
          detail.name = labelingToolLogic.getIndicationId(Details, detail.name);
          return detail;
        });

        listOfFindings.push({
          FindingId: val.FindingId,
          FindingName: findingIndicationId,
          Details: notSelectedDetail.concat(details),
        });
      });
      return listOfFindings;
    },
    [labelingToolLogic, notSelectedDetail]
  );

  const getFindingsDetails = useCallback(() => {
    tasksListLogic.getListOfFindings().then((res) => {
      try {
        const listOfFindings = getListOfFindings(res);
        setFindingsDetails(listOfFindings);
      } catch (error) {
        if (isAbortError(error)) throw error;
        logger
          .error('getFindingsList')
          .data({ module: 'FindingsDetails.js', err: error })
          .end();
        return Promise.reject(null);
      }
    });
  }, [getListOfFindings, tasksListLogic]);

  useMemo(() => getFindingsDetails(), [getFindingsDetails]);
  return { findingsDetails };
};
export default useFindingsDetails;
