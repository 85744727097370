import { createSlice } from '@reduxjs/toolkit';

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    campaign: null,
    labeler: null,
    role: null,
    adminRole: null,
    config: null,
    deletedTasks: [],
    potentiallyDeletedTasks: [],
    minNumberOfLabelers: 0,
    campaignGuidance: null,
    favoriteCampaign: null,
    usersList: [],
  },
  reducers: {
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    setFavoriteCampaign: (state, action) => {
      state.favoriteCampaign = action.payload;
    },
    setLabeler: (state, action) => {
      state.labeler = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setAdminRole: (state, action) => {
      state.adminRole = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setCampaignGuidance: (state, action) => {
      state.campaignGuidance = action.payload;
    },
    setDeletedTasks: (state, action) => {
      state.deletedTasks = action.payload;
    },
    setPotentiallyDeletedTasks: (state, action) => {
      state.potentiallyDeletedTasks = action.payload;
    },
    setMinNumberOfLabelers: (state, action) => {
      state.minNumberOfLabelers = action.payload;
    },
    setUsersList: (state, action) => {
      action.payload.sort((a, b) => (a.UserId < b.UserId ? -1 : 1));
      state.usersList = action.payload;
    },
  },
});

export const {
  setCampaign,
  setFavoriteCampaign,
  setLabeler,
  setRole,
  setAdminRole,
  setConfig,
  setCampaignGuidance,
  setDeletedTasks,
  setPotentiallyDeletedTasks,
  setMinNumberOfLabelers,
  setUsersList,
} = tasksSlice.actions;

export const selectCampaign = (state) => state.tasks.campaign;
export const selectFavoriteCampaign = (state) => state.tasks.favoriteCampaign;

export const selectLabeler = (state) => state.tasks.labeler;

export const selectRole = (state) => state.tasks.role;
export const selectAdminRole = (state) => state.tasks.adminRole;

export const selectConfig = (state) => state.tasks.config;

export const selectCampaignGuidance = (state) => state.tasks.campaignGuidance;

export const selectUsersList = (state) => state.tasks.usersList;

export const selectDeletedTasks = (state) => state.tasks.deletedTasks;

export const selectPotentiallyDeletedTasks = (state) =>
  state.tasks.potentiallyDeletedTasks;

export const selectMinNumberOfLabelers = (state) =>
  state.tasks.minNumberOfLabelers;

export default tasksSlice.reducer;
