import { BOUNDING_BOX, LINE, POLYGON } from '../../../shared-logic/enums';

export const ADD = 'Add';
export const EDIT = 'Edit';
export const DELETE = 'Delete';

export const BOUNDING_BOX_ACTS = {
  [ADD]: `${ADD} ${BOUNDING_BOX}`,
  [EDIT]: `${EDIT} ${BOUNDING_BOX}`,
  [DELETE]: `${DELETE} ${BOUNDING_BOX}`,
};

export const POLYGON_ACTS = {
  [ADD]: `${ADD} ${POLYGON}`,
  [EDIT]: `${EDIT} ${POLYGON}`,
  [DELETE]: `${DELETE} ${POLYGON}`,
};

export const LINE_ACTS = {
  [ADD]: `${ADD} ${LINE}`,
  [EDIT]: `${EDIT} ${LINE}`,
  [DELETE]: `${DELETE} ${LINE}`,
};
