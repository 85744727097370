import { useMemo } from 'react';
import { getAwsLabelingAppConfig } from '../config/awsLabelingConfig';

let awsLabelingAppConfigInstance = null;
const getAwsLabelingAppConfigInstance = () => {
  if (awsLabelingAppConfigInstance === null) {
    awsLabelingAppConfigInstance = getAwsLabelingAppConfig();
  }
  return awsLabelingAppConfigInstance;
};

const useGetApiConfigs = () => {
  const configs = useMemo(() => {
    awsLabelingAppConfigInstance = getAwsLabelingAppConfigInstance();
    return {
      API_ENDPOINT: awsLabelingAppConfigInstance?.apiEndpoint,
      BUCKET_NAME: awsLabelingAppConfigInstance?.bucketName,
      BUCKET_NAME_CONFIG: awsLabelingAppConfigInstance?.bucketNameConfig,
    };
  }, []);
  return configs;
};

export default useGetApiConfigs;
