import { useSelector } from 'react-redux';
import {
  assigneeSelector,
  batchSelector,
  imagesTypeSelector,
  taskLevelSelector,
  taskShortNickNameSelector,
  tierSelector,
  completedSelector,
  inTier1Selector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  lowerConflictedTeethSelector,
  lowerImagesSelector,
  lowerStateSelector,
} from '../../../redux/taskStateImages/stateImagesLowerSlice';
import {
  upperConflictedTeethSelector,
  upperImagesSelector,
  upperStateSelector,
} from '../../../redux/taskStateImages/stateImagesUpperSlice';
import {
  xRayImagesSelector,
  xRayStateSelector,
} from '../../../redux/taskStateImages/stateImagesXRaySlice';
import { isImage, isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { getSummary } from '../shared-logic';
import { showMissPointsSelector } from '../../../redux/marks/currentMarkingSlice';

const useCreateCaseIndex = () => {
  const assignee = useSelector(assigneeSelector);
  const batch = useSelector(batchSelector);
  const taskShortNickName = useSelector(taskShortNickNameSelector);
  const tier = useSelector(tierSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const imagesType = useSelector(imagesTypeSelector);
  const completed = useSelector(completedSelector);
  const inTier1 = useSelector(inTier1Selector);
  const lowerState = useSelector(lowerStateSelector);
  const lowerImages = useSelector(lowerImagesSelector);
  const lowerConflictedTeeth = useSelector(lowerConflictedTeethSelector);
  const upperState = useSelector(upperStateSelector);
  const upperImages = useSelector(upperImagesSelector);
  const upperConflictedTeeth = useSelector(upperConflictedTeethSelector);
  const xRayState = useSelector(xRayStateSelector);
  const xRayImages = useSelector(xRayImagesSelector);
  const showMissPoints = useSelector(showMissPointsSelector);

  const calculateSummary = () => {
    const lowerSummary = getSummary(
      isImage(taskLevel),
      lowerState,
      lowerImages.length,
      tier,
      lowerConflictedTeeth
    );
    const upperSummary = getSummary(
      isImage(taskLevel),
      upperState,
      upperImages.length,
      tier,
      upperConflictedTeeth
    );
    const xRaySummary = getSummary(
      isImage(taskLevel),
      xRayState,
      xRayImages.length
    );

    return {
      committed_images:
        lowerSummary.committed_images +
        upperSummary.committed_images +
        (isTooth(taskLevel) ? 0 : xRaySummary.committed_images),
      total_case_images:
        lowerSummary.total_case_images +
        upperSummary.total_case_images +
        (isTooth(taskLevel) ? 0 : xRaySummary.total_case_images),
    };
  };

  const createIndex = () => {
    const outputIndex = {
      DataMarkingTask: {
        Assignee: assignee,
        Batch: batch,
        TaskShortNickName: taskShortNickName,
        Tier: parseInt(tier),
        Type: imagesType,
        Level: taskLevel,
        Summary: calculateSummary(),
        Completed: completed,
        InTier1: inTier1,
        showMissPointInProgress: showMissPoints,
      },
    };
    return outputIndex;
  };

  return createIndex;
};

export default useCreateCaseIndex;
