import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import modalStyles from '../cancelTaskDialog/cancelTaskDialog.module.css';

const { customUi, dialogBtns, dialogBtn } = modalStyles;

const importTasksDialog = (
  countToCampaignList,
  batchesWithoutCampaign,
  failItems
) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={customUi}>
          <section>
            {Object.keys(countToCampaignList).map(
              (campaignID) =>
                !isNaN(countToCampaignList[campaignID].count) && (
                  <p key={campaignID}>
                    {countToCampaignList[campaignID].count.toString()} cases
                    added to {countToCampaignList[campaignID].campaignId}
                  </p>
                )
            )}
            {Object.keys(batchesWithoutCampaign).map(
              (campaignID) =>
                !isNaN(batchesWithoutCampaign[campaignID].count) && (
                  <p key={campaignID}>
                    {batchesWithoutCampaign[campaignID].count.toString()} cases
                    of batch {batchesWithoutCampaign[campaignID].batch}, were
                    not added – please connect:
                    {batchesWithoutCampaign[campaignID].batch} to the campaign
                  </p>
                )
            )}
            {parseInt(failItems) !== 0 && <p>Fail Items:{failItems}</p>}
          </section>
          <footer className={dialogBtns}>
            <button
              className={dialogBtn}
              onClick={() => {
                onClose();
              }}
            >
              Ok
            </button>
          </footer>
        </div>
      );
    },
  });
};

export default importTasksDialog;
