import React from 'react';
import PropTypes from 'prop-types';
import Mark from './Mark/Mark';
import styles from './Markers.module.css';

const Markers = (props) => {
  const {
    markers,
    size,
    color,
    zIndex,
    isSelected,
    onMarkClick,
    blink,
    labelingImageSize,
    showOpenSelectMenu,
    imageScale,
    imageSize,
    hoveredClusterNumber,
    touchEvent,
    missPoint,
  } = props;
  const SIZE = isSelected ? size * 3 : size;

  const determineOpacity = (mark) => {
    if (mark?.cluster === hoveredClusterNumber || !hoveredClusterNumber) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <div className={styles.markerContainer}>
      {markers &&
        markers.map((item, index) => {
          if (!item) return null;
          const opacity = determineOpacity(item);
          return (
            <div key={index} className={styles.markWrapper} style={{ opacity }}>
              <Mark
                key={index}
                index={index}
                tier={item?.tier}
                size={SIZE}
                color={color}
                zIndex={blink ? 1 : zIndex}
                item={item}
                onMarkClick={onMarkClick}
                blink={blink}
                imageScale={imageScale}
                imageSize={imageSize}
                showOpenSelectMenu={showOpenSelectMenu}
                labelingImageSize={labelingImageSize}
                opacity={opacity}
                touchEvent={touchEvent}
                missPoint={missPoint}
              />
            </div>
          );
        })}
    </div>
  );
};

Markers.propTypes = {
  /**
   * markers list item, array of items
   */
  markers: PropTypes.array,
  /**
   * size of the marker
   */
  size: PropTypes.number,
  /**
   * Z index
   */
  zIndex: PropTypes.number,
  /**
   * color of mark
   */
  color: PropTypes.string,
  /**
   * whether the mark represents a selected point
   */
  isSelected: PropTypes.bool,
  /**
   * whether the mark is a blinking one
   */
  blink: PropTypes.bool,
  /**
   * callback fired when a mark is clicked
   */
  onMarkClick: PropTypes.func,
  /**
   * whether show menu or not
   */
  showOpenSelectMenu: PropTypes.bool,
  /**
   * labeling image scale value used to reduce marks size which caused by zooming
   */
  imageScale: PropTypes.number,
  /**
   * rendered and natural image sizes
   */
  imageSize: PropTypes.object,
  /**
   * hovered Cluster Number used to add opacity to marks
   */
  hoveredClusterNumber: PropTypes.number,
  /**
   * should a touch event be attached to the marks
   */
  touchEvent: PropTypes.bool,
};

Markers.defaultProps = {
  markers: [],
  size: 8,
  color: null,
  zIndex: 1,
  imageScale: 1,
  showOpenSelectMenu: false,
};

export default React.memo(Markers);
