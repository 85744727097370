import React from 'react';
import { useSelector } from 'react-redux';
import AutoCorrectionContainer from '../AutoCorrection/AutoCorrectionContainer';
import Images from '../Images/Images';
import LabelersList from '../LabelersList/LabelersList';
import ShapeActionsButtons from '../ShapeActionsButtons/ShapeActionsButtons';
import styles from '../LabelingToolPage.module.css';
import { isImage, isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import {
  taskLevelSelector,
  potentiallyDiscardedSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { selectConfig, selectCampaign } from '../../../redux/tasks/tasksSlice';
import { prevTierStateSelector } from '../../../redux/taskStateImages/outputSlice';
import { currentToothIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import { usePrevMarks } from '../customHooks';
import {
  isMarkingAreaEnabled,
  getAutoCorrectionDisable,
} from '../../../config/configUtil';
import { isTier2 } from '../../../shared-logic/tiersHelpers';
const ImagesAndImageManipulation = () => {
  const taskLevel = useSelector(taskLevelSelector);
  const potentiallyDiscarded = useSelector(potentiallyDiscardedSelector);
  const isImageLevel = isImage(taskLevel);
  const tier = useSelector(tierSelector);
  const prevTierState = useSelector(prevTierStateSelector);
  const currentToothIndex = useSelector(currentToothIndexSelector);
  const TIER_2 = isTier2(tier);
  const isToothLevel = isTooth(taskLevel);
  const config = useSelector(selectConfig);
  const campaign = useSelector(selectCampaign);
  const { prevMarks } = usePrevMarks();
  const markingAreaEnabled = isMarkingAreaEnabled(config, campaign);

  const autoCorrectionDisabled = getAutoCorrectionDisable(config, campaign);
  return (
    <div className={styles.flexColumn}>
      <div className={styles.flexRow}>
        <Images />
      </div>
      {!autoCorrectionDisabled && (
        <AutoCorrectionContainer isToothLevel={isToothLevel} />
      )}
      {markingAreaEnabled && isImageLevel && !potentiallyDiscarded && (
        <ShapeActionsButtons />
      )}
      {TIER_2 && (
        <LabelersList
          prevState={isToothLevel ? prevTierState : prevMarks}
          currentLevel={taskLevel}
          currentToothIndex={currentToothIndex}
        />
      )}
    </div>
  );
};

export default ImagesAndImageManipulation;
