import React from 'react';
import { NiriBrightnessContrastKeys, CaseLevelShortcuts } from './keys';

const HotKeysShortcuts = () => {
  return (
    <>
      <NiriBrightnessContrastKeys />
      <CaseLevelShortcuts />
    </>
  );
};

export default HotKeysShortcuts;
