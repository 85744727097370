import { Autocomplete, Checkbox, TextField } from '@mui/material';
import React from 'react';
import DisplayNameInput from './DisplayNameInput';
import AutocompleteOrCreateNew from '../../../../../components/AutocompleteOrCreateNew';
import { LabeledSwitch } from '../../../Components';

const EditUserForm = ({
  usersList,
  email,
  selectEmail,
  name,
  setName,
  group,
  setGroup,
  groupList,
  isActive,
  setIsActive,
  isAdmin,
  setIsAdmin,
  selectedFilteredGroups,
  setSelectedFilteredGroups,
}) => {
  const filterdUsers =
    selectedFilteredGroups.length === 0
      ? usersList
      : usersList.filter((user) => selectedFilteredGroups.includes(user.Group));

  return (
    <>
      <Autocomplete
        multiple
        options={groupList}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Filter by Group" />
        )}
        value={selectedFilteredGroups}
        onChange={(event, newValue) => {
          setSelectedFilteredGroups(newValue.sort());
        }}
      />

      <Autocomplete
        options={filterdUsers}
        getOptionLabel={(option) => option.UserId}
        value={filterdUsers.find((user) => user.UserId === email) || null}
        onChange={(event, newValue) =>
          selectEmail(newValue ? newValue.UserId : '')
        }
        renderInput={(params) => (
          <TextField {...params} label="Email" required variant="outlined" />
        )}
        selectOnFocus
        handleHomeEndKeys
        disableClearable
      />
      {email && (
        <>
          <DisplayNameInput name={name} setName={setName} />
          <AutocompleteOrCreateNew
            selectedValue={group}
            setSelectedValue={setGroup}
            values={groupList}
            fieldlabel="Group"
          />
          <LabeledSwitch
            checked={isActive}
            setChecked={setIsActive}
            label={'Active'}
          />
          <LabeledSwitch
            checked={isAdmin}
            setChecked={setIsAdmin}
            label={'Admin'}
          />
        </>
      )}
    </>
  );
};

export default EditUserForm;
