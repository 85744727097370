import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePreviousValue } from './usePreviousValue';
import { cloneDeep } from 'lodash';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { taskLevelSelector } from '../../../redux/taskState/taskDetailsSlice';
import {
  outputSelector,
  setCommit,
} from '../../../redux/taskStateImages/outputSlice';
import { currentPhotoIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { isEqual } from 'lodash';

const useSetCommitToValue = () => {
  const dispatch = useDispatch();
  const taskLevel = useSelector(taskLevelSelector);
  const isToothLevel = isTooth(taskLevel);
  const output = useSelector(outputSelector, isEqual);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);

  const currentImagePair =
    (!isToothLevel &&
      output.imagePairs.filter((pair) => pair.id === currentPhotoIndex)) ||
    [];
  const currentPair = currentImagePair.length ? currentImagePair[0] : null;
  const prevCurrentPairState = usePreviousValue(cloneDeep(currentPair));

  useEffect(() => {
    const prevId = prevCurrentPairState && prevCurrentPairState.id;
    const currentId = currentPair && currentPair.id;
    if (prevId !== currentId && currentPair) {
      userActionLogs.addActionLog(
        `commit set to ${currentPair.commited} via navigation to pair ${currentId}`
      );
      dispatch(setCommit(currentPair.commited));
    }
  }, [currentPair, prevCurrentPairState, dispatch]);
};

export default useSetCommitToValue;
