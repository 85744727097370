import { Box, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import styles from './FindingNameInput.module.css';

const FindingNameInput = ({
  label,
  newFindingName,
  setNewFindingName,
  addDetail,
}) => (
  <Box className={styles.inputContainer}>
    <TextField
      required
      label={label}
      value={newFindingName}
      onChange={(e) => setNewFindingName(e.target.value)}
      fullWidth
    />
    <IconButton edge="end" aria-label="add" onClick={addDetail}>
      <AddIcon />
    </IconButton>
  </Box>
);

export default FindingNameInput;
